
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * React-Admin List component for: Equipment Resource
 */

import * as RA from 'react-admin';
import IconDataObject from '@mui/icons-material/DataObject';
import { SDK, AppService } from 'src/ts-axios-sdk/SDK'
import { useNotify, useRefresh, useRecordContext } from 'react-admin';
import InputHelper from '../common/inputHelper';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

const postFilters = [
    <RA.TextInput source="search" label="Search" alwaysOn />,
    
    // issue with multiple values: https://github.com/marmelab/react-admin/issues/8181
    <RA.ReferenceInput
      alwaysOn
      label="Equipment Type"
      source="equipmentTypeId"
      reference="EquipmentType"
      // hack: retrieve all eqTypes to ensure the dropdown filter won't clear  
      // the filter works with a lower value, but UX would be worse (filter would clear)
      perPage={0} // retrieve all
      on
      //   shouldRenderSuggestions={(val:any) => { return val.trim().length > 2 }}
      sort={{ field: 'name', order: 'ASC' }}
    >
      <RA.AutocompleteInput source="id" optionText="name" optionValue='id' 
        sx={{ width: 450 }}
        //filterToQuery={(query: string) => ({ search: query })}
      />
  </RA.ReferenceInput>,
  <RA.ReferenceInput
    alwaysOn
    label="Brand"
    source="brandId" 
    reference="Brand"
    perPage={0} // retrieve all to ensure the dropdown filter won't clear
    on
    sort={{ field: 'name', order: 'ASC' }}
    >
    <RA.AutocompleteInput source="id" optionText="name" optionValue='id' 
        sx={{ width: 300 }}
        //filterToQuery={(query: string) => ({ search: query })}
    />
    </RA.ReferenceInput>,
    <RA.NullableBooleanInput alwaysOn label="Published" source="published" />,
    
];

const CustomCloneButton = (props: any) => {
    
    const record = useRecordContext(props);
    const refresh = useRefresh();
    
    return(<><ContentCopyIcon 
        onClick={async (e) => {
            e.stopPropagation(); 
            
            let eqId = Number(record.id);
            const api = AppService.EquipmentApiFactory(...SDK.Config('en'));
            await api
                .equipmentCloneEquipment(eqId)
                .then(async (response) => {
                    refresh();
              })
        }}
    /></>);
}

const ListActions = () => {

    const notify = useNotify();

    return (
        <RA.TopToolbar>
            <RA.FilterButton/>
            <RA.CreateButton/>
            <RA.ExportButton/>
            {/* custom actions */}
            <RA.Button
                onClick={async () => { 
                    const api = AppService.EquipmentApiFactory(...SDK.Config('en'));
                    await api.equipmentIndexAll().then(async (response) => {
                        if (response.status == 200)
                            notify("Indexation enqueued", { type: 'info' });
                        else 
                            notify("Indexation already running", { type: 'warning' });
                    });

                }}
                label="Index All"
            >
                <IconDataObject/>
            </RA.Button>
        </RA.TopToolbar>
    );
};

const EquipmentList = () => (
    
    <RA.List filters={postFilters} actions={<ListActions/>}> 
        <RA.Datagrid rowClick="edit">
            <RA.TextField source="name" />

            <RA.ReferenceField label="equipment Type" source="equipmentTypeId" reference="equipmentType">
        <RA.FunctionField label="Name" render={(record: any) => `${record.name}`} />
</RA.ReferenceField>

            <RA.ReferenceField label="brand" source="brandId" reference="brand">
        <RA.FunctionField label="Name" render={(record: any) => `${record.name}`} />
</RA.ReferenceField>

            <RA.TextField source="published" />

            <RA.NumberField source="id" />

            <CustomCloneButton />

        </RA.Datagrid>
    </RA.List>
);

export default EquipmentList;

