
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * React-Admin List component for: Policy Resource
 */

import * as RA from 'react-admin';


const postFilters = [
    <RA.TextInput source="search" label="Search" alwaysOn />
];
const PolicyList = () => (
    <RA.List filters={postFilters}> 
        <RA.Datagrid rowClick="edit">
            <RA.NumberField source="id" />

            <RA.TextField source="type" />

            <RA.TextField source="version" />

            <RA.TextField source="isActive" />

            <RA.TextField source="isRequired" />

            <RA.DateField showTime source="activationDate" />

        </RA.Datagrid>
    </RA.List>
);

export default PolicyList;

