import * as RA from 'react-admin';
import OptionSetIcon from '@mui/icons-material/Bookmark';
import OptionSetList from './OptionSet_List';
import InputHelper from '../common/inputHelper';
import Styles from '../common/styles';
import { Box, Grid, Typography } from '@mui/material'
import { required } from 'react-admin';
import { SDK, AppService } from 'src/ts-axios-sdk/SDK'

const BaseForm = (props: any) => (

    <RA.TabbedForm warnWhenUnsavedChanges {...props}>
        <RA.FormTab label="Option Set">

            {props.type === "edit" && <RA.NumberInput disabled source="id" />}

            <RA.TextInput  source="name" />

            <RA.TranslatableInputs locales={['pt','en', 'fr', 'es']} defaultLocale="pt" >
                <RA.TextInput source="displayNameI18n" />
            </RA.TranslatableInputs>

        </RA.FormTab>
        <RA.FormTab label="Options">
            <RA.ArrayInput source="options" label="" {...props}>
                <RA.SimpleFormIterator>

                    {/* SimpleFormIterator expects childs to be inputs. 
                    To customize the inner form layouts we need to use a FormDataConsumer to retrieve the correct sources. 
                    ref: https://marmelab.com/react-admin/ArrayInput.html
                    */}
                    <RA.FormDataConsumer>
                        {({ formData, scopedFormData, getSource, ...rest }) =>
                            <Grid container spacing={{ xs: 2, md: 8 }} columns={{ xs: 4, sm: 8, md: 12 }} >
                                
                                <Grid item >
                                    <RA.TextInput source={getSource?.('name') ?? ''} />
                                </Grid>
                                <Grid item >
                                    <RA.TranslatableInputs locales={InputHelper.locales} defaultLocale="pt" {...rest} >
                                        <RA.TextInput label="Display Name" source={getSource?.('displayNameI18n') ?? ''} {...rest} />
                                    </RA.TranslatableInputs>
                                </Grid>

                            </Grid>
                        }
                    </RA.FormDataConsumer>

                </RA.SimpleFormIterator>
            </RA.ArrayInput>
        </RA.FormTab>

    </RA.TabbedForm>

);

const EditForm = (props: any) => (
    <RA.Edit {...props}>
        <BaseForm type="edit" />
    </RA.Edit>
);

const CreateForm = (props: any) => (
    <RA.Create {...props}>
        <BaseForm type="create" />
    </RA.Create>
);

export default {
    name: 'OptionSet',
    options: {label: 'Option Set' },
    list: OptionSetList,
    edit: EditForm,
    create: CreateForm,
    icon: OptionSetIcon,
};
