
import * as RA from 'react-admin';
import EquipmentTypeIcon from '@mui/icons-material/Bookmark';
import EquipmentTypeList from './EquipmentType_List';
import InputHelper from '../common/inputHelper';
import  {useEffect, useState } from 'react';
import { Box } from '@mui/material'
import { SDK, AppService } from 'src/ts-axios-sdk/SDK'

import { useFormContext, useFieldArray } from "react-hook-form";

// import { Tree } from "@react-admin/ra-tree";

// inline field create/edit
import { Drawer } from '@material-ui/core';
import * as Icons from '@mui/icons-material';
import { textAlign } from '@mui/material/node_modules/@mui/system';

const BaseForm = (props: any) => {

    const [unitTypes, setUnitTypes] = useState<AppService.UnitTypeListDTO[]>(new Array<AppService.UnitTypeListDTO>());
    
    // tree component (instead of area select) - WIP
    // const [areas, setAreas] = useState<any[]>(new Array<any>());
    // const [checked, setChecked] = useState<number[]>([7,19]);
    
    const fieldTypes = AppService.FieldFormDTOFieldTypeEnum;
    // const fieldTypes = AppService.FieldType; // the FieldType, when spec generated without UseInlineDefinitionsForEnums ($ref schema)

    useEffect(() => {

        const fetchUnitTypes = async () => await AppService.UnitTypeApiFactory(...SDK.Config('en')).unitTypeGetMany();
        fetchUnitTypes().then(result => {
            const items = result.data.items ?? new Array<AppService.UnitTypeListDTO>();
            setUnitTypes(items);
        });

        // tree component (instead of area select) - WIP
        // const fetchAreas = async () => await AppService.AreaApiFactory(...SDK.Config('en')).areaGetTree();
        // fetchAreas().then(result => {
        //     const items = result.data ?? new Array<any>();
        //     setAreas(items);
        // });

    }, []) // empty deps array: run just once

    // tree component (instead of area select) - WIP
    // let onCheck = function(checkedKeys:any) {
    //     console.log(checkedKeys);
    //     setChecked(checkedKeys);
    // }

    return (
            <RA.TabbedForm warnWhenUnsavedChanges {...props}>
                <RA.FormTab label="Equipment Type" >

                    {props.type === "edit" && <RA.NumberInput disabled source="id" />}

                    <RA.TextInput source="name" validate={[RA.required('Required')]} />

                    <RA.BooleanInput source="published" />

                    <RA.TranslatableInputs locales={InputHelper.locales} defaultLocale="pt" >
                        <RA.TextInput label="Display Name" source="displayNameI18n" />
                    </RA.TranslatableInputs>

                    <RA.ImageInput source="image" accept={'.png,.jpg,.jpeg,.svg'} >
                        <RA.ImageField source="src" title="title" />
                    </RA.ImageInput>
    
                    <RA.ReferenceArrayInput source="areas" reference="Area" 
                        perPage={0} sort={{ field: null, order: 'ASC' }}
                        parse={InputHelper.parseIdToObject} format={InputHelper.formatObjectToId} >
                        <RA.SelectArrayInput 
                            // replace path property ('17;21;') with '>' to indicate sub-levels
                            optionText={x => `${x.path.replace(/(\d*;)/gm, ">").substring(1) + " " + x.name}`}
                            parse={InputHelper.parseIdToObject} format={InputHelper.formatObjectToId} 
                            />
                    </RA.ReferenceArrayInput>

                    {/* tree component (instead of area select) - WIP 
                        ref: https://marmelab.com/ra-enterprise/modules/ra-tree#tree-component
                        Status: 
                        - it's rendering fine
                        - it's not loading data from the DB nor saving (I hardcoded some to test) 
                            - we'd have to build a rc tree based custom RA component: https://marmelab.com/react-admin/useInput.html
                        - bug (in rc-tree): after some interaction the expand branch behaviour stops working :/
                            - not a big big issue, it should not affect most interactions, I guess
                    */}
                    {/* <Tree treeData={areas} checkable checkedKeys={checked} onCheck={onCheck} selectable={false} /> */}

                    <RA.ReferenceArrayInput source="technicalBranches" reference="TechnicalBranch" 
                        perPage={0} 
                        parse={InputHelper.parseIdToObject} format={InputHelper.formatObjectToId} >
                        <RA.SelectArrayInput optionText="name" parse={InputHelper.parseIdToObject} format={InputHelper.formatObjectToId} />
                    </RA.ReferenceArrayInput>

                </RA.FormTab>
                <RA.FormTab label="Fields">
                    <RA.ArrayInput 
                        source="fields" label="" {...props} >
                        <RA.SimpleFormIterator inline >

                            {/* SimpleFormIterator expects childs to be inputs. 
                                To customize the inner form layouts we need to use a FormDataConsumer to retrieve the correct sources. 
                                ref: https://marmelab.com/react-admin/ArrayInput.html
                                */}
                            <RA.FormDataConsumer >
                                {({ formData, scopedFormData, getSource, ...rest }) =>
                                    <>
                                        <RA.TextInput source={getSource?.('name') ?? ''} validate={[RA.required('Required')]} />
                                        <RA.SelectInput label="Field Type" sx={{ width: '250px' }} validate={[RA.required('Required')]}
                                            source={getSource?.('fieldType') ?? ''}
                                            choices={Object.entries(fieldTypes).map((x) => ({ id: x[1], name: x[0] }))}
                                        />
                                        <RA.BooleanInput label="Show In Main Search" source={getSource?.('showInMainSearch') ?? ''} />
                                        <RA.BooleanInput label="Show In List" source={getSource?.('showInList') ?? ''} />
                                        <div className='RaSimpleFormIterator-action' >
                                            <EditFieldButton source={getSource?.('id') ?? ''} fieldData={scopedFormData} unitTypes={unitTypes} />
                                        </div>
                                    </>
                                }
                            </RA.FormDataConsumer>

                        </RA.SimpleFormIterator>
                    </RA.ArrayInput>
                </RA.FormTab>
                {/* NTH: era giro ter aqui uma lista de equipamentos  */}
                {/* <RA.FormTab label="Equipments">
                <RA.ReferenceManyField reference="Equipment" target="id" >
                    <RA.Datagrid>
                        <RA.DateField source="id" />
                        <RA.TextField source="name" />
                        <RA.EditButton />  
                    </RA.Datagrid>
                </RA.ReferenceManyField>
            </RA.FormTab> */}
            </RA.TabbedForm>
    );
}

const EditFieldButton = ({source, fieldData, unitTypes} : any) => {
    
    const fieldTypes = AppService.FieldFormDTOFieldTypeEnum;
    
    const [showPanel, setShowPanel] = useState(false);
    const [scopedFormData, setScopedFormData] = useState<AppService.FieldFormDTO>(fieldData);
    
    const matches = source.match(/fields.(\d+).id/);
    const key = matches.length > 1 ? parseInt(matches[1]) : 0;

    const { replace } = useFieldArray({ name: 'fields' })

    // this let us access whatever react-hook-form (from RA) is wrapping the current component
    const form = useFormContext();

    const handleOpenClick = async () => {
        
        var formValues = form.getValues();
        var fieldData = formValues.fields[key];
        setScopedFormData(fieldData);

        setShowPanel(true);
    }

    const fieldSave = (data:any) => {
        
        var formValues = form.getValues();
        formValues.fields[key] = data;
        
        replace(formValues.fields);

        setShowPanel(false);
    };

    const FieldToolbar = () => {
        return (
            <RA.Toolbar>
                <RA.SaveButton label="OK" />
                <RA.Button label="Cancel"
                    onClick={() => setShowPanel(false)}
                    size='medium'
                />
            </RA.Toolbar>
        );
    };

    return (
        <>
            <RA.Button onClick={handleOpenClick} label="edit" startIcon={<Icons.EditOutlined />} />
            <Drawer anchor="right" open={showPanel} >
                {/* <div>
                    <RA.Button label="Close" onClick={handleCloseClick}>
                        <EquipmentTypeIcon />
                    </RA.Button>
                </div> 
                <div> */}
                    <RA.SimpleForm record={scopedFormData} onSubmit={fieldSave} toolbar={<FieldToolbar />}  sx={{width: 350}} > 
                        <RA.TranslatableInputs locales={InputHelper.locales} defaultLocale="pt" >
                            <RA.TextInput label="Display Name" source='displayNameI18n' />
                        </RA.TranslatableInputs>
                        <RA.BooleanInput label="Required" source='required' />
                        <br />
                        {scopedFormData?.fieldType == fieldTypes.Numeric &&
                            <RA.ReferenceInput
                                label="Unit" sx={{ width: '250px' }}
                                source='unitId'
                                perPage={0} // retrieve all 
                                reference="unit">
                                <RA.AutocompleteInput sx={{ width: '250px' }} 
                                    optionText={choice => `${unitTypes.find((x: any) => x.id == choice.unitTypeId)?.name} - ${choice.name}`} />
                            </RA.ReferenceInput>
                        }
                        {scopedFormData?.fieldType == fieldTypes.Text &&
                            <RA.SelectInput label="Text Display" sx={{ width: '250px' }}
                                source='textDisplay'
                                validate={[InputHelper.requiredIf(scopedFormData.fieldType == fieldTypes.Text)]}
                                defaultValue={null}
                                choices={Object.entries(AppService.FieldFormDTOTextDisplayEnum).map((x) => ({ id: x[1], name: x[0] }))}
                            />
                        }
                        {scopedFormData?.fieldType == fieldTypes.Option &&
                            <Box>
                                <RA.SelectInput label="Option Display" sx={{ width: '250px' }}
                                    source='optionDisplay'
                                    validate={[InputHelper.requiredIf(scopedFormData.fieldType == fieldTypes.Option)]}
                                    defaultValue={null}
                                    choices={Object.entries(AppService.FieldFormDTOOptionDisplayEnum).map((x) => ({ id: x[1], name: x[0] }))}
                                />
                                <br />
                                <RA.ReferenceInput
                                    label="Option Set" sx={{ width: '250px' }}
                                    source='optionSetId'
                                    perPage={0} // retrieve all 
                                    reference="optionSet">
                                    <RA.AutocompleteInput optionText="name" />
                                </RA.ReferenceInput>
                            </Box>
                        }
                    </RA.SimpleForm>
                {/* </div> */}
            </Drawer>
        </>
    );
};

export const EquipmentTypeEdit = (props: any) => (
            <RA.Edit {...props} >
                <BaseForm type="edit" />
            </RA.Edit>
);

export const EquipmentTypeCreate = (props: any) => (
            <RA.Create {...props}>
                <BaseForm type="create" />
            </RA.Create>
);

export default {
    name: 'EquipmentType',
    options: { label: 'Equipment Type' },
    list: EquipmentTypeList,
    edit: EquipmentTypeEdit,
    create: EquipmentTypeCreate,
    icon: EquipmentTypeIcon,
};

