import * as RA from 'react-admin';
import UnitTypeIcon from '@mui/icons-material/Bookmark';
import UnitTypeList from './UnitType_List';
import InputHelper from '../common/inputHelper';
import Styles from '../common/styles';
import { Box, Grid, Typography } from '@mui/material'
import { required } from 'react-admin';
import { SDK, AppService } from 'src/ts-axios-sdk/SDK'

const BaseForm = (props: any) => (

    <RA.TabbedForm warnWhenUnsavedChanges {...props}>
        <RA.FormTab label="Unit Type">

            {props.type === "edit" && <RA.NumberInput disabled source="id" />}

            <RA.TextInput  source="name" />

        </RA.FormTab>
        <RA.FormTab label="Units">
            <RA.ArrayInput source="units" label="" {...props} >
                <RA.SimpleFormIterator>

                    {/* SimpleFormIterator expects childs to be inputs. 
                    To customize the inner form layouts we need to use a FormDataConsumer to retrieve the correct sources. 
                    ref: https://marmelab.com/react-admin/ArrayInput.html
                    */}
                    <RA.FormDataConsumer>
                        {({ formData, scopedFormData, getSource, ...rest }) =>
                            <Grid container spacing={{ xs: 2, md: 8 }} columns={{ xs: 4, sm: 8, md: 12 }} >
                                
                                <Grid item >
                                    <RA.TextInput source={getSource?.('name') ?? ''} />
                                </Grid>

                            </Grid>
                        }
                    </RA.FormDataConsumer>

                </RA.SimpleFormIterator>
            </RA.ArrayInput>
        </RA.FormTab>

    </RA.TabbedForm>

);

const EditForm = (props: any) => (
    <RA.Edit {...props}>
        <BaseForm type="edit" />
    </RA.Edit>
);

const CreateForm = (props: any) => (
    <RA.Create {...props}>
        <BaseForm type="create" />
    </RA.Create>
);

export default {
    name: 'UnitType',
    options: {label: 'Unit Type' },
    list: UnitTypeList,
    edit: EditForm,
    create: CreateForm,
    icon: UnitTypeIcon,
};

