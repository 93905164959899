import {dataProvider, httpClient} from './dataProvider';

import { fetchJson } from './customFetchUtils';
import { stringify } from 'query-string';
import { addTreeMethodsBasedOnParentAndPosition, MoveAsNthChildOfResult, MoveAsNthSiblingOfOfResult } from '@react-admin/ra-tree';

const apiUrl = `${process.env.REACT_APP_API_BASE_URL}${process.env.REACT_APP_APPLICATION_API_PATH}`;

const parentIdField = 'parentId';
const positionField = 'position';

const dataProviderWithTree = addTreeMethodsBasedOnParentAndPosition(
    dataProvider,
    parentIdField,
    positionField,
    false
);

// we're overriding below some methods of the dataProviderWithTree
// if we implement all methods we can discard the dataProviderWithTree 
// that would be the recommended approach (but we're not experiencing performance issues)

// READ METHODS ----------------------------------------------

// dataProviderWithTree.getTree = async (resource: string) => {

//     const { json } = await httpClient(`${apiUrl}/${resource}/tree`, {
//         method: 'GET'
//     });

//     return { data: json };
// }
// getRootNodes(resource, { meta })
// getParentNode(resource, { childId, meta })
// getChildNodes(resource, { parentId, meta })

// WRITE METHODS ----------------------------------------------

dataProviderWithTree.deleteBranch = async (resource: string, params: any) => {

    const { json } = await httpClient(`${apiUrl}/${resource}/${params.id}/branch`, {
        method: 'DELETE'
    });

    return { data: params.previousData };
}

// moveAsNthChildOf(resource, { source, destination, position, meta })
dataProviderWithTree.moveAsNthChildOf = async (resource, { source, destination, position }) => {

    const { json } = await httpClient(`${apiUrl}/${resource}/movechild`, {
        method: 'POST',
        body: JSON.stringify({ source, destination, position })
    });

    return { data: null } as MoveAsNthChildOfResult<any>;
}

dataProviderWithTree.moveAsNthSiblingOf = async (
    resource,
    { source, destination, position }
) => {

    const { json } = await httpClient(`${apiUrl}/${resource}/movesibling`, {
        method: 'POST',
        body: JSON.stringify({ source, destination, position })
    });

    return { data: null } as MoveAsNthSiblingOfOfResult<any>;
}


export default dataProviderWithTree;