
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * React-Admin Create/Update components for: Setting Resource
 */

import * as RA from 'react-admin';
import SettingIcon from '@mui/icons-material/Bookmark';
import SettingList from './Setting_List';
import InputHelper from '../common/inputHelper';
import { SDK, AppService } from 'src/ts-axios-sdk/SDK'

const SettingCreate = () => (
    <RA.Create>
        <RA.SimpleForm warnWhenUnsavedChanges>
            
            <RA.TextInput source="group"  />
            <RA.TextInput source="name" />
            <RA.TextInput source="value" />
            <RA.TextInput source="description" />

        </RA.SimpleForm>
    </RA.Create>
);

const SettingEdit = () => (
    <RA.Edit>
        
        <RA.SimpleForm warnWhenUnsavedChanges>
        
            <RA.NumberInput source="id" disabled />
            <RA.TextInput source="group" disabled />
            <RA.TextInput source="name" disabled />
            <RA.TextInput source="value" />
            <RA.TextInput source="description" disabled />

        </RA.SimpleForm>
    </RA.Edit>
);

export default {
    name: 'Setting',
    options: {label: 'Setting' },
    list: SettingList,
    edit: SettingEdit,
    //create: SettingCreate, // no creation
    icon: SettingIcon,
};

