import { fetchJson } from './customFetchUtils';
import { stringify } from 'query-string';
import { SDK } from 'src/ts-axios-sdk/SDK'
import { fetchUtils } from 'react-admin';

const apiUrl = `${process.env.REACT_APP_API_BASE_URL}${process.env.REACT_APP_APPLICATION_API_PATH}`;

export const httpClient = async (url: string, options: any = {}) => {
    if (!options.headers) {
        options.headers = new Headers({ Accept: 'application/json' });
    }
    const token = await SDK.GetAccessTokenAsync();

    if (!token)
        return Promise.reject();

    options.headers.set('Authorization', `Bearer ${token}`);
    return fetchUtils.fetchJson(url, options);
}

var search = async (resource: string, filter?: any, page?: number, pageSize?: number, sortBy?: string, sortDirection?: string) => {

    const queryString = {
        page: (page ?? 0) - 1,
        pageSize: pageSize,
        sortBy: sortBy,
        sortDirection: sortDirection
    };
    const url = `${apiUrl}/${resource}/list?${stringify(queryString)}`;
    const { json } = await httpClient(url, {
        method: 'POST',
        body: JSON.stringify(filter ?? {})
    });
    return ({
        data: json.items,
        total: json.totalItems
    });
}

// convert new uploads to base64, sets metadata (contentId and contentType)
async function handleFiles(obj: { [key: string]: any }, id: string, resource: any): Promise<void> {
    if (!obj) {
        return; // or handle the error appropriately
    }

    // aux method - checks if a payload prop is a file
    const isFile = (value: any) => 'rawFile' in value && value.rawFile instanceof File;

    // aux method - converts an object rawFile property to base64 
    // (and adds some metadata expected by the endpoints)
    const convertFile = function (fileObject: any, id: string, resource: string, prop: string) {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
      
          reader.onloadend = function () {
            if (reader.result) {
                const obj = {
                    base64: reader.result,
                    entityId: id,
                    entityType: resource,
                    title: fileObject.title, 
                    key: prop
                };
                resolve(obj);
            } else {
              reject(new Error('Failed to read the file/blob.'));
            }
          };
      
          reader.onerror = function () {
            reject(new Error('Error reading the file/blob.'));
          };
      
          if ('rawFile' in fileObject) {
            reader.readAsDataURL(fileObject.rawFile);
          } else {
            resolve(fileObject);
          }
        });
      };

    await Promise.all(
        Object.entries(obj || {}).map(async ([prop, value]) => {
            if (Array.isArray(value) && value.some((x: any) => isFile(x))) {
                
                var newArray = new Array();
                for (const arrayValue of value) {
                    var newFileObj = await convertFile(arrayValue, id, resource, prop);
                    newArray.push(newFileObj);
                }
                obj[prop] = newArray;

            } else if (value && typeof value === 'object' && isFile(value)) {
                var newProp = await convertFile(value, id, resource, prop);
                obj[prop] = newProp;
            } else if (typeof value === 'object') {
                // recursively convert nested properties in object
                await handleFiles(value, id, resource);
            }
        })
    );
}


// ref: https://marmelab.com/react-admin/DataProviderWriting.html
export const dataProvider = {

    // Search for resources
    getList: async (resource: any, params: any) => {

        const { page, perPage } = params.pagination;
        const { field, order } = params.sort;

        return await search(resource, params.filter, page, perPage, field, order);
    },

    // Read a list of resource, by ids
    getMany: async (resource: any, params: any) => {

        var size = params.ids.length;
        return await search(resource, { 'id': params.ids }, 1, size);
    },

    getManyReference: async (resource: any, params: any) => {
        const { page, perPage } = params.pagination;
        const { field, order } = params.sort;

        var filter = {
            ...params.filter,
            [params.target]: params.id,
        };
        return await search(resource, filter, page, perPage, field, order);
    },

    getOne: (resource: any, params: any) =>
        httpClient(`${apiUrl}/${resource}/${params.id}`).then(({ json }) => ({
            data: json,
        })),

    create: async (resource: any, params: any) => {
        await handleFiles(params.data, params.id, resource);
        return httpClient(`${apiUrl}/${resource}`, {
            method: 'POST',
            body: JSON.stringify(params.data),
        }).then(({ json }) => ({
            data: { ...params.data, id: json.id },
        }));
    },

    update: async (resource: any, params: any) => {
        await handleFiles(params.data, params.id, resource);
        return httpClient(`${apiUrl}/${resource}/${params.id}`, {
            method: 'PUT',
            body: JSON.stringify(params.data),
        }).then(({ json }) => ({ data: json }));
    },

    updateMany: async (resource: any, params: any) => {
        const query = {
            filter: JSON.stringify({ id: params.ids }),
        };
        const { json } = await httpClient(`${apiUrl}/${resource}?${stringify(query)}`, {
            method: 'PUT',
            body: JSON.stringify(params.data),
        });
        return ({ data: json });
    },

    delete: (resource: any, params: any) =>
        httpClient(`${apiUrl}/${resource}/${params.id}`, {
            method: 'DELETE',
        }).then(({ json }) => ({ data: json })),

    deleteMany: async (resource: any, params: any) => {
        const { json } = await httpClient(`${apiUrl}/${resource}?${stringify({ ids: params.ids })}`, {
            method: 'DELETE'
        });
        return ({ data: json });
    },
};
