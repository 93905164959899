import { useState, useEffect } from 'react';
import { TextField, Autocomplete } from "@mui/material";
import { SDK, AppService } from 'src/ts-axios-sdk/SDK'

const CountrySelect = (props: any) => {

  // TODO: search as you type
  const [options, setOptions] = useState<any>([]);
  const [val, setVal] = useState<any>(props.value);

  useEffect(() => {

    const fetchCountries = async () => {
      const api = AppService.CountryApiFactory(...SDK.Config('en'));
      await api.countryGetMany()
        .then(async (response) => {
          // map {id, name} => {id, label} as expected by mui Autocomplete component
          const countries = response.data.items?.map((x) => ({ id: x.id, label: x.name })) as readonly { id: any; label: any; }[];
          setOptions(countries);
          setVal(countries.find((x: any) => x.id === props.value))
        });
    }

    fetchCountries().catch(console.error);

  }, [props.value]) 

  const handleOnChange = (event: any, value: any) => {
    props.externalCallback(event, value);
  };

  return (
    <Autocomplete
      disablePortal
      id="countries-select"
      isOptionEqualToValue={(option, value) => option.id === value.id}
      options={options || []}
      sx={{ width: 300 }}
      onChange={handleOnChange}
      loading={options.length == 0}
      value={val || null}
      getOptionLabel={option => option?.label || ""}
      renderInput={(params) => <TextField {...params} label="Countries" />}
    />
  );

}

export default CountrySelect;