/* tslint:disable */
/* eslint-disable */
/**
 * Application
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface Area
 */
export interface Area {
    /**
     * 
     * @type {string}
     * @memberof Area
     */
    'uniqueId'?: string;
    /**
     * 
     * @type {number}
     * @memberof Area
     */
    'umbracoId'?: number;
    /**
     * 
     * @type {string}
     * @memberof Area
     */
    'path'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof Area
     */
    'position'?: number;
    /**
     * 
     * @type {number}
     * @memberof Area
     */
    'parentId'?: number | null;
    /**
     * 
     * @type {Area}
     * @memberof Area
     */
    'parent'?: Area;
    /**
     * 
     * @type {Array<Area>}
     * @memberof Area
     */
    'children'?: Array<Area> | null;
    /**
     * 
     * @type {Array<MediaAttachment>}
     * @memberof Area
     */
    'images'?: Array<MediaAttachment> | null;
    /**
     * 
     * @type {Array<EquipmentType>}
     * @memberof Area
     */
    'equipmentTypes'?: Array<EquipmentType> | null;
    /**
     * 
     * @type {string}
     * @memberof Area
     */
    'name'?: string | null;
    /**
     * 
     * @type {I18nField}
     * @memberof Area
     */
    'displayNameI18n'?: I18nField;
    /**
     * 
     * @type {string}
     * @memberof Area
     */
    'created'?: string;
    /**
     * 
     * @type {string}
     * @memberof Area
     */
    'lastUpdated'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Area
     */
    'deleted'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof Area
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof Area
     */
    'idAsString'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Area
     */
    'stateFlag'?: AreaStateFlagEnum;
}

export const AreaStateFlagEnum = {
    Detached: 'detached',
    Unchanged: 'unchanged',
    Deleted: 'deleted',
    Modified: 'modified',
    Added: 'added'
} as const;

export type AreaStateFlagEnum = typeof AreaStateFlagEnum[keyof typeof AreaStateFlagEnum];

/**
 * 
 * @export
 * @interface AreaFormDTO
 */
export interface AreaFormDTO {
    /**
     * 
     * @type {number}
     * @memberof AreaFormDTO
     */
    'position'?: number;
    /**
     * 
     * @type {number}
     * @memberof AreaFormDTO
     */
    'parentId'?: number | null;
    /**
     * 
     * @type {Array<MediaAttachmentDTO>}
     * @memberof AreaFormDTO
     */
    'images'?: Array<MediaAttachmentDTO> | null;
    /**
     * 
     * @type {number}
     * @memberof AreaFormDTO
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof AreaFormDTO
     */
    'name'?: string | null;
    /**
     * 
     * @type {any}
     * @memberof AreaFormDTO
     */
    'displayNameI18n'?: any | null;
    /**
     * 
     * @type {number}
     * @memberof AreaFormDTO
     */
    'stateFlag'?: number;
}
/**
 * 
 * @export
 * @interface AreaListDTO
 */
export interface AreaListDTO {
    /**
     * 
     * @type {string}
     * @memberof AreaListDTO
     */
    'path'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof AreaListDTO
     */
    'position'?: number;
    /**
     * 
     * @type {number}
     * @memberof AreaListDTO
     */
    'parentId'?: number | null;
    /**
     * 
     * @type {Array<MediaAttachmentDTO>}
     * @memberof AreaListDTO
     */
    'images'?: Array<MediaAttachmentDTO> | null;
    /**
     * 
     * @type {number}
     * @memberof AreaListDTO
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof AreaListDTO
     */
    'name'?: string | null;
    /**
     * 
     * @type {any}
     * @memberof AreaListDTO
     */
    'displayNameI18n'?: any | null;
    /**
     * 
     * @type {number}
     * @memberof AreaListDTO
     */
    'stateFlag'?: number;
}
/**
 * 
 * @export
 * @interface AreaTreeDTO
 */
export interface AreaTreeDTO {
    /**
     * 
     * @type {number}
     * @memberof AreaTreeDTO
     */
    'key'?: number;
    /**
     * 
     * @type {string}
     * @memberof AreaTreeDTO
     */
    'title'?: string | null;
    /**
     * 
     * @type {Array<AreaTreeDTO>}
     * @memberof AreaTreeDTO
     */
    'children'?: Array<AreaTreeDTO> | null;
}
/**
 * 
 * @export
 * @interface AreaTreeUiDTO
 */
export interface AreaTreeUiDTO {
    /**
     * 
     * @type {number}
     * @memberof AreaTreeUiDTO
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof AreaTreeUiDTO
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AreaTreeUiDTO
     */
    'displayNameI18n'?: string | null;
    /**
     * 
     * @type {Array<MediaUiDTO>}
     * @memberof AreaTreeUiDTO
     */
    'images'?: Array<MediaUiDTO> | null;
    /**
     * 
     * @type {Array<AreaTreeUiDTO>}
     * @memberof AreaTreeUiDTO
     */
    'children'?: Array<AreaTreeUiDTO> | null;
}
/**
 * 
 * @export
 * @interface BaseProfileDTO
 */
export interface BaseProfileDTO {
    /**
     * 
     * @type {string}
     * @memberof BaseProfileDTO
     */
    'job'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BaseProfileDTO
     */
    'company'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof BaseProfileDTO
     */
    'branchId'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof BaseProfileDTO
     */
    'streetAddress'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BaseProfileDTO
     */
    'city'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BaseProfileDTO
     */
    'postalCode'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof BaseProfileDTO
     */
    'countryId'?: number | null;
}
/**
 * 
 * @export
 * @interface Brand
 */
export interface Brand {
    /**
     * 
     * @type {boolean}
     * @memberof Brand
     */
    'published'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof Brand
     */
    'umbracoId'?: number;
    /**
     * 
     * @type {string}
     * @memberof Brand
     */
    'uniqueId'?: string;
    /**
     * 
     * @type {I18nField}
     * @memberof Brand
     */
    'description'?: I18nField;
    /**
     * 
     * @type {I18nField}
     * @memberof Brand
     */
    'services'?: I18nField;
    /**
     * 
     * @type {string}
     * @memberof Brand
     */
    'website'?: string | null;
    /**
     * 
     * @type {Office}
     * @memberof Brand
     */
    'mainOffice'?: Office;
    /**
     * 
     * @type {MediaAttachment}
     * @memberof Brand
     */
    'logo'?: MediaAttachment;
    /**
     * 
     * @type {Array<MediaAttachment>}
     * @memberof Brand
     */
    'images'?: Array<MediaAttachment> | null;
    /**
     * 
     * @type {Array<Equipment>}
     * @memberof Brand
     */
    'equipments'?: Array<Equipment> | null;
    /**
     * 
     * @type {Array<TechnicalBranch>}
     * @memberof Brand
     */
    'technicalBranches'?: Array<TechnicalBranch> | null;
    /**
     * 
     * @type {string}
     * @memberof Brand
     */
    'name'?: string | null;
    /**
     * 
     * @type {I18nField}
     * @memberof Brand
     */
    'displayNameI18n'?: I18nField;
    /**
     * 
     * @type {string}
     * @memberof Brand
     */
    'created'?: string;
    /**
     * 
     * @type {string}
     * @memberof Brand
     */
    'lastUpdated'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Brand
     */
    'deleted'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof Brand
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof Brand
     */
    'idAsString'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Brand
     */
    'stateFlag'?: BrandStateFlagEnum;
}

export const BrandStateFlagEnum = {
    Detached: 'detached',
    Unchanged: 'unchanged',
    Deleted: 'deleted',
    Modified: 'modified',
    Added: 'added'
} as const;

export type BrandStateFlagEnum = typeof BrandStateFlagEnum[keyof typeof BrandStateFlagEnum];

/**
 * 
 * @export
 * @interface BrandBaseDTO
 */
export interface BrandBaseDTO {
    /**
     * 
     * @type {number}
     * @memberof BrandBaseDTO
     */
    'id'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof BrandBaseDTO
     */
    'name'?: string | null;
    /**
     * 
     * @type {any}
     * @memberof BrandBaseDTO
     */
    'displayNameI18n'?: any | null;
    /**
     * 
     * @type {number}
     * @memberof BrandBaseDTO
     */
    'stateFlag'?: number | null;
    /**
     * 
     * @type {any}
     * @memberof BrandBaseDTO
     */
    'description'?: any | null;
    /**
     * 
     * @type {any}
     * @memberof BrandBaseDTO
     */
    'services'?: any | null;
    /**
     * 
     * @type {string}
     * @memberof BrandBaseDTO
     */
    'website'?: string | null;
    /**
     * 
     * @type {Array<ChildDTOOfInt32>}
     * @memberof BrandBaseDTO
     */
    'technicalBranches'?: Array<ChildDTOOfInt32> | null;
    /**
     * 
     * @type {MediaAttachmentDTO}
     * @memberof BrandBaseDTO
     */
    'logo'?: MediaAttachmentDTO;
    /**
     * 
     * @type {Array<MediaAttachmentDTO>}
     * @memberof BrandBaseDTO
     */
    'images'?: Array<MediaAttachmentDTO> | null;
    /**
     * 
     * @type {OfficeFormDTO}
     * @memberof BrandBaseDTO
     */
    'mainOffice'?: OfficeFormDTO;
}
/**
 * 
 * @export
 * @interface BrandEditorBrandDTO
 */
export interface BrandEditorBrandDTO {
    /**
     * 
     * @type {number}
     * @memberof BrandEditorBrandDTO
     */
    'brandId'?: number;
}
/**
 * 
 * @export
 * @interface BrandEditorProfileDTO
 */
export interface BrandEditorProfileDTO {
    /**
     * 
     * @type {Array<BrandEditorBrandDTO>}
     * @memberof BrandEditorProfileDTO
     */
    'brands'?: Array<BrandEditorBrandDTO> | null;
}
/**
 * 
 * @export
 * @interface BrandFormDTO
 */
export interface BrandFormDTO {
    /**
     * 
     * @type {boolean}
     * @memberof BrandFormDTO
     */
    'published'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof BrandFormDTO
     */
    'id'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof BrandFormDTO
     */
    'name'?: string | null;
    /**
     * 
     * @type {any}
     * @memberof BrandFormDTO
     */
    'displayNameI18n'?: any | null;
    /**
     * 
     * @type {number}
     * @memberof BrandFormDTO
     */
    'stateFlag'?: number | null;
    /**
     * 
     * @type {any}
     * @memberof BrandFormDTO
     */
    'description'?: any | null;
    /**
     * 
     * @type {any}
     * @memberof BrandFormDTO
     */
    'services'?: any | null;
    /**
     * 
     * @type {string}
     * @memberof BrandFormDTO
     */
    'website'?: string | null;
    /**
     * 
     * @type {Array<ChildDTOOfInt32>}
     * @memberof BrandFormDTO
     */
    'technicalBranches'?: Array<ChildDTOOfInt32> | null;
    /**
     * 
     * @type {MediaAttachmentDTO}
     * @memberof BrandFormDTO
     */
    'logo'?: MediaAttachmentDTO;
    /**
     * 
     * @type {Array<MediaAttachmentDTO>}
     * @memberof BrandFormDTO
     */
    'images'?: Array<MediaAttachmentDTO> | null;
    /**
     * 
     * @type {OfficeFormDTO}
     * @memberof BrandFormDTO
     */
    'mainOffice'?: OfficeFormDTO;
}
/**
 * 
 * @export
 * @interface BrandListDTO
 */
export interface BrandListDTO {
    /**
     * 
     * @type {string}
     * @memberof BrandListDTO
     */
    'name'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof BrandListDTO
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof BrandListDTO
     */
    'stateFlag'?: number;
}
/**
 * 
 * @export
 * @interface BrandSearchDTO
 */
export interface BrandSearchDTO {
    /**
     * 
     * @type {number}
     * @memberof BrandSearchDTO
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof BrandSearchDTO
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BrandSearchDTO
     */
    'displayNameI18n'?: string | null;
    /**
     * 
     * @type {MediaUiDTO}
     * @memberof BrandSearchDTO
     */
    'logo'?: MediaUiDTO;
    /**
     * 
     * @type {number}
     * @memberof BrandSearchDTO
     */
    'equipmentCount'?: number;
}
/**
 * 
 * @export
 * @interface BrandSearchDto
 */
export interface BrandSearchDto {
    /**
     * 
     * @type {number}
     * @memberof BrandSearchDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof BrandSearchDto
     */
    'name'?: string | null;
}
/**
 * 
 * @export
 * @interface BrandUiDTO
 */
export interface BrandUiDTO {
    /**
     * 
     * @type {string}
     * @memberof BrandUiDTO
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BrandUiDTO
     */
    'displayNameI18n'?: string | null;
    /**
     * 
     * @type {MediaUiDTO}
     * @memberof BrandUiDTO
     */
    'logo'?: MediaUiDTO;
    /**
     * 
     * @type {number}
     * @memberof BrandUiDTO
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof BrandUiDTO
     */
    'stateFlag'?: number;
}
/**
 * 
 * @export
 * @interface BrandUiFullDTO
 */
export interface BrandUiFullDTO {
    /**
     * 
     * @type {string}
     * @memberof BrandUiFullDTO
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BrandUiFullDTO
     */
    'displayNameI18n'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BrandUiFullDTO
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BrandUiFullDTO
     */
    'services'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BrandUiFullDTO
     */
    'website'?: string | null;
    /**
     * 
     * @type {Array<TechnicalBranchUiDTO>}
     * @memberof BrandUiFullDTO
     */
    'technicalBranches'?: Array<TechnicalBranchUiDTO> | null;
    /**
     * 
     * @type {MediaUiDTO}
     * @memberof BrandUiFullDTO
     */
    'logo'?: MediaUiDTO;
    /**
     * 
     * @type {Array<MediaUiDTO>}
     * @memberof BrandUiFullDTO
     */
    'images'?: Array<MediaUiDTO> | null;
    /**
     * 
     * @type {OfficeUiDTO}
     * @memberof BrandUiFullDTO
     */
    'mainOffice'?: OfficeUiDTO;
}
/**
 * 
 * @export
 * @interface BrandsUpsertUserBrandCommand
 */
export interface BrandsUpsertUserBrandCommand {
    /**
     * 
     * @type {BrandFormDTO}
     * @memberof BrandsUpsertUserBrandCommand
     */
    'brand'?: BrandFormDTO;
}
/**
 * 
 * @export
 * @interface ChildDTOOfGuid
 */
export interface ChildDTOOfGuid {
    /**
     * 
     * @type {string}
     * @memberof ChildDTOOfGuid
     */
    'id'?: string;
    /**
     * 
     * @type {number}
     * @memberof ChildDTOOfGuid
     */
    'stateFlag'?: number;
}
/**
 * 
 * @export
 * @interface ChildDTOOfInt32
 */
export interface ChildDTOOfInt32 {
    /**
     * 
     * @type {number}
     * @memberof ChildDTOOfInt32
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof ChildDTOOfInt32
     */
    'stateFlag'?: number;
}
/**
 * 
 * @export
 * @interface ConsentDTO
 */
export interface ConsentDTO {
    /**
     * 
     * @type {string}
     * @memberof ConsentDTO
     */
    'consentState'?: ConsentDTOConsentStateEnum;
    /**
     * 
     * @type {string}
     * @memberof ConsentDTO
     */
    'created'?: string;
    /**
     * 
     * @type {string}
     * @memberof ConsentDTO
     */
    'lastUpdated'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ConsentDTO
     */
    'policyId'?: number;
}

export const ConsentDTOConsentStateEnum = {
    Granted: 'granted',
    Revoked: 'revoked'
} as const;

export type ConsentDTOConsentStateEnum = typeof ConsentDTOConsentStateEnum[keyof typeof ConsentDTOConsentStateEnum];

/**
 * 
 * @export
 * @interface ContentFormDTO
 */
export interface ContentFormDTO {
    /**
     * 
     * @type {number}
     * @memberof ContentFormDTO
     */
    'position'?: number;
    /**
     * 
     * @type {number}
     * @memberof ContentFormDTO
     */
    'parentId'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ContentFormDTO
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentFormDTO
     */
    'contentType'?: ContentFormDTOContentTypeEnum;
    /**
     * 
     * @type {any}
     * @memberof ContentFormDTO
     */
    'jsonData'?: any | null;
    /**
     * 
     * @type {boolean}
     * @memberof ContentFormDTO
     */
    'published'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ContentFormDTO
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof ContentFormDTO
     */
    'stateFlag'?: number;
}

export const ContentFormDTOContentTypeEnum = {
    Page: 'page',
    ImageTextModule: 'imageTextModule',
    TextModule: 'textModule',
    HeroModule: 'heroModule'
} as const;

export type ContentFormDTOContentTypeEnum = typeof ContentFormDTOContentTypeEnum[keyof typeof ContentFormDTOContentTypeEnum];

/**
 * 
 * @export
 * @interface ContentListDTO
 */
export interface ContentListDTO {
    /**
     * 
     * @type {string}
     * @memberof ContentListDTO
     */
    'path'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ContentListDTO
     */
    'position'?: number;
    /**
     * 
     * @type {number}
     * @memberof ContentListDTO
     */
    'parentId'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ContentListDTO
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentListDTO
     */
    'contentType'?: ContentListDTOContentTypeEnum;
    /**
     * 
     * @type {any}
     * @memberof ContentListDTO
     */
    'jsonData'?: any | null;
    /**
     * 
     * @type {boolean}
     * @memberof ContentListDTO
     */
    'published'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ContentListDTO
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof ContentListDTO
     */
    'stateFlag'?: number;
}

export const ContentListDTOContentTypeEnum = {
    Page: 'page',
    ImageTextModule: 'imageTextModule',
    TextModule: 'textModule',
    HeroModule: 'heroModule'
} as const;

export type ContentListDTOContentTypeEnum = typeof ContentListDTOContentTypeEnum[keyof typeof ContentListDTOContentTypeEnum];

/**
 * 
 * @export
 * @interface ContentTreeDTO
 */
export interface ContentTreeDTO {
    /**
     * 
     * @type {number}
     * @memberof ContentTreeDTO
     */
    'key'?: number;
    /**
     * 
     * @type {string}
     * @memberof ContentTreeDTO
     */
    'title'?: string | null;
    /**
     * 
     * @type {Array<ContentTreeDTO>}
     * @memberof ContentTreeDTO
     */
    'children'?: Array<ContentTreeDTO> | null;
}
/**
 * 
 * @export
 * @interface ContentTreeUiDTO
 */
export interface ContentTreeUiDTO {
    /**
     * 
     * @type {string}
     * @memberof ContentTreeUiDTO
     */
    'contentType'?: ContentTreeUiDTOContentTypeEnum;
    /**
     * 
     * @type {boolean}
     * @memberof ContentTreeUiDTO
     */
    'published'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ContentTreeUiDTO
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof ContentTreeUiDTO
     */
    'name'?: string | null;
    /**
     * 
     * @type {Array<ContentTreeUiDTO>}
     * @memberof ContentTreeUiDTO
     */
    'children'?: Array<ContentTreeUiDTO> | null;
    /**
     * 
     * @type {any}
     * @memberof ContentTreeUiDTO
     */
    'jsonData'?: any | null;
}

export const ContentTreeUiDTOContentTypeEnum = {
    Page: 'page',
    ImageTextModule: 'imageTextModule',
    TextModule: 'textModule',
    HeroModule: 'heroModule'
} as const;

export type ContentTreeUiDTOContentTypeEnum = typeof ContentTreeUiDTOContentTypeEnum[keyof typeof ContentTreeUiDTOContentTypeEnum];

/**
 * 
 * @export
 * @interface Country
 */
export interface Country {
    /**
     * 
     * @type {string}
     * @memberof Country
     */
    'alpha2'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Country
     */
    'alpha3'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Country
     */
    'phonePrefix'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Country
     */
    'currency'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Country
     */
    'latLong'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Country
     */
    'capital'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Country
     */
    'timezones'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Country
     */
    'languages'?: string | null;
    /**
     * 
     * @type {Region}
     * @memberof Country
     */
    'region'?: Region;
    /**
     * 
     * @type {number}
     * @memberof Country
     */
    'regionId'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof Country
     */
    'name'?: string | null;
    /**
     * 
     * @type {I18nField}
     * @memberof Country
     */
    'displayNameI18n'?: I18nField;
    /**
     * 
     * @type {string}
     * @memberof Country
     */
    'created'?: string;
    /**
     * 
     * @type {string}
     * @memberof Country
     */
    'lastUpdated'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Country
     */
    'deleted'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof Country
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof Country
     */
    'idAsString'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Country
     */
    'stateFlag'?: CountryStateFlagEnum;
}

export const CountryStateFlagEnum = {
    Detached: 'detached',
    Unchanged: 'unchanged',
    Deleted: 'deleted',
    Modified: 'modified',
    Added: 'added'
} as const;

export type CountryStateFlagEnum = typeof CountryStateFlagEnum[keyof typeof CountryStateFlagEnum];

/**
 * 
 * @export
 * @interface CountryFormDTO
 */
export interface CountryFormDTO {
    /**
     * 
     * @type {string}
     * @memberof CountryFormDTO
     */
    'alpha2'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CountryFormDTO
     */
    'alpha3'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CountryFormDTO
     */
    'phonePrefix'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CountryFormDTO
     */
    'currency'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CountryFormDTO
     */
    'latLong'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CountryFormDTO
     */
    'capital'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CountryFormDTO
     */
    'timezones'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CountryFormDTO
     */
    'languages'?: string | null;
    /**
     * 
     * @type {Region}
     * @memberof CountryFormDTO
     */
    'region'?: Region;
    /**
     * 
     * @type {number}
     * @memberof CountryFormDTO
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof CountryFormDTO
     */
    'name'?: string | null;
    /**
     * 
     * @type {any}
     * @memberof CountryFormDTO
     */
    'displayNameI18n'?: any | null;
    /**
     * 
     * @type {number}
     * @memberof CountryFormDTO
     */
    'stateFlag'?: number;
}
/**
 * 
 * @export
 * @interface CountryListDTO
 */
export interface CountryListDTO {
    /**
     * 
     * @type {string}
     * @memberof CountryListDTO
     */
    'alpha2'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CountryListDTO
     */
    'phonePrefix'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CountryListDTO
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof CountryListDTO
     */
    'name'?: string | null;
    /**
     * 
     * @type {any}
     * @memberof CountryListDTO
     */
    'displayNameI18n'?: any | null;
    /**
     * 
     * @type {number}
     * @memberof CountryListDTO
     */
    'stateFlag'?: number;
}
/**
 * 
 * @export
 * @interface CountryUiDTO
 */
export interface CountryUiDTO {
    /**
     * 
     * @type {number}
     * @memberof CountryUiDTO
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof CountryUiDTO
     */
    'displayNameI18n'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CountryUiDTO
     */
    'regionId'?: number | null;
}
/**
 * 
 * @export
 * @interface Equipment
 */
export interface Equipment {
    /**
     * 
     * @type {any}
     * @memberof Equipment
     */
    'jsonData'?: any | null;
    /**
     * 
     * @type {boolean}
     * @memberof Equipment
     */
    'published'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Equipment
     */
    'name'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof Equipment
     */
    'brandId'?: number | null;
    /**
     * 
     * @type {Brand}
     * @memberof Equipment
     */
    'brand'?: Brand;
    /**
     * 
     * @type {number}
     * @memberof Equipment
     */
    'madeInCountryId'?: number | null;
    /**
     * 
     * @type {Country}
     * @memberof Equipment
     */
    'madeInCountry'?: Country;
    /**
     * 
     * @type {Array<MediaAttachment>}
     * @memberof Equipment
     */
    'attachments'?: Array<MediaAttachment> | null;
    /**
     * 
     * @type {number}
     * @memberof Equipment
     */
    'equipmentTypeId'?: number;
    /**
     * 
     * @type {EquipmentType}
     * @memberof Equipment
     */
    'equipmentType'?: EquipmentType;
    /**
     * 
     * @type {string}
     * @memberof Equipment
     */
    'created'?: string;
    /**
     * 
     * @type {string}
     * @memberof Equipment
     */
    'lastUpdated'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Equipment
     */
    'deleted'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof Equipment
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof Equipment
     */
    'idAsString'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Equipment
     */
    'stateFlag'?: EquipmentStateFlagEnum;
}

export const EquipmentStateFlagEnum = {
    Detached: 'detached',
    Unchanged: 'unchanged',
    Deleted: 'deleted',
    Modified: 'modified',
    Added: 'added'
} as const;

export type EquipmentStateFlagEnum = typeof EquipmentStateFlagEnum[keyof typeof EquipmentStateFlagEnum];

/**
 * 
 * @export
 * @interface EquipmentComposition
 */
export interface EquipmentComposition {
    /**
     * 
     * @type {number}
     * @memberof EquipmentComposition
     */
    'order'?: number;
    /**
     * 
     * @type {number}
     * @memberof EquipmentComposition
     */
    'sourceId'?: number;
    /**
     * 
     * @type {EquipmentType}
     * @memberof EquipmentComposition
     */
    'source'?: EquipmentType;
    /**
     * 
     * @type {number}
     * @memberof EquipmentComposition
     */
    'destinationId'?: number;
    /**
     * 
     * @type {EquipmentType}
     * @memberof EquipmentComposition
     */
    'destination'?: EquipmentType;
    /**
     * 
     * @type {string}
     * @memberof EquipmentComposition
     */
    'created'?: string;
    /**
     * 
     * @type {string}
     * @memberof EquipmentComposition
     */
    'lastUpdated'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EquipmentComposition
     */
    'deleted'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof EquipmentComposition
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof EquipmentComposition
     */
    'idAsString'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EquipmentComposition
     */
    'stateFlag'?: EquipmentCompositionStateFlagEnum;
}

export const EquipmentCompositionStateFlagEnum = {
    Detached: 'detached',
    Unchanged: 'unchanged',
    Deleted: 'deleted',
    Modified: 'modified',
    Added: 'added'
} as const;

export type EquipmentCompositionStateFlagEnum = typeof EquipmentCompositionStateFlagEnum[keyof typeof EquipmentCompositionStateFlagEnum];

/**
 * 
 * @export
 * @interface EquipmentFormDTO
 */
export interface EquipmentFormDTO {
    /**
     * 
     * @type {any}
     * @memberof EquipmentFormDTO
     */
    'jsonData'?: any | null;
    /**
     * 
     * @type {string}
     * @memberof EquipmentFormDTO
     */
    'name'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof EquipmentFormDTO
     */
    'equipmentTypeId'?: number;
    /**
     * 
     * @type {number}
     * @memberof EquipmentFormDTO
     */
    'brandId'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof EquipmentFormDTO
     */
    'madeInCountryId'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof EquipmentFormDTO
     */
    'published'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof EquipmentFormDTO
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof EquipmentFormDTO
     */
    'stateFlag'?: number;
}
/**
 * 
 * @export
 * @interface EquipmentListDTO
 */
export interface EquipmentListDTO {
    /**
     * 
     * @type {string}
     * @memberof EquipmentListDTO
     */
    'name'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof EquipmentListDTO
     */
    'equipmentTypeId'?: number;
    /**
     * 
     * @type {number}
     * @memberof EquipmentListDTO
     */
    'brandId'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof EquipmentListDTO
     */
    'madeInCountryId'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof EquipmentListDTO
     */
    'published'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof EquipmentListDTO
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof EquipmentListDTO
     */
    'stateFlag'?: number;
}
/**
 * 
 * @export
 * @interface EquipmentMainSearchDTO
 */
export interface EquipmentMainSearchDTO {
    /**
     * 
     * @type {number}
     * @memberof EquipmentMainSearchDTO
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof EquipmentMainSearchDTO
     */
    'name'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof EquipmentMainSearchDTO
     */
    'equipmentTypeId'?: number;
    /**
     * 
     * @type {string}
     * @memberof EquipmentMainSearchDTO
     */
    'equipmentTypeName'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof EquipmentMainSearchDTO
     */
    'brandId'?: number | null;
}
/**
 * 
 * @export
 * @interface EquipmentSearchDTO
 */
export interface EquipmentSearchDTO {
    /**
     * 
     * @type {number}
     * @memberof EquipmentSearchDTO
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof EquipmentSearchDTO
     */
    'name'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof EquipmentSearchDTO
     */
    'published'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof EquipmentSearchDTO
     */
    'lastUpdated'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EquipmentSearchDTO
     */
    'created'?: string | null;
    /**
     * 
     * @type {BrandSearchDto}
     * @memberof EquipmentSearchDTO
     */
    'brand'?: BrandSearchDto;
    /**
     * 
     * @type {any}
     * @memberof EquipmentSearchDTO
     */
    'jsonData'?: any | null;
}
/**
 * 
 * @export
 * @interface EquipmentType
 */
export interface EquipmentType {
    /**
     * 
     * @type {string}
     * @memberof EquipmentType
     */
    'uniqueId'?: string;
    /**
     * 
     * @type {number}
     * @memberof EquipmentType
     */
    'umbracoId'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof EquipmentType
     */
    'published'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof EquipmentType
     */
    'isBaseEquipment'?: boolean;
    /**
     * 
     * @type {MediaAttachment}
     * @memberof EquipmentType
     */
    'image'?: MediaAttachment;
    /**
     * 
     * @type {Array<TechnicalBranch>}
     * @memberof EquipmentType
     */
    'technicalBranches'?: Array<TechnicalBranch> | null;
    /**
     * 
     * @type {Array<Area>}
     * @memberof EquipmentType
     */
    'areas'?: Array<Area> | null;
    /**
     * 
     * @type {Array<Equipment>}
     * @memberof EquipmentType
     */
    'equipments'?: Array<Equipment> | null;
    /**
     * 
     * @type {Array<Field>}
     * @memberof EquipmentType
     */
    'fields'?: Array<Field> | null;
    /**
     * 
     * @type {Array<EquipmentType>}
     * @memberof EquipmentType
     */
    'sourceEquipments'?: Array<EquipmentType> | null;
    /**
     * 
     * @type {Array<EquipmentComposition>}
     * @memberof EquipmentType
     */
    'sourceCompositions'?: Array<EquipmentComposition> | null;
    /**
     * 
     * @type {Array<EquipmentType>}
     * @memberof EquipmentType
     */
    'destinationEquipments'?: Array<EquipmentType> | null;
    /**
     * 
     * @type {Array<EquipmentComposition>}
     * @memberof EquipmentType
     */
    'destinationCompositions'?: Array<EquipmentComposition> | null;
    /**
     * 
     * @type {string}
     * @memberof EquipmentType
     */
    'name'?: string | null;
    /**
     * 
     * @type {I18nField}
     * @memberof EquipmentType
     */
    'displayNameI18n'?: I18nField;
    /**
     * 
     * @type {string}
     * @memberof EquipmentType
     */
    'created'?: string;
    /**
     * 
     * @type {string}
     * @memberof EquipmentType
     */
    'lastUpdated'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EquipmentType
     */
    'deleted'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof EquipmentType
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof EquipmentType
     */
    'idAsString'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EquipmentType
     */
    'stateFlag'?: EquipmentTypeStateFlagEnum;
}

export const EquipmentTypeStateFlagEnum = {
    Detached: 'detached',
    Unchanged: 'unchanged',
    Deleted: 'deleted',
    Modified: 'modified',
    Added: 'added'
} as const;

export type EquipmentTypeStateFlagEnum = typeof EquipmentTypeStateFlagEnum[keyof typeof EquipmentTypeStateFlagEnum];

/**
 * 
 * @export
 * @interface EquipmentTypeFormDTO
 */
export interface EquipmentTypeFormDTO {
    /**
     * 
     * @type {boolean}
     * @memberof EquipmentTypeFormDTO
     */
    'published'?: boolean;
    /**
     * 
     * @type {MediaAttachmentDTO}
     * @memberof EquipmentTypeFormDTO
     */
    'image'?: MediaAttachmentDTO;
    /**
     * 
     * @type {Array<FieldFormDTO>}
     * @memberof EquipmentTypeFormDTO
     */
    'fields'?: Array<FieldFormDTO> | null;
    /**
     * 
     * @type {Array<ChildDTOOfInt32>}
     * @memberof EquipmentTypeFormDTO
     */
    'areas'?: Array<ChildDTOOfInt32> | null;
    /**
     * 
     * @type {Array<ChildDTOOfInt32>}
     * @memberof EquipmentTypeFormDTO
     */
    'technicalBranches'?: Array<ChildDTOOfInt32> | null;
    /**
     * 
     * @type {number}
     * @memberof EquipmentTypeFormDTO
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof EquipmentTypeFormDTO
     */
    'name'?: string | null;
    /**
     * 
     * @type {any}
     * @memberof EquipmentTypeFormDTO
     */
    'displayNameI18n'?: any | null;
    /**
     * 
     * @type {number}
     * @memberof EquipmentTypeFormDTO
     */
    'stateFlag'?: number;
}
/**
 * 
 * @export
 * @interface EquipmentTypeListDTO
 */
export interface EquipmentTypeListDTO {
    /**
     * 
     * @type {string}
     * @memberof EquipmentTypeListDTO
     */
    'name'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof EquipmentTypeListDTO
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof EquipmentTypeListDTO
     */
    'stateFlag'?: number;
}
/**
 * 
 * @export
 * @interface EquipmentTypeSearchDTO
 */
export interface EquipmentTypeSearchDTO {
    /**
     * 
     * @type {number}
     * @memberof EquipmentTypeSearchDTO
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof EquipmentTypeSearchDTO
     */
    'displayNameI18n'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof EquipmentTypeSearchDTO
     */
    'equipmentCount'?: number;
    /**
     * 
     * @type {MediaUiDTO}
     * @memberof EquipmentTypeSearchDTO
     */
    'image'?: MediaUiDTO;
}
/**
 * 
 * @export
 * @interface EquipmentTypeUiDTO
 */
export interface EquipmentTypeUiDTO {
    /**
     * 
     * @type {number}
     * @memberof EquipmentTypeUiDTO
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof EquipmentTypeUiDTO
     */
    'displayNameI18n'?: string | null;
    /**
     * 
     * @type {MediaAttachmentDTO}
     * @memberof EquipmentTypeUiDTO
     */
    'image'?: MediaAttachmentDTO;
}
/**
 * 
 * @export
 * @interface EquipmentTypesSearchEquipmentTypesWithSQLQuery
 */
export interface EquipmentTypesSearchEquipmentTypesWithSQLQuery {
    /**
     * 
     * @type {Array<number>}
     * @memberof EquipmentTypesSearchEquipmentTypesWithSQLQuery
     */
    'brands'?: Array<number> | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof EquipmentTypesSearchEquipmentTypesWithSQLQuery
     */
    'areas'?: Array<number> | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof EquipmentTypesSearchEquipmentTypesWithSQLQuery
     */
    'branches'?: Array<number> | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof EquipmentTypesSearchEquipmentTypesWithSQLQuery
     */
    'countries'?: Array<number> | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof EquipmentTypesSearchEquipmentTypesWithSQLQuery
     */
    'regions'?: Array<number> | null;
    /**
     * 
     * @type {string}
     * @memberof EquipmentTypesSearchEquipmentTypesWithSQLQuery
     */
    'text'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof EquipmentTypesSearchEquipmentTypesWithSQLQuery
     */
    'includeEmpty'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof EquipmentTypesSearchEquipmentTypesWithSQLQuery
     */
    'page'?: number;
    /**
     * 
     * @type {number}
     * @memberof EquipmentTypesSearchEquipmentTypesWithSQLQuery
     */
    'pageSize'?: number;
}
/**
 * 
 * @export
 * @interface EquipmentUiDTO
 */
export interface EquipmentUiDTO {
    /**
     * 
     * @type {number}
     * @memberof EquipmentUiDTO
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof EquipmentUiDTO
     */
    'name'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof EquipmentUiDTO
     */
    'published'?: boolean;
    /**
     * 
     * @type {CountryUiDTO}
     * @memberof EquipmentUiDTO
     */
    'madeInCountry'?: CountryUiDTO;
    /**
     * 
     * @type {BrandUiDTO}
     * @memberof EquipmentUiDTO
     */
    'brand'?: BrandUiDTO;
    /**
     * 
     * @type {EquipmentTypeUiDTO}
     * @memberof EquipmentUiDTO
     */
    'equipmentType'?: EquipmentTypeUiDTO;
    /**
     * 
     * @type {any}
     * @memberof EquipmentUiDTO
     */
    'jsonData'?: any | null;
}
/**
 * 
 * @export
 * @interface EquipmentsGeneratePdfTestCommand
 */
export interface EquipmentsGeneratePdfTestCommand {
    /**
     * 
     * @type {string}
     * @memberof EquipmentsGeneratePdfTestCommand
     */
    'url'?: string | null;
}
/**
 * 
 * @export
 * @interface EquipmentsGetJsonSchemaResponse
 */
export interface EquipmentsGetJsonSchemaResponse {
    /**
     * 
     * @type {any}
     * @memberof EquipmentsGetJsonSchemaResponse
     */
    'jsonSchema'?: any | null;
    /**
     * 
     * @type {any}
     * @memberof EquipmentsGetJsonSchemaResponse
     */
    'uiSchema'?: any | null;
}
/**
 * 
 * @export
 * @interface EquipmentsMainSearchResponse
 */
export interface EquipmentsMainSearchResponse {
    /**
     * 
     * @type {Array<EquipmentMainSearchDTO>}
     * @memberof EquipmentsMainSearchResponse
     */
    'equipments'?: Array<EquipmentMainSearchDTO> | null;
    /**
     * 
     * @type {Array<EquipmentTypeListDTO>}
     * @memberof EquipmentsMainSearchResponse
     */
    'equipmentTypes'?: Array<EquipmentTypeListDTO> | null;
    /**
     * 
     * @type {Array<BrandListDTO>}
     * @memberof EquipmentsMainSearchResponse
     */
    'brands'?: Array<BrandListDTO> | null;
    /**
     * 
     * @type {string}
     * @memberof EquipmentsMainSearchResponse
     */
    'searchType'?: EquipmentsMainSearchResponseSearchTypeEnum;
}

export const EquipmentsMainSearchResponseSearchTypeEnum = {
    All: 'all',
    Equipments: 'equipments',
    Brands: 'brands',
    EquipmentTypes: 'equipmentTypes'
} as const;

export type EquipmentsMainSearchResponseSearchTypeEnum = typeof EquipmentsMainSearchResponseSearchTypeEnum[keyof typeof EquipmentsMainSearchResponseSearchTypeEnum];

/**
 * 
 * @export
 * @interface EquipmentsRequestQuoteCommand
 */
export interface EquipmentsRequestQuoteCommand {
    /**
     * 
     * @type {number}
     * @memberof EquipmentsRequestQuoteCommand
     */
    'eqId'?: number;
    /**
     * 
     * @type {string}
     * @memberof EquipmentsRequestQuoteCommand
     */
    'message'?: string | null;
}
/**
 * 
 * @export
 * @interface EquipmentsSearchEquipmentsHeader
 */
export interface EquipmentsSearchEquipmentsHeader {
    /**
     * 
     * @type {string}
     * @memberof EquipmentsSearchEquipmentsHeader
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EquipmentsSearchEquipmentsHeader
     */
    'displayName'?: string | null;
}
/**
 * 
 * @export
 * @interface EquipmentsSearchEquipmentsQuery
 */
export interface EquipmentsSearchEquipmentsQuery {
    /**
     * 
     * @type {number}
     * @memberof EquipmentsSearchEquipmentsQuery
     */
    'equipmentTypeId'?: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof EquipmentsSearchEquipmentsQuery
     */
    'brands'?: Array<number> | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof EquipmentsSearchEquipmentsQuery
     */
    'countries'?: Array<number> | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof EquipmentsSearchEquipmentsQuery
     */
    'regions'?: Array<number> | null;
    /**
     * 
     * @type {string}
     * @memberof EquipmentsSearchEquipmentsQuery
     */
    'text'?: string | null;
    /**
     * 
     * @type {any}
     * @memberof EquipmentsSearchEquipmentsQuery
     */
    'formData'?: any | null;
    /**
     * 
     * @type {string}
     * @memberof EquipmentsSearchEquipmentsQuery
     */
    'sortBy'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EquipmentsSearchEquipmentsQuery
     */
    'sortDirection'?: EquipmentsSearchEquipmentsQuerySortDirectionEnum;
    /**
     * 
     * @type {number}
     * @memberof EquipmentsSearchEquipmentsQuery
     */
    'page'?: number;
    /**
     * 
     * @type {number}
     * @memberof EquipmentsSearchEquipmentsQuery
     */
    'pageSize'?: number;
}

export const EquipmentsSearchEquipmentsQuerySortDirectionEnum = {
    Ascending: 'ascending',
    Descending: 'descending'
} as const;

export type EquipmentsSearchEquipmentsQuerySortDirectionEnum = typeof EquipmentsSearchEquipmentsQuerySortDirectionEnum[keyof typeof EquipmentsSearchEquipmentsQuerySortDirectionEnum];

/**
 * 
 * @export
 * @interface EquipmentsSearchEquipmentsResponse
 */
export interface EquipmentsSearchEquipmentsResponse {
    /**
     * 
     * @type {number}
     * @memberof EquipmentsSearchEquipmentsResponse
     */
    'totalItems'?: number;
    /**
     * 
     * @type {Array<EquipmentSearchDTO>}
     * @memberof EquipmentsSearchEquipmentsResponse
     */
    'items'?: Array<EquipmentSearchDTO> | null;
    /**
     * 
     * @type {Array<EquipmentsSearchEquipmentsHeader>}
     * @memberof EquipmentsSearchEquipmentsResponse
     */
    'headers'?: Array<EquipmentsSearchEquipmentsHeader> | null;
}
/**
 * 
 * @export
 * @interface EquipmentsSpecSheetGeneratePdfCommand
 */
export interface EquipmentsSpecSheetGeneratePdfCommand {
    /**
     * 
     * @type {number}
     * @memberof EquipmentsSpecSheetGeneratePdfCommand
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof EquipmentsSpecSheetGeneratePdfCommand
     */
    'contract'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EquipmentsSpecSheetGeneratePdfCommand
     */
    'company'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EquipmentsSpecSheetGeneratePdfCommand
     */
    'companyLogo'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EquipmentsSpecSheetGeneratePdfCommand
     */
    'sponsorLogo'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EquipmentsSpecSheetGeneratePdfCommand
     */
    'units'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EquipmentsSpecSheetGeneratePdfCommand
     */
    'specNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EquipmentsSpecSheetGeneratePdfCommand
     */
    'baseRef'?: string | null;
}
/**
 * 
 * @export
 * @interface Field
 */
export interface Field {
    /**
     * 
     * @type {string}
     * @memberof Field
     */
    'fieldType'?: FieldFieldTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof Field
     */
    'textDisplay'?: FieldTextDisplayEnum;
    /**
     * 
     * @type {string}
     * @memberof Field
     */
    'optionDisplay'?: FieldOptionDisplayEnum;
    /**
     * 
     * @type {boolean}
     * @memberof Field
     */
    'required'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Field
     */
    'showInMainSearch'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Field
     */
    'showInList'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof Field
     */
    'position'?: number;
    /**
     * 
     * @type {string}
     * @memberof Field
     */
    'umbracoName'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof Field
     */
    'equipmentTypeId'?: number;
    /**
     * 
     * @type {EquipmentType}
     * @memberof Field
     */
    'equipmentType'?: EquipmentType;
    /**
     * 
     * @type {number}
     * @memberof Field
     */
    'optionSetId'?: number | null;
    /**
     * 
     * @type {OptionSet}
     * @memberof Field
     */
    'optionSet'?: OptionSet;
    /**
     * 
     * @type {number}
     * @memberof Field
     */
    'unitId'?: number | null;
    /**
     * 
     * @type {Unit}
     * @memberof Field
     */
    'unit'?: Unit;
    /**
     * 
     * @type {string}
     * @memberof Field
     */
    'name'?: string | null;
    /**
     * 
     * @type {I18nField}
     * @memberof Field
     */
    'displayNameI18n'?: I18nField;
    /**
     * 
     * @type {string}
     * @memberof Field
     */
    'created'?: string;
    /**
     * 
     * @type {string}
     * @memberof Field
     */
    'lastUpdated'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Field
     */
    'deleted'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof Field
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof Field
     */
    'idAsString'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Field
     */
    'stateFlag'?: FieldStateFlagEnum;
}

export const FieldFieldTypeEnum = {
    Separator: 'separator',
    Text: 'text',
    Numeric: 'numeric',
    Option: 'option',
    Attachment: 'attachment'
} as const;

export type FieldFieldTypeEnum = typeof FieldFieldTypeEnum[keyof typeof FieldFieldTypeEnum];
export const FieldTextDisplayEnum = {
    Simple: 'simple',
    Multilingual: 'multilingual'
} as const;

export type FieldTextDisplayEnum = typeof FieldTextDisplayEnum[keyof typeof FieldTextDisplayEnum];
export const FieldOptionDisplayEnum = {
    Dropdown: 'dropdown',
    Checkbox: 'checkbox'
} as const;

export type FieldOptionDisplayEnum = typeof FieldOptionDisplayEnum[keyof typeof FieldOptionDisplayEnum];
export const FieldStateFlagEnum = {
    Detached: 'detached',
    Unchanged: 'unchanged',
    Deleted: 'deleted',
    Modified: 'modified',
    Added: 'added'
} as const;

export type FieldStateFlagEnum = typeof FieldStateFlagEnum[keyof typeof FieldStateFlagEnum];

/**
 * 
 * @export
 * @interface FieldFormDTO
 */
export interface FieldFormDTO {
    /**
     * 
     * @type {number}
     * @memberof FieldFormDTO
     */
    'equipmentTypeId'?: number;
    /**
     * 
     * @type {string}
     * @memberof FieldFormDTO
     */
    'fieldType'?: FieldFormDTOFieldTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof FieldFormDTO
     */
    'textDisplay'?: FieldFormDTOTextDisplayEnum;
    /**
     * 
     * @type {number}
     * @memberof FieldFormDTO
     */
    'unitId'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FieldFormDTO
     */
    'optionDisplay'?: FieldFormDTOOptionDisplayEnum;
    /**
     * 
     * @type {number}
     * @memberof FieldFormDTO
     */
    'optionSetId'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof FieldFormDTO
     */
    'required'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FieldFormDTO
     */
    'showInMainSearch'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FieldFormDTO
     */
    'showInList'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof FieldFormDTO
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof FieldFormDTO
     */
    'name'?: string | null;
    /**
     * 
     * @type {any}
     * @memberof FieldFormDTO
     */
    'displayNameI18n'?: any | null;
    /**
     * 
     * @type {number}
     * @memberof FieldFormDTO
     */
    'stateFlag'?: number;
}

export const FieldFormDTOFieldTypeEnum = {
    Separator: 'separator',
    Text: 'text',
    Numeric: 'numeric',
    Option: 'option',
    Attachment: 'attachment'
} as const;

export type FieldFormDTOFieldTypeEnum = typeof FieldFormDTOFieldTypeEnum[keyof typeof FieldFormDTOFieldTypeEnum];
export const FieldFormDTOTextDisplayEnum = {
    Simple: 'simple',
    Multilingual: 'multilingual'
} as const;

export type FieldFormDTOTextDisplayEnum = typeof FieldFormDTOTextDisplayEnum[keyof typeof FieldFormDTOTextDisplayEnum];
export const FieldFormDTOOptionDisplayEnum = {
    Dropdown: 'dropdown',
    Checkbox: 'checkbox'
} as const;

export type FieldFormDTOOptionDisplayEnum = typeof FieldFormDTOOptionDisplayEnum[keyof typeof FieldFormDTOOptionDisplayEnum];

/**
 * 
 * @export
 * @interface FieldListDTO
 */
export interface FieldListDTO {
    /**
     * 
     * @type {number}
     * @memberof FieldListDTO
     */
    'equipmentTypeId'?: number;
    /**
     * 
     * @type {string}
     * @memberof FieldListDTO
     */
    'fieldType'?: FieldListDTOFieldTypeEnum;
    /**
     * 
     * @type {boolean}
     * @memberof FieldListDTO
     */
    'required'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FieldListDTO
     */
    'showInMainSearch'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FieldListDTO
     */
    'showInList'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof FieldListDTO
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof FieldListDTO
     */
    'name'?: string | null;
    /**
     * 
     * @type {any}
     * @memberof FieldListDTO
     */
    'displayNameI18n'?: any | null;
    /**
     * 
     * @type {number}
     * @memberof FieldListDTO
     */
    'stateFlag'?: number;
}

export const FieldListDTOFieldTypeEnum = {
    Separator: 'separator',
    Text: 'text',
    Numeric: 'numeric',
    Option: 'option',
    Attachment: 'attachment'
} as const;

export type FieldListDTOFieldTypeEnum = typeof FieldListDTOFieldTypeEnum[keyof typeof FieldListDTOFieldTypeEnum];

/**
 * 
 * @export
 * @interface ForgotPasswordForgotPasswordSendMailCommand
 */
export interface ForgotPasswordForgotPasswordSendMailCommand {
    /**
     * 
     * @type {string}
     * @memberof ForgotPasswordForgotPasswordSendMailCommand
     */
    'email'?: string | null;
}
/**
 * 
 * @export
 * @interface I18nField
 */
export interface I18nField {
    /**
     * 
     * @type {string}
     * @memberof I18nField
     */
    'type'?: I18nFieldTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof I18nField
     */
    'key'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof I18nField
     */
    'contentType'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof I18nField
     */
    'contentId'?: string | null;
    /**
     * 
     * @type {Array<I18nTranslation>}
     * @memberof I18nField
     */
    'i18nTranslations'?: Array<I18nTranslation> | null;
    /**
     * 
     * @type {number}
     * @memberof I18nField
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof I18nField
     */
    'idAsString'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof I18nField
     */
    'stateFlag'?: I18nFieldStateFlagEnum;
}

export const I18nFieldTypeEnum = {
    Content: 'content',
    Error: 'error',
    Dictionary: 'dictionary'
} as const;

export type I18nFieldTypeEnum = typeof I18nFieldTypeEnum[keyof typeof I18nFieldTypeEnum];
export const I18nFieldStateFlagEnum = {
    Detached: 'detached',
    Unchanged: 'unchanged',
    Deleted: 'deleted',
    Modified: 'modified',
    Added: 'added'
} as const;

export type I18nFieldStateFlagEnum = typeof I18nFieldStateFlagEnum[keyof typeof I18nFieldStateFlagEnum];

/**
 * 
 * @export
 * @interface I18nLanguage
 */
export interface I18nLanguage {
    /**
     * 
     * @type {string}
     * @memberof I18nLanguage
     */
    'id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof I18nLanguage
     */
    'created'?: string;
    /**
     * 
     * @type {string}
     * @memberof I18nLanguage
     */
    'lastUpdated'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof I18nLanguage
     */
    'deleted'?: string | null;
    /**
     * 
     * @type {Array<I18nTranslation>}
     * @memberof I18nLanguage
     */
    'i18nTranslations'?: Array<I18nTranslation> | null;
}
/**
 * 
 * @export
 * @interface I18nTranslation
 */
export interface I18nTranslation {
    /**
     * 
     * @type {string}
     * @memberof I18nTranslation
     */
    'i18nLanguageId'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof I18nTranslation
     */
    'i18nFieldId'?: number;
    /**
     * 
     * @type {string}
     * @memberof I18nTranslation
     */
    'translation'?: string | null;
    /**
     * 
     * @type {I18nField}
     * @memberof I18nTranslation
     */
    'i18nField'?: I18nField;
    /**
     * 
     * @type {I18nLanguage}
     * @memberof I18nTranslation
     */
    'i18nLanguage'?: I18nLanguage;
    /**
     * 
     * @type {string}
     * @memberof I18nTranslation
     */
    'idAsString'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof I18nTranslation
     */
    'stateFlag'?: I18nTranslationStateFlagEnum;
}

export const I18nTranslationStateFlagEnum = {
    Detached: 'detached',
    Unchanged: 'unchanged',
    Deleted: 'deleted',
    Modified: 'modified',
    Added: 'added'
} as const;

export type I18nTranslationStateFlagEnum = typeof I18nTranslationStateFlagEnum[keyof typeof I18nTranslationStateFlagEnum];

/**
 * 
 * @export
 * @interface KeyValuePairOfStringString
 */
export interface KeyValuePairOfStringString {
    /**
     * 
     * @type {string}
     * @memberof KeyValuePairOfStringString
     */
    'key'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KeyValuePairOfStringString
     */
    'value'?: string | null;
}
/**
 * 
 * @export
 * @interface Media
 */
export interface Media {
    /**
     * 
     * @type {string}
     * @memberof Media
     */
    'originalName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Media
     */
    'uploadedName'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof Media
     */
    'containerId'?: number;
    /**
     * 
     * @type {Array<MediaAttachment>}
     * @memberof Media
     */
    'attachments'?: Array<MediaAttachment> | null;
    /**
     * 
     * @type {string}
     * @memberof Media
     */
    'created'?: string;
    /**
     * 
     * @type {string}
     * @memberof Media
     */
    'lastUpdated'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Media
     */
    'deleted'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof Media
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof Media
     */
    'idAsString'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Media
     */
    'stateFlag'?: MediaStateFlagEnum;
}

export const MediaStateFlagEnum = {
    Detached: 'detached',
    Unchanged: 'unchanged',
    Deleted: 'deleted',
    Modified: 'modified',
    Added: 'added'
} as const;

export type MediaStateFlagEnum = typeof MediaStateFlagEnum[keyof typeof MediaStateFlagEnum];

/**
 * 
 * @export
 * @interface MediaAttachment
 */
export interface MediaAttachment {
    /**
     * 
     * @type {number}
     * @memberof MediaAttachment
     */
    'order'?: number;
    /**
     * 
     * @type {string}
     * @memberof MediaAttachment
     */
    'key'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MediaAttachment
     */
    'entityType'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MediaAttachment
     */
    'entityId'?: string | null;
    /**
     * 
     * @type {Media}
     * @memberof MediaAttachment
     */
    'media'?: Media;
    /**
     * 
     * @type {number}
     * @memberof MediaAttachment
     */
    'mediaId'?: number;
    /**
     * 
     * @type {string}
     * @memberof MediaAttachment
     */
    'created'?: string;
    /**
     * 
     * @type {string}
     * @memberof MediaAttachment
     */
    'lastUpdated'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MediaAttachment
     */
    'deleted'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof MediaAttachment
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof MediaAttachment
     */
    'idAsString'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MediaAttachment
     */
    'stateFlag'?: MediaAttachmentStateFlagEnum;
}

export const MediaAttachmentStateFlagEnum = {
    Detached: 'detached',
    Unchanged: 'unchanged',
    Deleted: 'deleted',
    Modified: 'modified',
    Added: 'added'
} as const;

export type MediaAttachmentStateFlagEnum = typeof MediaAttachmentStateFlagEnum[keyof typeof MediaAttachmentStateFlagEnum];

/**
 * 
 * @export
 * @interface MediaAttachmentDTO
 */
export interface MediaAttachmentDTO {
    /**
     * 
     * @type {number}
     * @memberof MediaAttachmentDTO
     */
    'id'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MediaAttachmentDTO
     */
    'stateFlag'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MediaAttachmentDTO
     */
    'mediaId'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof MediaAttachmentDTO
     */
    'created'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof MediaAttachmentDTO
     */
    'order'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof MediaAttachmentDTO
     */
    'key'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MediaAttachmentDTO
     */
    'entityType'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MediaAttachmentDTO
     */
    'entityId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MediaAttachmentDTO
     */
    'title'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MediaAttachmentDTO
     */
    'src'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MediaAttachmentDTO
     */
    'base64'?: string | null;
}
/**
 * 
 * @export
 * @interface MediaUiDTO
 */
export interface MediaUiDTO {
    /**
     * 
     * @type {string}
     * @memberof MediaUiDTO
     */
    'url'?: string | null;
}
/**
 * 
 * @export
 * @interface MoveNodeRequestOfAreaFormDTO
 */
export interface MoveNodeRequestOfAreaFormDTO {
    /**
     * 
     * @type {AreaFormDTO}
     * @memberof MoveNodeRequestOfAreaFormDTO
     */
    'source'?: AreaFormDTO;
    /**
     * 
     * @type {AreaFormDTO}
     * @memberof MoveNodeRequestOfAreaFormDTO
     */
    'destination'?: AreaFormDTO;
    /**
     * 
     * @type {number}
     * @memberof MoveNodeRequestOfAreaFormDTO
     */
    'position'?: number;
}
/**
 * 
 * @export
 * @interface MoveNodeRequestOfContentFormDTO
 */
export interface MoveNodeRequestOfContentFormDTO {
    /**
     * 
     * @type {ContentFormDTO}
     * @memberof MoveNodeRequestOfContentFormDTO
     */
    'source'?: ContentFormDTO;
    /**
     * 
     * @type {ContentFormDTO}
     * @memberof MoveNodeRequestOfContentFormDTO
     */
    'destination'?: ContentFormDTO;
    /**
     * 
     * @type {number}
     * @memberof MoveNodeRequestOfContentFormDTO
     */
    'position'?: number;
}
/**
 * 
 * @export
 * @interface Office
 */
export interface Office {
    /**
     * 
     * @type {string}
     * @memberof Office
     */
    'phoneNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Office
     */
    'email'?: string | null;
    /**
     * 
     * @type {Country}
     * @memberof Office
     */
    'country'?: Country;
    /**
     * 
     * @type {string}
     * @memberof Office
     */
    'streetAddress'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Office
     */
    'city'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Office
     */
    'postalCode'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof Office
     */
    'countryId'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof Office
     */
    'created'?: string;
    /**
     * 
     * @type {string}
     * @memberof Office
     */
    'lastUpdated'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Office
     */
    'deleted'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof Office
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof Office
     */
    'idAsString'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Office
     */
    'stateFlag'?: OfficeStateFlagEnum;
}

export const OfficeStateFlagEnum = {
    Detached: 'detached',
    Unchanged: 'unchanged',
    Deleted: 'deleted',
    Modified: 'modified',
    Added: 'added'
} as const;

export type OfficeStateFlagEnum = typeof OfficeStateFlagEnum[keyof typeof OfficeStateFlagEnum];

/**
 * 
 * @export
 * @interface OfficeFormDTO
 */
export interface OfficeFormDTO {
    /**
     * 
     * @type {string}
     * @memberof OfficeFormDTO
     */
    'streetAddress'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OfficeFormDTO
     */
    'city'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OfficeFormDTO
     */
    'postalCode'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof OfficeFormDTO
     */
    'countryId'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof OfficeFormDTO
     */
    'phoneNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OfficeFormDTO
     */
    'email'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof OfficeFormDTO
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof OfficeFormDTO
     */
    'stateFlag'?: number;
}
/**
 * 
 * @export
 * @interface OfficeUiDTO
 */
export interface OfficeUiDTO {
    /**
     * 
     * @type {string}
     * @memberof OfficeUiDTO
     */
    'streetAddress'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OfficeUiDTO
     */
    'city'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OfficeUiDTO
     */
    'postalCode'?: string | null;
    /**
     * 
     * @type {CountryUiDTO}
     * @memberof OfficeUiDTO
     */
    'country'?: CountryUiDTO;
    /**
     * 
     * @type {string}
     * @memberof OfficeUiDTO
     */
    'phoneNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OfficeUiDTO
     */
    'email'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof OfficeUiDTO
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof OfficeUiDTO
     */
    'stateFlag'?: number;
}
/**
 * 
 * @export
 * @interface Operation
 */
export interface Operation {
    /**
     * 
     * @type {any}
     * @memberof Operation
     */
    'value'?: any | null;
    /**
     * 
     * @type {string}
     * @memberof Operation
     */
    'path'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Operation
     */
    'op'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Operation
     */
    'from'?: string | null;
}
/**
 * 
 * @export
 * @interface Option
 */
export interface Option {
    /**
     * 
     * @type {number}
     * @memberof Option
     */
    'umbracoId'?: number;
    /**
     * 
     * @type {string}
     * @memberof Option
     */
    'uniqueId'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof Option
     */
    'optionSetId'?: number | null;
    /**
     * 
     * @type {OptionSet}
     * @memberof Option
     */
    'optionSet'?: OptionSet;
    /**
     * 
     * @type {string}
     * @memberof Option
     */
    'name'?: string | null;
    /**
     * 
     * @type {I18nField}
     * @memberof Option
     */
    'displayNameI18n'?: I18nField;
    /**
     * 
     * @type {string}
     * @memberof Option
     */
    'created'?: string;
    /**
     * 
     * @type {string}
     * @memberof Option
     */
    'lastUpdated'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Option
     */
    'deleted'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof Option
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof Option
     */
    'idAsString'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Option
     */
    'stateFlag'?: OptionStateFlagEnum;
}

export const OptionStateFlagEnum = {
    Detached: 'detached',
    Unchanged: 'unchanged',
    Deleted: 'deleted',
    Modified: 'modified',
    Added: 'added'
} as const;

export type OptionStateFlagEnum = typeof OptionStateFlagEnum[keyof typeof OptionStateFlagEnum];

/**
 * 
 * @export
 * @interface OptionFormDTO
 */
export interface OptionFormDTO {
    /**
     * 
     * @type {number}
     * @memberof OptionFormDTO
     */
    'optionSetId'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof OptionFormDTO
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof OptionFormDTO
     */
    'name'?: string | null;
    /**
     * 
     * @type {any}
     * @memberof OptionFormDTO
     */
    'displayNameI18n'?: any | null;
    /**
     * 
     * @type {number}
     * @memberof OptionFormDTO
     */
    'stateFlag'?: number;
}
/**
 * 
 * @export
 * @interface OptionListDTO
 */
export interface OptionListDTO {
    /**
     * 
     * @type {number}
     * @memberof OptionListDTO
     */
    'optionSetId'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof OptionListDTO
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof OptionListDTO
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OptionListDTO
     */
    'displayNameI18n'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof OptionListDTO
     */
    'stateFlag'?: number;
}
/**
 * 
 * @export
 * @interface OptionSet
 */
export interface OptionSet {
    /**
     * 
     * @type {string}
     * @memberof OptionSet
     */
    'umbId'?: string | null;
    /**
     * 
     * @type {Array<Option>}
     * @memberof OptionSet
     */
    'options'?: Array<Option> | null;
    /**
     * 
     * @type {string}
     * @memberof OptionSet
     */
    'name'?: string | null;
    /**
     * 
     * @type {I18nField}
     * @memberof OptionSet
     */
    'displayNameI18n'?: I18nField;
    /**
     * 
     * @type {string}
     * @memberof OptionSet
     */
    'created'?: string;
    /**
     * 
     * @type {string}
     * @memberof OptionSet
     */
    'lastUpdated'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OptionSet
     */
    'deleted'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof OptionSet
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof OptionSet
     */
    'idAsString'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OptionSet
     */
    'stateFlag'?: OptionSetStateFlagEnum;
}

export const OptionSetStateFlagEnum = {
    Detached: 'detached',
    Unchanged: 'unchanged',
    Deleted: 'deleted',
    Modified: 'modified',
    Added: 'added'
} as const;

export type OptionSetStateFlagEnum = typeof OptionSetStateFlagEnum[keyof typeof OptionSetStateFlagEnum];

/**
 * 
 * @export
 * @interface OptionSetFormDTO
 */
export interface OptionSetFormDTO {
    /**
     * 
     * @type {string}
     * @memberof OptionSetFormDTO
     */
    'name'?: string | null;
    /**
     * 
     * @type {Array<OptionFormDTO>}
     * @memberof OptionSetFormDTO
     */
    'options'?: Array<OptionFormDTO> | null;
    /**
     * 
     * @type {number}
     * @memberof OptionSetFormDTO
     */
    'id'?: number;
    /**
     * 
     * @type {any}
     * @memberof OptionSetFormDTO
     */
    'displayNameI18n'?: any | null;
    /**
     * 
     * @type {number}
     * @memberof OptionSetFormDTO
     */
    'stateFlag'?: number;
}
/**
 * 
 * @export
 * @interface OptionSetListDTO
 */
export interface OptionSetListDTO {
    /**
     * 
     * @type {string}
     * @memberof OptionSetListDTO
     */
    'name'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof OptionSetListDTO
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof OptionSetListDTO
     */
    'stateFlag'?: number;
}
/**
 * 
 * @export
 * @interface PagedResponseDtoOfAreaListDTO
 */
export interface PagedResponseDtoOfAreaListDTO {
    /**
     * 
     * @type {number}
     * @memberof PagedResponseDtoOfAreaListDTO
     */
    'totalItems'?: number;
    /**
     * 
     * @type {Array<AreaListDTO>}
     * @memberof PagedResponseDtoOfAreaListDTO
     */
    'items'?: Array<AreaListDTO> | null;
}
/**
 * 
 * @export
 * @interface PagedResponseDtoOfBrandListDTO
 */
export interface PagedResponseDtoOfBrandListDTO {
    /**
     * 
     * @type {number}
     * @memberof PagedResponseDtoOfBrandListDTO
     */
    'totalItems'?: number;
    /**
     * 
     * @type {Array<BrandListDTO>}
     * @memberof PagedResponseDtoOfBrandListDTO
     */
    'items'?: Array<BrandListDTO> | null;
}
/**
 * 
 * @export
 * @interface PagedResponseDtoOfBrandSearchDTO
 */
export interface PagedResponseDtoOfBrandSearchDTO {
    /**
     * 
     * @type {number}
     * @memberof PagedResponseDtoOfBrandSearchDTO
     */
    'totalItems'?: number;
    /**
     * 
     * @type {Array<BrandSearchDTO>}
     * @memberof PagedResponseDtoOfBrandSearchDTO
     */
    'items'?: Array<BrandSearchDTO> | null;
}
/**
 * 
 * @export
 * @interface PagedResponseDtoOfContentListDTO
 */
export interface PagedResponseDtoOfContentListDTO {
    /**
     * 
     * @type {number}
     * @memberof PagedResponseDtoOfContentListDTO
     */
    'totalItems'?: number;
    /**
     * 
     * @type {Array<ContentListDTO>}
     * @memberof PagedResponseDtoOfContentListDTO
     */
    'items'?: Array<ContentListDTO> | null;
}
/**
 * 
 * @export
 * @interface PagedResponseDtoOfCountryListDTO
 */
export interface PagedResponseDtoOfCountryListDTO {
    /**
     * 
     * @type {number}
     * @memberof PagedResponseDtoOfCountryListDTO
     */
    'totalItems'?: number;
    /**
     * 
     * @type {Array<CountryListDTO>}
     * @memberof PagedResponseDtoOfCountryListDTO
     */
    'items'?: Array<CountryListDTO> | null;
}
/**
 * 
 * @export
 * @interface PagedResponseDtoOfCountryUiDTO
 */
export interface PagedResponseDtoOfCountryUiDTO {
    /**
     * 
     * @type {number}
     * @memberof PagedResponseDtoOfCountryUiDTO
     */
    'totalItems'?: number;
    /**
     * 
     * @type {Array<CountryUiDTO>}
     * @memberof PagedResponseDtoOfCountryUiDTO
     */
    'items'?: Array<CountryUiDTO> | null;
}
/**
 * 
 * @export
 * @interface PagedResponseDtoOfEquipmentListDTO
 */
export interface PagedResponseDtoOfEquipmentListDTO {
    /**
     * 
     * @type {number}
     * @memberof PagedResponseDtoOfEquipmentListDTO
     */
    'totalItems'?: number;
    /**
     * 
     * @type {Array<EquipmentListDTO>}
     * @memberof PagedResponseDtoOfEquipmentListDTO
     */
    'items'?: Array<EquipmentListDTO> | null;
}
/**
 * 
 * @export
 * @interface PagedResponseDtoOfEquipmentTypeListDTO
 */
export interface PagedResponseDtoOfEquipmentTypeListDTO {
    /**
     * 
     * @type {number}
     * @memberof PagedResponseDtoOfEquipmentTypeListDTO
     */
    'totalItems'?: number;
    /**
     * 
     * @type {Array<EquipmentTypeListDTO>}
     * @memberof PagedResponseDtoOfEquipmentTypeListDTO
     */
    'items'?: Array<EquipmentTypeListDTO> | null;
}
/**
 * 
 * @export
 * @interface PagedResponseDtoOfEquipmentTypeSearchDTO
 */
export interface PagedResponseDtoOfEquipmentTypeSearchDTO {
    /**
     * 
     * @type {number}
     * @memberof PagedResponseDtoOfEquipmentTypeSearchDTO
     */
    'totalItems'?: number;
    /**
     * 
     * @type {Array<EquipmentTypeSearchDTO>}
     * @memberof PagedResponseDtoOfEquipmentTypeSearchDTO
     */
    'items'?: Array<EquipmentTypeSearchDTO> | null;
}
/**
 * 
 * @export
 * @interface PagedResponseDtoOfFieldListDTO
 */
export interface PagedResponseDtoOfFieldListDTO {
    /**
     * 
     * @type {number}
     * @memberof PagedResponseDtoOfFieldListDTO
     */
    'totalItems'?: number;
    /**
     * 
     * @type {Array<FieldListDTO>}
     * @memberof PagedResponseDtoOfFieldListDTO
     */
    'items'?: Array<FieldListDTO> | null;
}
/**
 * 
 * @export
 * @interface PagedResponseDtoOfJObject
 */
export interface PagedResponseDtoOfJObject {
    /**
     * 
     * @type {number}
     * @memberof PagedResponseDtoOfJObject
     */
    'totalItems'?: number;
    /**
     * 
     * @type {Array<any>}
     * @memberof PagedResponseDtoOfJObject
     */
    'items'?: Array<any> | null;
}
/**
 * 
 * @export
 * @interface PagedResponseDtoOfKeyValuePairOfStringString
 */
export interface PagedResponseDtoOfKeyValuePairOfStringString {
    /**
     * 
     * @type {number}
     * @memberof PagedResponseDtoOfKeyValuePairOfStringString
     */
    'totalItems'?: number;
    /**
     * 
     * @type {Array<KeyValuePairOfStringString>}
     * @memberof PagedResponseDtoOfKeyValuePairOfStringString
     */
    'items'?: Array<KeyValuePairOfStringString> | null;
}
/**
 * 
 * @export
 * @interface PagedResponseDtoOfOptionListDTO
 */
export interface PagedResponseDtoOfOptionListDTO {
    /**
     * 
     * @type {number}
     * @memberof PagedResponseDtoOfOptionListDTO
     */
    'totalItems'?: number;
    /**
     * 
     * @type {Array<OptionListDTO>}
     * @memberof PagedResponseDtoOfOptionListDTO
     */
    'items'?: Array<OptionListDTO> | null;
}
/**
 * 
 * @export
 * @interface PagedResponseDtoOfOptionSetListDTO
 */
export interface PagedResponseDtoOfOptionSetListDTO {
    /**
     * 
     * @type {number}
     * @memberof PagedResponseDtoOfOptionSetListDTO
     */
    'totalItems'?: number;
    /**
     * 
     * @type {Array<OptionSetListDTO>}
     * @memberof PagedResponseDtoOfOptionSetListDTO
     */
    'items'?: Array<OptionSetListDTO> | null;
}
/**
 * 
 * @export
 * @interface PagedResponseDtoOfPolicyListDTO
 */
export interface PagedResponseDtoOfPolicyListDTO {
    /**
     * 
     * @type {number}
     * @memberof PagedResponseDtoOfPolicyListDTO
     */
    'totalItems'?: number;
    /**
     * 
     * @type {Array<PolicyListDTO>}
     * @memberof PagedResponseDtoOfPolicyListDTO
     */
    'items'?: Array<PolicyListDTO> | null;
}
/**
 * 
 * @export
 * @interface PagedResponseDtoOfPolicyUiDTO
 */
export interface PagedResponseDtoOfPolicyUiDTO {
    /**
     * 
     * @type {number}
     * @memberof PagedResponseDtoOfPolicyUiDTO
     */
    'totalItems'?: number;
    /**
     * 
     * @type {Array<PolicyUiDTO>}
     * @memberof PagedResponseDtoOfPolicyUiDTO
     */
    'items'?: Array<PolicyUiDTO> | null;
}
/**
 * 
 * @export
 * @interface PagedResponseDtoOfRoleListDTO
 */
export interface PagedResponseDtoOfRoleListDTO {
    /**
     * 
     * @type {number}
     * @memberof PagedResponseDtoOfRoleListDTO
     */
    'totalItems'?: number;
    /**
     * 
     * @type {Array<RoleListDTO>}
     * @memberof PagedResponseDtoOfRoleListDTO
     */
    'items'?: Array<RoleListDTO> | null;
}
/**
 * 
 * @export
 * @interface PagedResponseDtoOfSettingListDTO
 */
export interface PagedResponseDtoOfSettingListDTO {
    /**
     * 
     * @type {number}
     * @memberof PagedResponseDtoOfSettingListDTO
     */
    'totalItems'?: number;
    /**
     * 
     * @type {Array<SettingListDTO>}
     * @memberof PagedResponseDtoOfSettingListDTO
     */
    'items'?: Array<SettingListDTO> | null;
}
/**
 * 
 * @export
 * @interface PagedResponseDtoOfTechnicalBranchListDTO
 */
export interface PagedResponseDtoOfTechnicalBranchListDTO {
    /**
     * 
     * @type {number}
     * @memberof PagedResponseDtoOfTechnicalBranchListDTO
     */
    'totalItems'?: number;
    /**
     * 
     * @type {Array<TechnicalBranchListDTO>}
     * @memberof PagedResponseDtoOfTechnicalBranchListDTO
     */
    'items'?: Array<TechnicalBranchListDTO> | null;
}
/**
 * 
 * @export
 * @interface PagedResponseDtoOfTechnicalBranchUiDTO
 */
export interface PagedResponseDtoOfTechnicalBranchUiDTO {
    /**
     * 
     * @type {number}
     * @memberof PagedResponseDtoOfTechnicalBranchUiDTO
     */
    'totalItems'?: number;
    /**
     * 
     * @type {Array<TechnicalBranchUiDTO>}
     * @memberof PagedResponseDtoOfTechnicalBranchUiDTO
     */
    'items'?: Array<TechnicalBranchUiDTO> | null;
}
/**
 * 
 * @export
 * @interface PagedResponseDtoOfUnitListDTO
 */
export interface PagedResponseDtoOfUnitListDTO {
    /**
     * 
     * @type {number}
     * @memberof PagedResponseDtoOfUnitListDTO
     */
    'totalItems'?: number;
    /**
     * 
     * @type {Array<UnitListDTO>}
     * @memberof PagedResponseDtoOfUnitListDTO
     */
    'items'?: Array<UnitListDTO> | null;
}
/**
 * 
 * @export
 * @interface PagedResponseDtoOfUnitTypeListDTO
 */
export interface PagedResponseDtoOfUnitTypeListDTO {
    /**
     * 
     * @type {number}
     * @memberof PagedResponseDtoOfUnitTypeListDTO
     */
    'totalItems'?: number;
    /**
     * 
     * @type {Array<UnitTypeListDTO>}
     * @memberof PagedResponseDtoOfUnitTypeListDTO
     */
    'items'?: Array<UnitTypeListDTO> | null;
}
/**
 * 
 * @export
 * @interface PagedResponseDtoOfUserListDTO
 */
export interface PagedResponseDtoOfUserListDTO {
    /**
     * 
     * @type {number}
     * @memberof PagedResponseDtoOfUserListDTO
     */
    'totalItems'?: number;
    /**
     * 
     * @type {Array<UserListDTO>}
     * @memberof PagedResponseDtoOfUserListDTO
     */
    'items'?: Array<UserListDTO> | null;
}
/**
 * 
 * @export
 * @interface PolicyFormDTO
 */
export interface PolicyFormDTO {
    /**
     * 
     * @type {number}
     * @memberof PolicyFormDTO
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof PolicyFormDTO
     */
    'type'?: PolicyFormDTOTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof PolicyFormDTO
     */
    'version'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof PolicyFormDTO
     */
    'isActive'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PolicyFormDTO
     */
    'isRequired'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PolicyFormDTO
     */
    'activationDate'?: string | null;
    /**
     * 
     * @type {any}
     * @memberof PolicyFormDTO
     */
    'titleI18n'?: any | null;
    /**
     * 
     * @type {any}
     * @memberof PolicyFormDTO
     */
    'textI18n'?: any | null;
}

export const PolicyFormDTOTypeEnum = {
    TermsAndConditions: 'termsAndConditions',
    Marketing: 'marketing',
    Privacy: 'privacy'
} as const;

export type PolicyFormDTOTypeEnum = typeof PolicyFormDTOTypeEnum[keyof typeof PolicyFormDTOTypeEnum];

/**
 * 
 * @export
 * @interface PolicyListDTO
 */
export interface PolicyListDTO {
    /**
     * 
     * @type {number}
     * @memberof PolicyListDTO
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof PolicyListDTO
     */
    'type'?: PolicyListDTOTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof PolicyListDTO
     */
    'version'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof PolicyListDTO
     */
    'isActive'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PolicyListDTO
     */
    'isRequired'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PolicyListDTO
     */
    'activationDate'?: string | null;
}

export const PolicyListDTOTypeEnum = {
    TermsAndConditions: 'termsAndConditions',
    Marketing: 'marketing',
    Privacy: 'privacy'
} as const;

export type PolicyListDTOTypeEnum = typeof PolicyListDTOTypeEnum[keyof typeof PolicyListDTOTypeEnum];

/**
 * 
 * @export
 * @interface PolicyUiDTO
 */
export interface PolicyUiDTO {
    /**
     * 
     * @type {number}
     * @memberof PolicyUiDTO
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof PolicyUiDTO
     */
    'type'?: PolicyUiDTOTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof PolicyUiDTO
     */
    'version'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PolicyUiDTO
     */
    'titleI18n'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PolicyUiDTO
     */
    'textI18n'?: string | null;
}

export const PolicyUiDTOTypeEnum = {
    TermsAndConditions: 'termsAndConditions',
    Marketing: 'marketing',
    Privacy: 'privacy'
} as const;

export type PolicyUiDTOTypeEnum = typeof PolicyUiDTOTypeEnum[keyof typeof PolicyUiDTOTypeEnum];

/**
 * 
 * @export
 * @interface RecoverPasswordForgotPasswordSetNewCommand
 */
export interface RecoverPasswordForgotPasswordSetNewCommand {
    /**
     * 
     * @type {string}
     * @memberof RecoverPasswordForgotPasswordSetNewCommand
     */
    'email'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RecoverPasswordForgotPasswordSetNewCommand
     */
    'password'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RecoverPasswordForgotPasswordSetNewCommand
     */
    'changePasswordToken'?: string | null;
}
/**
 * 
 * @export
 * @interface Region
 */
export interface Region {
    /**
     * 
     * @type {string}
     * @memberof Region
     */
    'path'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof Region
     */
    'position'?: number;
    /**
     * 
     * @type {number}
     * @memberof Region
     */
    'parentId'?: number | null;
    /**
     * 
     * @type {Region}
     * @memberof Region
     */
    'parent'?: Region;
    /**
     * 
     * @type {Array<EquipmentType>}
     * @memberof Region
     */
    'equipmentTypes'?: Array<EquipmentType> | null;
    /**
     * 
     * @type {Array<Region>}
     * @memberof Region
     */
    'children'?: Array<Region> | null;
    /**
     * 
     * @type {Array<Country>}
     * @memberof Region
     */
    'countries'?: Array<Country> | null;
    /**
     * 
     * @type {string}
     * @memberof Region
     */
    'name'?: string | null;
    /**
     * 
     * @type {I18nField}
     * @memberof Region
     */
    'displayNameI18n'?: I18nField;
    /**
     * 
     * @type {string}
     * @memberof Region
     */
    'created'?: string;
    /**
     * 
     * @type {string}
     * @memberof Region
     */
    'lastUpdated'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Region
     */
    'deleted'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof Region
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof Region
     */
    'idAsString'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Region
     */
    'stateFlag'?: RegionStateFlagEnum;
}

export const RegionStateFlagEnum = {
    Detached: 'detached',
    Unchanged: 'unchanged',
    Deleted: 'deleted',
    Modified: 'modified',
    Added: 'added'
} as const;

export type RegionStateFlagEnum = typeof RegionStateFlagEnum[keyof typeof RegionStateFlagEnum];

/**
 * 
 * @export
 * @interface RegionTreeUiDTO
 */
export interface RegionTreeUiDTO {
    /**
     * 
     * @type {number}
     * @memberof RegionTreeUiDTO
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof RegionTreeUiDTO
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RegionTreeUiDTO
     */
    'displayNameI18n'?: string | null;
    /**
     * 
     * @type {Array<RegionTreeUiDTO>}
     * @memberof RegionTreeUiDTO
     */
    'children'?: Array<RegionTreeUiDTO> | null;
}
/**
 * 
 * @export
 * @interface ResponseContent
 */
export interface ResponseContent {
    /**
     * 
     * @type {string}
     * @memberof ResponseContent
     */
    'type'?: ResponseContentTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof ResponseContent
     */
    'status'?: number;
    /**
     * 
     * @type {string}
     * @memberof ResponseContent
     */
    'title'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ResponseContent
     */
    'detail'?: string | null;
}

export const ResponseContentTypeEnum = {
    Error: 'error',
    Success: 'success',
    Info: 'info',
    Warning: 'warning'
} as const;

export type ResponseContentTypeEnum = typeof ResponseContentTypeEnum[keyof typeof ResponseContentTypeEnum];

/**
 * 
 * @export
 * @interface ResponseMessage
 */
export interface ResponseMessage {
    /**
     * 
     * @type {ResponseContent}
     * @memberof ResponseMessage
     */
    'message'?: ResponseContent;
}
/**
 * 
 * @export
 * @interface RoleListDTO
 */
export interface RoleListDTO {
    /**
     * 
     * @type {string}
     * @memberof RoleListDTO
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RoleListDTO
     */
    'id'?: string;
    /**
     * 
     * @type {number}
     * @memberof RoleListDTO
     */
    'stateFlag'?: number;
}
/**
 * 
 * @export
 * @interface SettingFormDTO
 */
export interface SettingFormDTO {
    /**
     * 
     * @type {string}
     * @memberof SettingFormDTO
     */
    'group'?: SettingFormDTOGroupEnum;
    /**
     * 
     * @type {string}
     * @memberof SettingFormDTO
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SettingFormDTO
     */
    'value'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SettingFormDTO
     */
    'description'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof SettingFormDTO
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof SettingFormDTO
     */
    'stateFlag'?: number;
}

export const SettingFormDTOGroupEnum = {
    ElasticSearch: 'elasticSearch',
    Forms: 'forms'
} as const;

export type SettingFormDTOGroupEnum = typeof SettingFormDTOGroupEnum[keyof typeof SettingFormDTOGroupEnum];

/**
 * 
 * @export
 * @interface SettingListDTO
 */
export interface SettingListDTO {
    /**
     * 
     * @type {string}
     * @memberof SettingListDTO
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SettingListDTO
     */
    'value'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SettingListDTO
     */
    'description'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof SettingListDTO
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof SettingListDTO
     */
    'stateFlag'?: number;
}
/**
 * 
 * @export
 * @interface TechnicalBranch
 */
export interface TechnicalBranch {
    /**
     * 
     * @type {string}
     * @memberof TechnicalBranch
     */
    'uniqueId'?: string;
    /**
     * 
     * @type {number}
     * @memberof TechnicalBranch
     */
    'umbracoId'?: number;
    /**
     * 
     * @type {Array<MediaAttachment>}
     * @memberof TechnicalBranch
     */
    'images'?: Array<MediaAttachment> | null;
    /**
     * 
     * @type {Array<EquipmentType>}
     * @memberof TechnicalBranch
     */
    'equipmentTypes'?: Array<EquipmentType> | null;
    /**
     * 
     * @type {string}
     * @memberof TechnicalBranch
     */
    'name'?: string | null;
    /**
     * 
     * @type {I18nField}
     * @memberof TechnicalBranch
     */
    'displayNameI18n'?: I18nField;
    /**
     * 
     * @type {string}
     * @memberof TechnicalBranch
     */
    'created'?: string;
    /**
     * 
     * @type {string}
     * @memberof TechnicalBranch
     */
    'lastUpdated'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TechnicalBranch
     */
    'deleted'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TechnicalBranch
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TechnicalBranch
     */
    'idAsString'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TechnicalBranch
     */
    'stateFlag'?: TechnicalBranchStateFlagEnum;
}

export const TechnicalBranchStateFlagEnum = {
    Detached: 'detached',
    Unchanged: 'unchanged',
    Deleted: 'deleted',
    Modified: 'modified',
    Added: 'added'
} as const;

export type TechnicalBranchStateFlagEnum = typeof TechnicalBranchStateFlagEnum[keyof typeof TechnicalBranchStateFlagEnum];

/**
 * 
 * @export
 * @interface TechnicalBranchFormDTO
 */
export interface TechnicalBranchFormDTO {
    /**
     * 
     * @type {Array<MediaAttachmentDTO>}
     * @memberof TechnicalBranchFormDTO
     */
    'images'?: Array<MediaAttachmentDTO> | null;
    /**
     * 
     * @type {number}
     * @memberof TechnicalBranchFormDTO
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TechnicalBranchFormDTO
     */
    'name'?: string | null;
    /**
     * 
     * @type {any}
     * @memberof TechnicalBranchFormDTO
     */
    'displayNameI18n'?: any | null;
    /**
     * 
     * @type {number}
     * @memberof TechnicalBranchFormDTO
     */
    'stateFlag'?: number;
}
/**
 * 
 * @export
 * @interface TechnicalBranchListDTO
 */
export interface TechnicalBranchListDTO {
    /**
     * 
     * @type {string}
     * @memberof TechnicalBranchListDTO
     */
    'name'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TechnicalBranchListDTO
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof TechnicalBranchListDTO
     */
    'stateFlag'?: number;
}
/**
 * 
 * @export
 * @interface TechnicalBranchUiDTO
 */
export interface TechnicalBranchUiDTO {
    /**
     * 
     * @type {string}
     * @memberof TechnicalBranchUiDTO
     */
    'displayNameI18n'?: string | null;
    /**
     * 
     * @type {Array<MediaUiDTO>}
     * @memberof TechnicalBranchUiDTO
     */
    'images'?: Array<MediaUiDTO> | null;
    /**
     * 
     * @type {number}
     * @memberof TechnicalBranchUiDTO
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof TechnicalBranchUiDTO
     */
    'stateFlag'?: number;
}
/**
 * 
 * @export
 * @interface Unit
 */
export interface Unit {
    /**
     * 
     * @type {string}
     * @memberof Unit
     */
    'umbId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Unit
     */
    'name'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof Unit
     */
    'unitTypeId'?: number;
    /**
     * 
     * @type {UnitType}
     * @memberof Unit
     */
    'unitType'?: UnitType;
    /**
     * 
     * @type {string}
     * @memberof Unit
     */
    'created'?: string;
    /**
     * 
     * @type {string}
     * @memberof Unit
     */
    'lastUpdated'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Unit
     */
    'deleted'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof Unit
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof Unit
     */
    'idAsString'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Unit
     */
    'stateFlag'?: UnitStateFlagEnum;
}

export const UnitStateFlagEnum = {
    Detached: 'detached',
    Unchanged: 'unchanged',
    Deleted: 'deleted',
    Modified: 'modified',
    Added: 'added'
} as const;

export type UnitStateFlagEnum = typeof UnitStateFlagEnum[keyof typeof UnitStateFlagEnum];

/**
 * 
 * @export
 * @interface UnitFormDTO
 */
export interface UnitFormDTO {
    /**
     * 
     * @type {number}
     * @memberof UnitFormDTO
     */
    'unitTypeId'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof UnitFormDTO
     */
    'name'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UnitFormDTO
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof UnitFormDTO
     */
    'stateFlag'?: number;
}
/**
 * 
 * @export
 * @interface UnitListDTO
 */
export interface UnitListDTO {
    /**
     * 
     * @type {number}
     * @memberof UnitListDTO
     */
    'unitTypeId'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof UnitListDTO
     */
    'name'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UnitListDTO
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof UnitListDTO
     */
    'displayNameI18n'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UnitListDTO
     */
    'stateFlag'?: number;
}
/**
 * 
 * @export
 * @interface UnitType
 */
export interface UnitType {
    /**
     * 
     * @type {string}
     * @memberof UnitType
     */
    'name'?: string | null;
    /**
     * 
     * @type {Array<Unit>}
     * @memberof UnitType
     */
    'units'?: Array<Unit> | null;
    /**
     * 
     * @type {string}
     * @memberof UnitType
     */
    'created'?: string;
    /**
     * 
     * @type {string}
     * @memberof UnitType
     */
    'lastUpdated'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UnitType
     */
    'deleted'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UnitType
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof UnitType
     */
    'idAsString'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UnitType
     */
    'stateFlag'?: UnitTypeStateFlagEnum;
}

export const UnitTypeStateFlagEnum = {
    Detached: 'detached',
    Unchanged: 'unchanged',
    Deleted: 'deleted',
    Modified: 'modified',
    Added: 'added'
} as const;

export type UnitTypeStateFlagEnum = typeof UnitTypeStateFlagEnum[keyof typeof UnitTypeStateFlagEnum];

/**
 * 
 * @export
 * @interface UnitTypeFormDTO
 */
export interface UnitTypeFormDTO {
    /**
     * 
     * @type {string}
     * @memberof UnitTypeFormDTO
     */
    'name'?: string | null;
    /**
     * 
     * @type {Array<UnitFormDTO>}
     * @memberof UnitTypeFormDTO
     */
    'units'?: Array<UnitFormDTO> | null;
    /**
     * 
     * @type {number}
     * @memberof UnitTypeFormDTO
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof UnitTypeFormDTO
     */
    'stateFlag'?: number;
}
/**
 * 
 * @export
 * @interface UnitTypeListDTO
 */
export interface UnitTypeListDTO {
    /**
     * 
     * @type {string}
     * @memberof UnitTypeListDTO
     */
    'name'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UnitTypeListDTO
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof UnitTypeListDTO
     */
    'stateFlag'?: number;
}
/**
 * 
 * @export
 * @interface UserFormDTO
 */
export interface UserFormDTO {
    /**
     * 
     * @type {boolean}
     * @memberof UserFormDTO
     */
    'approved'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserFormDTO
     */
    'emailConfirmed'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserFormDTO
     */
    'lockoutEnabled'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof UserFormDTO
     */
    'accessFailedCount'?: number;
    /**
     * 
     * @type {string}
     * @memberof UserFormDTO
     */
    'password'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserFormDTO
     */
    'passwordConfirm'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserFormDTO
     */
    'state'?: UserFormDTOStateEnum;
    /**
     * 
     * @type {string}
     * @memberof UserFormDTO
     */
    'lockoutEnd'?: string | null;
    /**
     * 
     * @type {Array<ChildDTOOfGuid>}
     * @memberof UserFormDTO
     */
    'roles'?: Array<ChildDTOOfGuid> | null;
    /**
     * 
     * @type {Array<ConsentDTO>}
     * @memberof UserFormDTO
     */
    'consents'?: Array<ConsentDTO> | null;
    /**
     * 
     * @type {string}
     * @memberof UserFormDTO
     */
    'id'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UserFormDTO
     */
    'stateFlag'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof UserFormDTO
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserFormDTO
     */
    'email'?: string | null;
    /**
     * 
     * @type {BaseProfileDTO}
     * @memberof UserFormDTO
     */
    'baseProfile'?: BaseProfileDTO;
    /**
     * 
     * @type {BrandEditorProfileDTO}
     * @memberof UserFormDTO
     */
    'brandEditorProfile'?: BrandEditorProfileDTO;
}

export const UserFormDTOStateEnum = {
    PendingEmailValidation: 'pendingEmailValidation',
    Registered: 'registered'
} as const;

export type UserFormDTOStateEnum = typeof UserFormDTOStateEnum[keyof typeof UserFormDTOStateEnum];

/**
 * 
 * @export
 * @interface UserListDTO
 */
export interface UserListDTO {
    /**
     * 
     * @type {string}
     * @memberof UserListDTO
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserListDTO
     */
    'email'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof UserListDTO
     */
    'approved'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof UserListDTO
     */
    'created'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserListDTO
     */
    'id'?: string;
    /**
     * 
     * @type {number}
     * @memberof UserListDTO
     */
    'stateFlag'?: number;
}
/**
 * 
 * @export
 * @interface UserRegisterDTO
 */
export interface UserRegisterDTO {
    /**
     * 
     * @type {string}
     * @memberof UserRegisterDTO
     */
    'password'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserRegisterDTO
     */
    'passwordConfirm'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserRegisterDTO
     */
    'id'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UserRegisterDTO
     */
    'stateFlag'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof UserRegisterDTO
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserRegisterDTO
     */
    'email'?: string | null;
    /**
     * 
     * @type {BaseProfileDTO}
     * @memberof UserRegisterDTO
     */
    'baseProfile'?: BaseProfileDTO;
    /**
     * 
     * @type {BrandEditorProfileDTO}
     * @memberof UserRegisterDTO
     */
    'brandEditorProfile'?: BrandEditorProfileDTO;
}
/**
 * 
 * @export
 * @interface UserUIDTO
 */
export interface UserUIDTO {
    /**
     * 
     * @type {string}
     * @memberof UserUIDTO
     */
    'id'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UserUIDTO
     */
    'stateFlag'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof UserUIDTO
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserUIDTO
     */
    'email'?: string | null;
    /**
     * 
     * @type {BaseProfileDTO}
     * @memberof UserUIDTO
     */
    'baseProfile'?: BaseProfileDTO;
    /**
     * 
     * @type {BrandEditorProfileDTO}
     * @memberof UserUIDTO
     */
    'brandEditorProfile'?: BrandEditorProfileDTO;
}
/**
 * 
 * @export
 * @interface UsersChangePasswordCommand
 */
export interface UsersChangePasswordCommand {
    /**
     * 
     * @type {string}
     * @memberof UsersChangePasswordCommand
     */
    'oldPassword'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UsersChangePasswordCommand
     */
    'newPassword'?: string | null;
}
/**
 * 
 * @export
 * @interface UsersRegisterBrandUserCommand
 */
export interface UsersRegisterBrandUserCommand {
    /**
     * 
     * @type {UserRegisterDTO}
     * @memberof UsersRegisterBrandUserCommand
     */
    'user'?: UserRegisterDTO;
    /**
     * 
     * @type {Array<number>}
     * @memberof UsersRegisterBrandUserCommand
     */
    'grantedConsents'?: Array<number> | null;
    /**
     * 
     * @type {Array<BrandBaseDTO>}
     * @memberof UsersRegisterBrandUserCommand
     */
    'brands'?: Array<BrandBaseDTO> | null;
}
/**
 * 
 * @export
 * @interface UsersRegisterUserCommand
 */
export interface UsersRegisterUserCommand {
    /**
     * 
     * @type {UserRegisterDTO}
     * @memberof UsersRegisterUserCommand
     */
    'user'?: UserRegisterDTO;
    /**
     * 
     * @type {Array<number>}
     * @memberof UsersRegisterUserCommand
     */
    'grantedConsents'?: Array<number> | null;
}
/**
 * 
 * @export
 * @interface UsersUpsertUserCommand
 */
export interface UsersUpsertUserCommand {
    /**
     * 
     * @type {boolean}
     * @memberof UsersUpsertUserCommand
     */
    'approved'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UsersUpsertUserCommand
     */
    'emailConfirmed'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UsersUpsertUserCommand
     */
    'lockoutEnabled'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof UsersUpsertUserCommand
     */
    'accessFailedCount'?: number;
    /**
     * 
     * @type {string}
     * @memberof UsersUpsertUserCommand
     */
    'password'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UsersUpsertUserCommand
     */
    'passwordConfirm'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UsersUpsertUserCommand
     */
    'state'?: UsersUpsertUserCommandStateEnum;
    /**
     * 
     * @type {string}
     * @memberof UsersUpsertUserCommand
     */
    'lockoutEnd'?: string | null;
    /**
     * 
     * @type {Array<ChildDTOOfGuid>}
     * @memberof UsersUpsertUserCommand
     */
    'roles'?: Array<ChildDTOOfGuid> | null;
    /**
     * 
     * @type {Array<ConsentDTO>}
     * @memberof UsersUpsertUserCommand
     */
    'consents'?: Array<ConsentDTO> | null;
    /**
     * 
     * @type {string}
     * @memberof UsersUpsertUserCommand
     */
    'id'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UsersUpsertUserCommand
     */
    'stateFlag'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof UsersUpsertUserCommand
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UsersUpsertUserCommand
     */
    'email'?: string | null;
    /**
     * 
     * @type {BaseProfileDTO}
     * @memberof UsersUpsertUserCommand
     */
    'baseProfile'?: BaseProfileDTO;
    /**
     * 
     * @type {BrandEditorProfileDTO}
     * @memberof UsersUpsertUserCommand
     */
    'brandEditorProfile'?: BrandEditorProfileDTO;
}

export const UsersUpsertUserCommandStateEnum = {
    PendingEmailValidation: 'pendingEmailValidation',
    Registered: 'registered'
} as const;

export type UsersUpsertUserCommandStateEnum = typeof UsersUpsertUserCommandStateEnum[keyof typeof UsersUpsertUserCommandStateEnum];

/**
 * 
 * @export
 * @interface UtilsHelloWorldQuery
 */
export interface UtilsHelloWorldQuery {
    /**
     * 
     * @type {string}
     * @memberof UtilsHelloWorldQuery
     */
    'name'?: string | null;
}
/**
 * 
 * @export
 * @interface UtilsHelloWorldResponse
 */
export interface UtilsHelloWorldResponse {
    /**
     * 
     * @type {string}
     * @memberof UtilsHelloWorldResponse
     */
    'text'?: string | null;
}

/**
 * AreaApi - axios parameter creator
 * @export
 */
export const AreaApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Area_Create 
         * @param {AreaFormDTO} [areaFormDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        areaCreate: async (areaFormDTO?: AreaFormDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/application/Area`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(areaFormDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Area_Delete 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        areaDelete: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('areaDelete', 'id', id)
            const localVarPath = `/api/v1/application/Area/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Area_DeleteBranch  (Auth policies: management-access-policy)
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        areaDeleteBranch: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('areaDeleteBranch', 'id', id)
            const localVarPath = `/api/v1/application/Area/{id}/branch`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Area_DeleteMany 
         * @param {Array<number>} [ids] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        areaDeleteMany: async (ids?: Array<number>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/application/Area`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (ids) {
                localVarQueryParameter['ids'] = ids;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Area_GetBranch  (Auth policies: management-access-policy)
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        areaGetBranch: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('areaGetBranch', 'id', id)
            const localVarPath = `/api/v1/application/Area/tree/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Area_GetMany Override to sort by path if no sort criteria is set   Used to populate the area select in EqType form in BO (Auth policies: management-access-policy)
         * @param {string} [sortBy] 
         * @param {'asc' | 'desc'} [sortDirection] Values: asc, desc
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {boolean} [skipGlobalFilters] 
         * @param {any} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        areaGetMany: async (sortBy?: string, sortDirection?: 'asc' | 'desc', page?: number, pageSize?: number, skipGlobalFilters?: boolean, body?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/application/Area/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (sortDirection !== undefined) {
                localVarQueryParameter['sortDirection'] = sortDirection;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (skipGlobalFilters !== undefined) {
                localVarQueryParameter['skipGlobalFilters'] = skipGlobalFilters;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Area_GetTree  (Auth policies: management-access-policy)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        areaGetTree: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/application/Area/tree`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Area_MoveAsNthChildOf  (Auth policies: management-access-policy)
         * @param {MoveNodeRequestOfAreaFormDTO} [moveNodeRequestOfAreaFormDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        areaMoveAsNthChildOf: async (moveNodeRequestOfAreaFormDTO?: MoveNodeRequestOfAreaFormDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/application/Area/movechild`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(moveNodeRequestOfAreaFormDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Area_MoveAsNthSiblingOf  (Auth policies: management-access-policy)
         * @param {MoveNodeRequestOfAreaFormDTO} [moveNodeRequestOfAreaFormDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        areaMoveAsNthSiblingOf: async (moveNodeRequestOfAreaFormDTO?: MoveNodeRequestOfAreaFormDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/application/Area/movesibling`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(moveNodeRequestOfAreaFormDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Area_Read 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        areaRead: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('areaRead', 'id', id)
            const localVarPath = `/api/v1/application/Area/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Area_SoftDelete 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        areaSoftDelete: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('areaSoftDelete', 'id', id)
            const localVarPath = `/api/v1/application/Area/{id}/soft`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Area_Update 
         * @param {number} id 
         * @param {AreaFormDTO} [areaFormDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        areaUpdate: async (id: number, areaFormDTO?: AreaFormDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('areaUpdate', 'id', id)
            const localVarPath = `/api/v1/application/Area/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(areaFormDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Area_UpdatePartial 
         * @param {number} id 
         * @param {Array<Operation>} [operation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        areaUpdatePartial: async (id: number, operation?: Array<Operation>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('areaUpdatePartial', 'id', id)
            const localVarPath = `/api/v1/application/Area/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(operation, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AreaApi - functional programming interface
 * @export
 */
export const AreaApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AreaApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Area_Create 
         * @param {AreaFormDTO} [areaFormDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async areaCreate(areaFormDTO?: AreaFormDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AreaFormDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.areaCreate(areaFormDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Area_Delete 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async areaDelete(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.areaDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Area_DeleteBranch  (Auth policies: management-access-policy)
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async areaDeleteBranch(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.areaDeleteBranch(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Area_DeleteMany 
         * @param {Array<number>} [ids] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async areaDeleteMany(ids?: Array<number>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<number>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.areaDeleteMany(ids, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Area_GetBranch  (Auth policies: management-access-policy)
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async areaGetBranch(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AreaTreeDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.areaGetBranch(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Area_GetMany Override to sort by path if no sort criteria is set   Used to populate the area select in EqType form in BO (Auth policies: management-access-policy)
         * @param {string} [sortBy] 
         * @param {'asc' | 'desc'} [sortDirection] Values: asc, desc
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {boolean} [skipGlobalFilters] 
         * @param {any} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async areaGetMany(sortBy?: string, sortDirection?: 'asc' | 'desc', page?: number, pageSize?: number, skipGlobalFilters?: boolean, body?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagedResponseDtoOfAreaListDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.areaGetMany(sortBy, sortDirection, page, pageSize, skipGlobalFilters, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Area_GetTree  (Auth policies: management-access-policy)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async areaGetTree(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AreaTreeDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.areaGetTree(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Area_MoveAsNthChildOf  (Auth policies: management-access-policy)
         * @param {MoveNodeRequestOfAreaFormDTO} [moveNodeRequestOfAreaFormDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async areaMoveAsNthChildOf(moveNodeRequestOfAreaFormDTO?: MoveNodeRequestOfAreaFormDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.areaMoveAsNthChildOf(moveNodeRequestOfAreaFormDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Area_MoveAsNthSiblingOf  (Auth policies: management-access-policy)
         * @param {MoveNodeRequestOfAreaFormDTO} [moveNodeRequestOfAreaFormDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async areaMoveAsNthSiblingOf(moveNodeRequestOfAreaFormDTO?: MoveNodeRequestOfAreaFormDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.areaMoveAsNthSiblingOf(moveNodeRequestOfAreaFormDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Area_Read 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async areaRead(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AreaFormDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.areaRead(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Area_SoftDelete 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async areaSoftDelete(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.areaSoftDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Area_Update 
         * @param {number} id 
         * @param {AreaFormDTO} [areaFormDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async areaUpdate(id: number, areaFormDTO?: AreaFormDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AreaFormDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.areaUpdate(id, areaFormDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Area_UpdatePartial 
         * @param {number} id 
         * @param {Array<Operation>} [operation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async areaUpdatePartial(id: number, operation?: Array<Operation>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AreaFormDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.areaUpdatePartial(id, operation, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AreaApi - factory interface
 * @export
 */
export const AreaApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AreaApiFp(configuration)
    return {
        /**
         * 
         * @summary Area_Create 
         * @param {AreaFormDTO} [areaFormDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        areaCreate(areaFormDTO?: AreaFormDTO, options?: any): AxiosPromise<AreaFormDTO> {
            return localVarFp.areaCreate(areaFormDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Area_Delete 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        areaDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.areaDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Area_DeleteBranch  (Auth policies: management-access-policy)
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        areaDeleteBranch(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.areaDeleteBranch(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Area_DeleteMany 
         * @param {Array<number>} [ids] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        areaDeleteMany(ids?: Array<number>, options?: any): AxiosPromise<Array<number>> {
            return localVarFp.areaDeleteMany(ids, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Area_GetBranch  (Auth policies: management-access-policy)
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        areaGetBranch(id: number, options?: any): AxiosPromise<Array<AreaTreeDTO>> {
            return localVarFp.areaGetBranch(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Area_GetMany Override to sort by path if no sort criteria is set   Used to populate the area select in EqType form in BO (Auth policies: management-access-policy)
         * @param {string} [sortBy] 
         * @param {'asc' | 'desc'} [sortDirection] Values: asc, desc
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {boolean} [skipGlobalFilters] 
         * @param {any} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        areaGetMany(sortBy?: string, sortDirection?: 'asc' | 'desc', page?: number, pageSize?: number, skipGlobalFilters?: boolean, body?: any, options?: any): AxiosPromise<PagedResponseDtoOfAreaListDTO> {
            return localVarFp.areaGetMany(sortBy, sortDirection, page, pageSize, skipGlobalFilters, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Area_GetTree  (Auth policies: management-access-policy)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        areaGetTree(options?: any): AxiosPromise<Array<AreaTreeDTO>> {
            return localVarFp.areaGetTree(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Area_MoveAsNthChildOf  (Auth policies: management-access-policy)
         * @param {MoveNodeRequestOfAreaFormDTO} [moveNodeRequestOfAreaFormDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        areaMoveAsNthChildOf(moveNodeRequestOfAreaFormDTO?: MoveNodeRequestOfAreaFormDTO, options?: any): AxiosPromise<void> {
            return localVarFp.areaMoveAsNthChildOf(moveNodeRequestOfAreaFormDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Area_MoveAsNthSiblingOf  (Auth policies: management-access-policy)
         * @param {MoveNodeRequestOfAreaFormDTO} [moveNodeRequestOfAreaFormDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        areaMoveAsNthSiblingOf(moveNodeRequestOfAreaFormDTO?: MoveNodeRequestOfAreaFormDTO, options?: any): AxiosPromise<void> {
            return localVarFp.areaMoveAsNthSiblingOf(moveNodeRequestOfAreaFormDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Area_Read 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        areaRead(id: number, options?: any): AxiosPromise<AreaFormDTO> {
            return localVarFp.areaRead(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Area_SoftDelete 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        areaSoftDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.areaSoftDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Area_Update 
         * @param {number} id 
         * @param {AreaFormDTO} [areaFormDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        areaUpdate(id: number, areaFormDTO?: AreaFormDTO, options?: any): AxiosPromise<AreaFormDTO> {
            return localVarFp.areaUpdate(id, areaFormDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Area_UpdatePartial 
         * @param {number} id 
         * @param {Array<Operation>} [operation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        areaUpdatePartial(id: number, operation?: Array<Operation>, options?: any): AxiosPromise<AreaFormDTO> {
            return localVarFp.areaUpdatePartial(id, operation, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AreaApi - object-oriented interface
 * @export
 * @class AreaApi
 * @extends {BaseAPI}
 */
export class AreaApi extends BaseAPI {
    /**
     * 
     * @summary Area_Create 
     * @param {AreaFormDTO} [areaFormDTO] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AreaApi
     */
    public areaCreate(areaFormDTO?: AreaFormDTO, options?: AxiosRequestConfig) {
        return AreaApiFp(this.configuration).areaCreate(areaFormDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Area_Delete 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AreaApi
     */
    public areaDelete(id: number, options?: AxiosRequestConfig) {
        return AreaApiFp(this.configuration).areaDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Area_DeleteBranch  (Auth policies: management-access-policy)
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AreaApi
     */
    public areaDeleteBranch(id: number, options?: AxiosRequestConfig) {
        return AreaApiFp(this.configuration).areaDeleteBranch(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Area_DeleteMany 
     * @param {Array<number>} [ids] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AreaApi
     */
    public areaDeleteMany(ids?: Array<number>, options?: AxiosRequestConfig) {
        return AreaApiFp(this.configuration).areaDeleteMany(ids, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Area_GetBranch  (Auth policies: management-access-policy)
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AreaApi
     */
    public areaGetBranch(id: number, options?: AxiosRequestConfig) {
        return AreaApiFp(this.configuration).areaGetBranch(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Area_GetMany Override to sort by path if no sort criteria is set   Used to populate the area select in EqType form in BO (Auth policies: management-access-policy)
     * @param {string} [sortBy] 
     * @param {'asc' | 'desc'} [sortDirection] Values: asc, desc
     * @param {number} [page] 
     * @param {number} [pageSize] 
     * @param {boolean} [skipGlobalFilters] 
     * @param {any} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AreaApi
     */
    public areaGetMany(sortBy?: string, sortDirection?: 'asc' | 'desc', page?: number, pageSize?: number, skipGlobalFilters?: boolean, body?: any, options?: AxiosRequestConfig) {
        return AreaApiFp(this.configuration).areaGetMany(sortBy, sortDirection, page, pageSize, skipGlobalFilters, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Area_GetTree  (Auth policies: management-access-policy)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AreaApi
     */
    public areaGetTree(options?: AxiosRequestConfig) {
        return AreaApiFp(this.configuration).areaGetTree(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Area_MoveAsNthChildOf  (Auth policies: management-access-policy)
     * @param {MoveNodeRequestOfAreaFormDTO} [moveNodeRequestOfAreaFormDTO] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AreaApi
     */
    public areaMoveAsNthChildOf(moveNodeRequestOfAreaFormDTO?: MoveNodeRequestOfAreaFormDTO, options?: AxiosRequestConfig) {
        return AreaApiFp(this.configuration).areaMoveAsNthChildOf(moveNodeRequestOfAreaFormDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Area_MoveAsNthSiblingOf  (Auth policies: management-access-policy)
     * @param {MoveNodeRequestOfAreaFormDTO} [moveNodeRequestOfAreaFormDTO] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AreaApi
     */
    public areaMoveAsNthSiblingOf(moveNodeRequestOfAreaFormDTO?: MoveNodeRequestOfAreaFormDTO, options?: AxiosRequestConfig) {
        return AreaApiFp(this.configuration).areaMoveAsNthSiblingOf(moveNodeRequestOfAreaFormDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Area_Read 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AreaApi
     */
    public areaRead(id: number, options?: AxiosRequestConfig) {
        return AreaApiFp(this.configuration).areaRead(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Area_SoftDelete 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AreaApi
     */
    public areaSoftDelete(id: number, options?: AxiosRequestConfig) {
        return AreaApiFp(this.configuration).areaSoftDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Area_Update 
     * @param {number} id 
     * @param {AreaFormDTO} [areaFormDTO] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AreaApi
     */
    public areaUpdate(id: number, areaFormDTO?: AreaFormDTO, options?: AxiosRequestConfig) {
        return AreaApiFp(this.configuration).areaUpdate(id, areaFormDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Area_UpdatePartial 
     * @param {number} id 
     * @param {Array<Operation>} [operation] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AreaApi
     */
    public areaUpdatePartial(id: number, operation?: Array<Operation>, options?: AxiosRequestConfig) {
        return AreaApiFp(this.configuration).areaUpdatePartial(id, operation, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * BrandApi - axios parameter creator
 * @export
 */
export const BrandApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Brand_Create 
         * @param {BrandFormDTO} [brandFormDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brandCreate: async (brandFormDTO?: BrandFormDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/application/Brand`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(brandFormDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Brand_Delete 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brandDelete: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('brandDelete', 'id', id)
            const localVarPath = `/api/v1/application/Brand/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Brand_DeleteMany 
         * @param {Array<number>} [ids] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brandDeleteMany: async (ids?: Array<number>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/application/Brand`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (ids) {
                localVarQueryParameter['ids'] = ids;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Brand_GetMany 
         * @param {string} [sortBy] 
         * @param {'asc' | 'desc'} [sortDirection] Values: asc, desc
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {boolean} [skipGlobalFilters] 
         * @param {any} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brandGetMany: async (sortBy?: string, sortDirection?: 'asc' | 'desc', page?: number, pageSize?: number, skipGlobalFilters?: boolean, body?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/application/Brand/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (sortDirection !== undefined) {
                localVarQueryParameter['sortDirection'] = sortDirection;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (skipGlobalFilters !== undefined) {
                localVarQueryParameter['skipGlobalFilters'] = skipGlobalFilters;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Brand_Read 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brandRead: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('brandRead', 'id', id)
            const localVarPath = `/api/v1/application/Brand/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Brand_SoftDelete 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brandSoftDelete: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('brandSoftDelete', 'id', id)
            const localVarPath = `/api/v1/application/Brand/{id}/soft`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Brand_Update 
         * @param {number} id 
         * @param {BrandFormDTO} [brandFormDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brandUpdate: async (id: number, brandFormDTO?: BrandFormDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('brandUpdate', 'id', id)
            const localVarPath = `/api/v1/application/Brand/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(brandFormDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Brand_UpdatePartial 
         * @param {number} id 
         * @param {Array<Operation>} [operation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brandUpdatePartial: async (id: number, operation?: Array<Operation>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('brandUpdatePartial', 'id', id)
            const localVarPath = `/api/v1/application/Brand/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(operation, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BrandApi - functional programming interface
 * @export
 */
export const BrandApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BrandApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Brand_Create 
         * @param {BrandFormDTO} [brandFormDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async brandCreate(brandFormDTO?: BrandFormDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BrandFormDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.brandCreate(brandFormDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Brand_Delete 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async brandDelete(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.brandDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Brand_DeleteMany 
         * @param {Array<number>} [ids] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async brandDeleteMany(ids?: Array<number>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<number>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.brandDeleteMany(ids, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Brand_GetMany 
         * @param {string} [sortBy] 
         * @param {'asc' | 'desc'} [sortDirection] Values: asc, desc
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {boolean} [skipGlobalFilters] 
         * @param {any} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async brandGetMany(sortBy?: string, sortDirection?: 'asc' | 'desc', page?: number, pageSize?: number, skipGlobalFilters?: boolean, body?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagedResponseDtoOfBrandListDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.brandGetMany(sortBy, sortDirection, page, pageSize, skipGlobalFilters, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Brand_Read 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async brandRead(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BrandFormDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.brandRead(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Brand_SoftDelete 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async brandSoftDelete(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.brandSoftDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Brand_Update 
         * @param {number} id 
         * @param {BrandFormDTO} [brandFormDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async brandUpdate(id: number, brandFormDTO?: BrandFormDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BrandFormDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.brandUpdate(id, brandFormDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Brand_UpdatePartial 
         * @param {number} id 
         * @param {Array<Operation>} [operation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async brandUpdatePartial(id: number, operation?: Array<Operation>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BrandFormDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.brandUpdatePartial(id, operation, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BrandApi - factory interface
 * @export
 */
export const BrandApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BrandApiFp(configuration)
    return {
        /**
         * 
         * @summary Brand_Create 
         * @param {BrandFormDTO} [brandFormDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brandCreate(brandFormDTO?: BrandFormDTO, options?: any): AxiosPromise<BrandFormDTO> {
            return localVarFp.brandCreate(brandFormDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Brand_Delete 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brandDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.brandDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Brand_DeleteMany 
         * @param {Array<number>} [ids] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brandDeleteMany(ids?: Array<number>, options?: any): AxiosPromise<Array<number>> {
            return localVarFp.brandDeleteMany(ids, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Brand_GetMany 
         * @param {string} [sortBy] 
         * @param {'asc' | 'desc'} [sortDirection] Values: asc, desc
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {boolean} [skipGlobalFilters] 
         * @param {any} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brandGetMany(sortBy?: string, sortDirection?: 'asc' | 'desc', page?: number, pageSize?: number, skipGlobalFilters?: boolean, body?: any, options?: any): AxiosPromise<PagedResponseDtoOfBrandListDTO> {
            return localVarFp.brandGetMany(sortBy, sortDirection, page, pageSize, skipGlobalFilters, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Brand_Read 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brandRead(id: number, options?: any): AxiosPromise<BrandFormDTO> {
            return localVarFp.brandRead(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Brand_SoftDelete 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brandSoftDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.brandSoftDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Brand_Update 
         * @param {number} id 
         * @param {BrandFormDTO} [brandFormDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brandUpdate(id: number, brandFormDTO?: BrandFormDTO, options?: any): AxiosPromise<BrandFormDTO> {
            return localVarFp.brandUpdate(id, brandFormDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Brand_UpdatePartial 
         * @param {number} id 
         * @param {Array<Operation>} [operation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brandUpdatePartial(id: number, operation?: Array<Operation>, options?: any): AxiosPromise<BrandFormDTO> {
            return localVarFp.brandUpdatePartial(id, operation, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BrandApi - object-oriented interface
 * @export
 * @class BrandApi
 * @extends {BaseAPI}
 */
export class BrandApi extends BaseAPI {
    /**
     * 
     * @summary Brand_Create 
     * @param {BrandFormDTO} [brandFormDTO] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BrandApi
     */
    public brandCreate(brandFormDTO?: BrandFormDTO, options?: AxiosRequestConfig) {
        return BrandApiFp(this.configuration).brandCreate(brandFormDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Brand_Delete 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BrandApi
     */
    public brandDelete(id: number, options?: AxiosRequestConfig) {
        return BrandApiFp(this.configuration).brandDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Brand_DeleteMany 
     * @param {Array<number>} [ids] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BrandApi
     */
    public brandDeleteMany(ids?: Array<number>, options?: AxiosRequestConfig) {
        return BrandApiFp(this.configuration).brandDeleteMany(ids, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Brand_GetMany 
     * @param {string} [sortBy] 
     * @param {'asc' | 'desc'} [sortDirection] Values: asc, desc
     * @param {number} [page] 
     * @param {number} [pageSize] 
     * @param {boolean} [skipGlobalFilters] 
     * @param {any} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BrandApi
     */
    public brandGetMany(sortBy?: string, sortDirection?: 'asc' | 'desc', page?: number, pageSize?: number, skipGlobalFilters?: boolean, body?: any, options?: AxiosRequestConfig) {
        return BrandApiFp(this.configuration).brandGetMany(sortBy, sortDirection, page, pageSize, skipGlobalFilters, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Brand_Read 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BrandApi
     */
    public brandRead(id: number, options?: AxiosRequestConfig) {
        return BrandApiFp(this.configuration).brandRead(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Brand_SoftDelete 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BrandApi
     */
    public brandSoftDelete(id: number, options?: AxiosRequestConfig) {
        return BrandApiFp(this.configuration).brandSoftDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Brand_Update 
     * @param {number} id 
     * @param {BrandFormDTO} [brandFormDTO] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BrandApi
     */
    public brandUpdate(id: number, brandFormDTO?: BrandFormDTO, options?: AxiosRequestConfig) {
        return BrandApiFp(this.configuration).brandUpdate(id, brandFormDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Brand_UpdatePartial 
     * @param {number} id 
     * @param {Array<Operation>} [operation] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BrandApi
     */
    public brandUpdatePartial(id: number, operation?: Array<Operation>, options?: AxiosRequestConfig) {
        return BrandApiFp(this.configuration).brandUpdatePartial(id, operation, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ContentApi - axios parameter creator
 * @export
 */
export const ContentApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Content_Create  (Auth policies: management-access-policy)
         * @param {ContentFormDTO} [contentFormDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contentCreate: async (contentFormDTO?: ContentFormDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/application/Content`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(contentFormDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Content_Delete 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contentDelete: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('contentDelete', 'id', id)
            const localVarPath = `/api/v1/application/Content/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Content_DeleteBranch  (Auth policies: management-access-policy)
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contentDeleteBranch: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('contentDeleteBranch', 'id', id)
            const localVarPath = `/api/v1/application/Content/{id}/branch`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Content_DeleteMany 
         * @param {Array<number>} [ids] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contentDeleteMany: async (ids?: Array<number>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/application/Content`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (ids) {
                localVarQueryParameter['ids'] = ids;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Content_GetBranch  (Auth policies: management-access-policy)
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contentGetBranch: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('contentGetBranch', 'id', id)
            const localVarPath = `/api/v1/application/Content/tree/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Content_GetMany Override to sort by path if no sort criteria is set   Used to populate the Content select in EqType form in BO (Auth policies: management-access-policy)
         * @param {string} [sortBy] 
         * @param {'asc' | 'desc'} [sortDirection] Values: asc, desc
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {boolean} [skipGlobalFilters] 
         * @param {any} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contentGetMany: async (sortBy?: string, sortDirection?: 'asc' | 'desc', page?: number, pageSize?: number, skipGlobalFilters?: boolean, body?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/application/Content/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (sortDirection !== undefined) {
                localVarQueryParameter['sortDirection'] = sortDirection;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (skipGlobalFilters !== undefined) {
                localVarQueryParameter['skipGlobalFilters'] = skipGlobalFilters;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Content_GetTree  (Auth policies: management-access-policy)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contentGetTree: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/application/Content/tree`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Content_MoveAsNthChildOf  (Auth policies: management-access-policy)
         * @param {MoveNodeRequestOfContentFormDTO} [moveNodeRequestOfContentFormDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contentMoveAsNthChildOf: async (moveNodeRequestOfContentFormDTO?: MoveNodeRequestOfContentFormDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/application/Content/movechild`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(moveNodeRequestOfContentFormDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Content_MoveAsNthSiblingOf  (Auth policies: management-access-policy)
         * @param {MoveNodeRequestOfContentFormDTO} [moveNodeRequestOfContentFormDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contentMoveAsNthSiblingOf: async (moveNodeRequestOfContentFormDTO?: MoveNodeRequestOfContentFormDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/application/Content/movesibling`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(moveNodeRequestOfContentFormDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Content_Read 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contentRead: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('contentRead', 'id', id)
            const localVarPath = `/api/v1/application/Content/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Content_SoftDelete 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contentSoftDelete: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('contentSoftDelete', 'id', id)
            const localVarPath = `/api/v1/application/Content/{id}/soft`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Content_Update  (Auth policies: management-access-policy)
         * @param {number} id 
         * @param {ContentFormDTO} [contentFormDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contentUpdate: async (id: number, contentFormDTO?: ContentFormDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('contentUpdate', 'id', id)
            const localVarPath = `/api/v1/application/Content/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(contentFormDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Content_UpdatePartial 
         * @param {number} id 
         * @param {Array<Operation>} [operation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contentUpdatePartial: async (id: number, operation?: Array<Operation>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('contentUpdatePartial', 'id', id)
            const localVarPath = `/api/v1/application/Content/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(operation, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ContentApi - functional programming interface
 * @export
 */
export const ContentApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ContentApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Content_Create  (Auth policies: management-access-policy)
         * @param {ContentFormDTO} [contentFormDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async contentCreate(contentFormDTO?: ContentFormDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContentFormDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.contentCreate(contentFormDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Content_Delete 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async contentDelete(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.contentDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Content_DeleteBranch  (Auth policies: management-access-policy)
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async contentDeleteBranch(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.contentDeleteBranch(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Content_DeleteMany 
         * @param {Array<number>} [ids] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async contentDeleteMany(ids?: Array<number>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<number>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.contentDeleteMany(ids, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Content_GetBranch  (Auth policies: management-access-policy)
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async contentGetBranch(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ContentTreeDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.contentGetBranch(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Content_GetMany Override to sort by path if no sort criteria is set   Used to populate the Content select in EqType form in BO (Auth policies: management-access-policy)
         * @param {string} [sortBy] 
         * @param {'asc' | 'desc'} [sortDirection] Values: asc, desc
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {boolean} [skipGlobalFilters] 
         * @param {any} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async contentGetMany(sortBy?: string, sortDirection?: 'asc' | 'desc', page?: number, pageSize?: number, skipGlobalFilters?: boolean, body?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagedResponseDtoOfContentListDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.contentGetMany(sortBy, sortDirection, page, pageSize, skipGlobalFilters, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Content_GetTree  (Auth policies: management-access-policy)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async contentGetTree(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ContentTreeDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.contentGetTree(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Content_MoveAsNthChildOf  (Auth policies: management-access-policy)
         * @param {MoveNodeRequestOfContentFormDTO} [moveNodeRequestOfContentFormDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async contentMoveAsNthChildOf(moveNodeRequestOfContentFormDTO?: MoveNodeRequestOfContentFormDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.contentMoveAsNthChildOf(moveNodeRequestOfContentFormDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Content_MoveAsNthSiblingOf  (Auth policies: management-access-policy)
         * @param {MoveNodeRequestOfContentFormDTO} [moveNodeRequestOfContentFormDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async contentMoveAsNthSiblingOf(moveNodeRequestOfContentFormDTO?: MoveNodeRequestOfContentFormDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.contentMoveAsNthSiblingOf(moveNodeRequestOfContentFormDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Content_Read 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async contentRead(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContentFormDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.contentRead(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Content_SoftDelete 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async contentSoftDelete(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.contentSoftDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Content_Update  (Auth policies: management-access-policy)
         * @param {number} id 
         * @param {ContentFormDTO} [contentFormDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async contentUpdate(id: number, contentFormDTO?: ContentFormDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContentFormDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.contentUpdate(id, contentFormDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Content_UpdatePartial 
         * @param {number} id 
         * @param {Array<Operation>} [operation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async contentUpdatePartial(id: number, operation?: Array<Operation>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContentFormDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.contentUpdatePartial(id, operation, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ContentApi - factory interface
 * @export
 */
export const ContentApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ContentApiFp(configuration)
    return {
        /**
         * 
         * @summary Content_Create  (Auth policies: management-access-policy)
         * @param {ContentFormDTO} [contentFormDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contentCreate(contentFormDTO?: ContentFormDTO, options?: any): AxiosPromise<ContentFormDTO> {
            return localVarFp.contentCreate(contentFormDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Content_Delete 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contentDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.contentDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Content_DeleteBranch  (Auth policies: management-access-policy)
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contentDeleteBranch(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.contentDeleteBranch(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Content_DeleteMany 
         * @param {Array<number>} [ids] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contentDeleteMany(ids?: Array<number>, options?: any): AxiosPromise<Array<number>> {
            return localVarFp.contentDeleteMany(ids, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Content_GetBranch  (Auth policies: management-access-policy)
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contentGetBranch(id: number, options?: any): AxiosPromise<Array<ContentTreeDTO>> {
            return localVarFp.contentGetBranch(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Content_GetMany Override to sort by path if no sort criteria is set   Used to populate the Content select in EqType form in BO (Auth policies: management-access-policy)
         * @param {string} [sortBy] 
         * @param {'asc' | 'desc'} [sortDirection] Values: asc, desc
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {boolean} [skipGlobalFilters] 
         * @param {any} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contentGetMany(sortBy?: string, sortDirection?: 'asc' | 'desc', page?: number, pageSize?: number, skipGlobalFilters?: boolean, body?: any, options?: any): AxiosPromise<PagedResponseDtoOfContentListDTO> {
            return localVarFp.contentGetMany(sortBy, sortDirection, page, pageSize, skipGlobalFilters, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Content_GetTree  (Auth policies: management-access-policy)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contentGetTree(options?: any): AxiosPromise<Array<ContentTreeDTO>> {
            return localVarFp.contentGetTree(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Content_MoveAsNthChildOf  (Auth policies: management-access-policy)
         * @param {MoveNodeRequestOfContentFormDTO} [moveNodeRequestOfContentFormDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contentMoveAsNthChildOf(moveNodeRequestOfContentFormDTO?: MoveNodeRequestOfContentFormDTO, options?: any): AxiosPromise<void> {
            return localVarFp.contentMoveAsNthChildOf(moveNodeRequestOfContentFormDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Content_MoveAsNthSiblingOf  (Auth policies: management-access-policy)
         * @param {MoveNodeRequestOfContentFormDTO} [moveNodeRequestOfContentFormDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contentMoveAsNthSiblingOf(moveNodeRequestOfContentFormDTO?: MoveNodeRequestOfContentFormDTO, options?: any): AxiosPromise<void> {
            return localVarFp.contentMoveAsNthSiblingOf(moveNodeRequestOfContentFormDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Content_Read 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contentRead(id: number, options?: any): AxiosPromise<ContentFormDTO> {
            return localVarFp.contentRead(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Content_SoftDelete 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contentSoftDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.contentSoftDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Content_Update  (Auth policies: management-access-policy)
         * @param {number} id 
         * @param {ContentFormDTO} [contentFormDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contentUpdate(id: number, contentFormDTO?: ContentFormDTO, options?: any): AxiosPromise<ContentFormDTO> {
            return localVarFp.contentUpdate(id, contentFormDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Content_UpdatePartial 
         * @param {number} id 
         * @param {Array<Operation>} [operation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contentUpdatePartial(id: number, operation?: Array<Operation>, options?: any): AxiosPromise<ContentFormDTO> {
            return localVarFp.contentUpdatePartial(id, operation, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ContentApi - object-oriented interface
 * @export
 * @class ContentApi
 * @extends {BaseAPI}
 */
export class ContentApi extends BaseAPI {
    /**
     * 
     * @summary Content_Create  (Auth policies: management-access-policy)
     * @param {ContentFormDTO} [contentFormDTO] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentApi
     */
    public contentCreate(contentFormDTO?: ContentFormDTO, options?: AxiosRequestConfig) {
        return ContentApiFp(this.configuration).contentCreate(contentFormDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Content_Delete 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentApi
     */
    public contentDelete(id: number, options?: AxiosRequestConfig) {
        return ContentApiFp(this.configuration).contentDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Content_DeleteBranch  (Auth policies: management-access-policy)
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentApi
     */
    public contentDeleteBranch(id: number, options?: AxiosRequestConfig) {
        return ContentApiFp(this.configuration).contentDeleteBranch(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Content_DeleteMany 
     * @param {Array<number>} [ids] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentApi
     */
    public contentDeleteMany(ids?: Array<number>, options?: AxiosRequestConfig) {
        return ContentApiFp(this.configuration).contentDeleteMany(ids, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Content_GetBranch  (Auth policies: management-access-policy)
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentApi
     */
    public contentGetBranch(id: number, options?: AxiosRequestConfig) {
        return ContentApiFp(this.configuration).contentGetBranch(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Content_GetMany Override to sort by path if no sort criteria is set   Used to populate the Content select in EqType form in BO (Auth policies: management-access-policy)
     * @param {string} [sortBy] 
     * @param {'asc' | 'desc'} [sortDirection] Values: asc, desc
     * @param {number} [page] 
     * @param {number} [pageSize] 
     * @param {boolean} [skipGlobalFilters] 
     * @param {any} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentApi
     */
    public contentGetMany(sortBy?: string, sortDirection?: 'asc' | 'desc', page?: number, pageSize?: number, skipGlobalFilters?: boolean, body?: any, options?: AxiosRequestConfig) {
        return ContentApiFp(this.configuration).contentGetMany(sortBy, sortDirection, page, pageSize, skipGlobalFilters, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Content_GetTree  (Auth policies: management-access-policy)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentApi
     */
    public contentGetTree(options?: AxiosRequestConfig) {
        return ContentApiFp(this.configuration).contentGetTree(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Content_MoveAsNthChildOf  (Auth policies: management-access-policy)
     * @param {MoveNodeRequestOfContentFormDTO} [moveNodeRequestOfContentFormDTO] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentApi
     */
    public contentMoveAsNthChildOf(moveNodeRequestOfContentFormDTO?: MoveNodeRequestOfContentFormDTO, options?: AxiosRequestConfig) {
        return ContentApiFp(this.configuration).contentMoveAsNthChildOf(moveNodeRequestOfContentFormDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Content_MoveAsNthSiblingOf  (Auth policies: management-access-policy)
     * @param {MoveNodeRequestOfContentFormDTO} [moveNodeRequestOfContentFormDTO] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentApi
     */
    public contentMoveAsNthSiblingOf(moveNodeRequestOfContentFormDTO?: MoveNodeRequestOfContentFormDTO, options?: AxiosRequestConfig) {
        return ContentApiFp(this.configuration).contentMoveAsNthSiblingOf(moveNodeRequestOfContentFormDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Content_Read 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentApi
     */
    public contentRead(id: number, options?: AxiosRequestConfig) {
        return ContentApiFp(this.configuration).contentRead(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Content_SoftDelete 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentApi
     */
    public contentSoftDelete(id: number, options?: AxiosRequestConfig) {
        return ContentApiFp(this.configuration).contentSoftDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Content_Update  (Auth policies: management-access-policy)
     * @param {number} id 
     * @param {ContentFormDTO} [contentFormDTO] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentApi
     */
    public contentUpdate(id: number, contentFormDTO?: ContentFormDTO, options?: AxiosRequestConfig) {
        return ContentApiFp(this.configuration).contentUpdate(id, contentFormDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Content_UpdatePartial 
     * @param {number} id 
     * @param {Array<Operation>} [operation] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentApi
     */
    public contentUpdatePartial(id: number, operation?: Array<Operation>, options?: AxiosRequestConfig) {
        return ContentApiFp(this.configuration).contentUpdatePartial(id, operation, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CountryApi - axios parameter creator
 * @export
 */
export const CountryApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Country_Create 
         * @param {CountryFormDTO} [countryFormDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        countryCreate: async (countryFormDTO?: CountryFormDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/application/Country`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(countryFormDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Country_Delete 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        countryDelete: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('countryDelete', 'id', id)
            const localVarPath = `/api/v1/application/Country/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Country_DeleteMany 
         * @param {Array<number>} [ids] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        countryDeleteMany: async (ids?: Array<number>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/application/Country`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (ids) {
                localVarQueryParameter['ids'] = ids;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Country_GetMany 
         * @param {string} [sortBy] 
         * @param {'asc' | 'desc'} [sortDirection] Values: asc, desc
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {boolean} [skipGlobalFilters] 
         * @param {any} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        countryGetMany: async (sortBy?: string, sortDirection?: 'asc' | 'desc', page?: number, pageSize?: number, skipGlobalFilters?: boolean, body?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/application/Country/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (sortDirection !== undefined) {
                localVarQueryParameter['sortDirection'] = sortDirection;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (skipGlobalFilters !== undefined) {
                localVarQueryParameter['skipGlobalFilters'] = skipGlobalFilters;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Country_Read 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        countryRead: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('countryRead', 'id', id)
            const localVarPath = `/api/v1/application/Country/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Country_SoftDelete 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        countrySoftDelete: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('countrySoftDelete', 'id', id)
            const localVarPath = `/api/v1/application/Country/{id}/soft`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Country_Update 
         * @param {number} id 
         * @param {CountryFormDTO} [countryFormDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        countryUpdate: async (id: number, countryFormDTO?: CountryFormDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('countryUpdate', 'id', id)
            const localVarPath = `/api/v1/application/Country/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(countryFormDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Country_UpdatePartial 
         * @param {number} id 
         * @param {Array<Operation>} [operation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        countryUpdatePartial: async (id: number, operation?: Array<Operation>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('countryUpdatePartial', 'id', id)
            const localVarPath = `/api/v1/application/Country/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(operation, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CountryApi - functional programming interface
 * @export
 */
export const CountryApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CountryApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Country_Create 
         * @param {CountryFormDTO} [countryFormDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async countryCreate(countryFormDTO?: CountryFormDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CountryFormDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.countryCreate(countryFormDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Country_Delete 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async countryDelete(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.countryDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Country_DeleteMany 
         * @param {Array<number>} [ids] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async countryDeleteMany(ids?: Array<number>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<number>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.countryDeleteMany(ids, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Country_GetMany 
         * @param {string} [sortBy] 
         * @param {'asc' | 'desc'} [sortDirection] Values: asc, desc
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {boolean} [skipGlobalFilters] 
         * @param {any} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async countryGetMany(sortBy?: string, sortDirection?: 'asc' | 'desc', page?: number, pageSize?: number, skipGlobalFilters?: boolean, body?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagedResponseDtoOfCountryListDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.countryGetMany(sortBy, sortDirection, page, pageSize, skipGlobalFilters, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Country_Read 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async countryRead(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CountryFormDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.countryRead(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Country_SoftDelete 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async countrySoftDelete(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.countrySoftDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Country_Update 
         * @param {number} id 
         * @param {CountryFormDTO} [countryFormDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async countryUpdate(id: number, countryFormDTO?: CountryFormDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CountryFormDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.countryUpdate(id, countryFormDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Country_UpdatePartial 
         * @param {number} id 
         * @param {Array<Operation>} [operation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async countryUpdatePartial(id: number, operation?: Array<Operation>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CountryFormDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.countryUpdatePartial(id, operation, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CountryApi - factory interface
 * @export
 */
export const CountryApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CountryApiFp(configuration)
    return {
        /**
         * 
         * @summary Country_Create 
         * @param {CountryFormDTO} [countryFormDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        countryCreate(countryFormDTO?: CountryFormDTO, options?: any): AxiosPromise<CountryFormDTO> {
            return localVarFp.countryCreate(countryFormDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Country_Delete 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        countryDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.countryDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Country_DeleteMany 
         * @param {Array<number>} [ids] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        countryDeleteMany(ids?: Array<number>, options?: any): AxiosPromise<Array<number>> {
            return localVarFp.countryDeleteMany(ids, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Country_GetMany 
         * @param {string} [sortBy] 
         * @param {'asc' | 'desc'} [sortDirection] Values: asc, desc
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {boolean} [skipGlobalFilters] 
         * @param {any} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        countryGetMany(sortBy?: string, sortDirection?: 'asc' | 'desc', page?: number, pageSize?: number, skipGlobalFilters?: boolean, body?: any, options?: any): AxiosPromise<PagedResponseDtoOfCountryListDTO> {
            return localVarFp.countryGetMany(sortBy, sortDirection, page, pageSize, skipGlobalFilters, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Country_Read 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        countryRead(id: number, options?: any): AxiosPromise<CountryFormDTO> {
            return localVarFp.countryRead(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Country_SoftDelete 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        countrySoftDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.countrySoftDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Country_Update 
         * @param {number} id 
         * @param {CountryFormDTO} [countryFormDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        countryUpdate(id: number, countryFormDTO?: CountryFormDTO, options?: any): AxiosPromise<CountryFormDTO> {
            return localVarFp.countryUpdate(id, countryFormDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Country_UpdatePartial 
         * @param {number} id 
         * @param {Array<Operation>} [operation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        countryUpdatePartial(id: number, operation?: Array<Operation>, options?: any): AxiosPromise<CountryFormDTO> {
            return localVarFp.countryUpdatePartial(id, operation, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CountryApi - object-oriented interface
 * @export
 * @class CountryApi
 * @extends {BaseAPI}
 */
export class CountryApi extends BaseAPI {
    /**
     * 
     * @summary Country_Create 
     * @param {CountryFormDTO} [countryFormDTO] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CountryApi
     */
    public countryCreate(countryFormDTO?: CountryFormDTO, options?: AxiosRequestConfig) {
        return CountryApiFp(this.configuration).countryCreate(countryFormDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Country_Delete 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CountryApi
     */
    public countryDelete(id: number, options?: AxiosRequestConfig) {
        return CountryApiFp(this.configuration).countryDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Country_DeleteMany 
     * @param {Array<number>} [ids] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CountryApi
     */
    public countryDeleteMany(ids?: Array<number>, options?: AxiosRequestConfig) {
        return CountryApiFp(this.configuration).countryDeleteMany(ids, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Country_GetMany 
     * @param {string} [sortBy] 
     * @param {'asc' | 'desc'} [sortDirection] Values: asc, desc
     * @param {number} [page] 
     * @param {number} [pageSize] 
     * @param {boolean} [skipGlobalFilters] 
     * @param {any} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CountryApi
     */
    public countryGetMany(sortBy?: string, sortDirection?: 'asc' | 'desc', page?: number, pageSize?: number, skipGlobalFilters?: boolean, body?: any, options?: AxiosRequestConfig) {
        return CountryApiFp(this.configuration).countryGetMany(sortBy, sortDirection, page, pageSize, skipGlobalFilters, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Country_Read 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CountryApi
     */
    public countryRead(id: number, options?: AxiosRequestConfig) {
        return CountryApiFp(this.configuration).countryRead(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Country_SoftDelete 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CountryApi
     */
    public countrySoftDelete(id: number, options?: AxiosRequestConfig) {
        return CountryApiFp(this.configuration).countrySoftDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Country_Update 
     * @param {number} id 
     * @param {CountryFormDTO} [countryFormDTO] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CountryApi
     */
    public countryUpdate(id: number, countryFormDTO?: CountryFormDTO, options?: AxiosRequestConfig) {
        return CountryApiFp(this.configuration).countryUpdate(id, countryFormDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Country_UpdatePartial 
     * @param {number} id 
     * @param {Array<Operation>} [operation] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CountryApi
     */
    public countryUpdatePartial(id: number, operation?: Array<Operation>, options?: AxiosRequestConfig) {
        return CountryApiFp(this.configuration).countryUpdatePartial(id, operation, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * EquipmentApi - axios parameter creator
 * @export
 */
export const EquipmentApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Equipment_CloneEquipment  (Auth policies: management-access-policy)
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        equipmentCloneEquipment: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('equipmentCloneEquipment', 'id', id)
            const localVarPath = `/api/v1/application/Equipment/equipment/{id}/clone`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Equipment_Create  (Auth policies: management-access-policy)
         * @param {EquipmentFormDTO} [equipmentFormDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        equipmentCreate: async (equipmentFormDTO?: EquipmentFormDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/application/Equipment`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(equipmentFormDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Equipment_Delete  (Auth policies: management-access-policy)
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        equipmentDelete: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('equipmentDelete', 'id', id)
            const localVarPath = `/api/v1/application/Equipment/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Equipment_DeleteMany  (Auth policies: management-access-policy)
         * @param {Array<number>} [ids] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        equipmentDeleteMany: async (ids?: Array<number>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/application/Equipment`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (ids) {
                localVarQueryParameter['ids'] = ids;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Equipment_GetMany 
         * @param {string} [sortBy] 
         * @param {'asc' | 'desc'} [sortDirection] Values: asc, desc
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {boolean} [skipGlobalFilters] 
         * @param {any} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        equipmentGetMany: async (sortBy?: string, sortDirection?: 'asc' | 'desc', page?: number, pageSize?: number, skipGlobalFilters?: boolean, body?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/application/Equipment/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (sortDirection !== undefined) {
                localVarQueryParameter['sortDirection'] = sortDirection;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (skipGlobalFilters !== undefined) {
                localVarQueryParameter['skipGlobalFilters'] = skipGlobalFilters;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Equipment_IndexAll  (Auth policies: management-access-policy)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        equipmentIndexAll: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/application/Equipment/index-all`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Equipment_Read  (Auth policies: management-access-policy)
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        equipmentRead: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('equipmentRead', 'id', id)
            const localVarPath = `/api/v1/application/Equipment/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Equipment_SoftDelete 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        equipmentSoftDelete: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('equipmentSoftDelete', 'id', id)
            const localVarPath = `/api/v1/application/Equipment/{id}/soft`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Equipment_Update  (Auth policies: management-access-policy)
         * @param {number} id 
         * @param {EquipmentFormDTO} [equipmentFormDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        equipmentUpdate: async (id: number, equipmentFormDTO?: EquipmentFormDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('equipmentUpdate', 'id', id)
            const localVarPath = `/api/v1/application/Equipment/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(equipmentFormDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Equipment_UpdatePartial 
         * @param {number} id 
         * @param {Array<Operation>} [operation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        equipmentUpdatePartial: async (id: number, operation?: Array<Operation>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('equipmentUpdatePartial', 'id', id)
            const localVarPath = `/api/v1/application/Equipment/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(operation, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EquipmentApi - functional programming interface
 * @export
 */
export const EquipmentApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = EquipmentApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Equipment_CloneEquipment  (Auth policies: management-access-policy)
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async equipmentCloneEquipment(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseMessage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.equipmentCloneEquipment(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Equipment_Create  (Auth policies: management-access-policy)
         * @param {EquipmentFormDTO} [equipmentFormDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async equipmentCreate(equipmentFormDTO?: EquipmentFormDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EquipmentFormDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.equipmentCreate(equipmentFormDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Equipment_Delete  (Auth policies: management-access-policy)
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async equipmentDelete(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.equipmentDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Equipment_DeleteMany  (Auth policies: management-access-policy)
         * @param {Array<number>} [ids] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async equipmentDeleteMany(ids?: Array<number>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<number>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.equipmentDeleteMany(ids, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Equipment_GetMany 
         * @param {string} [sortBy] 
         * @param {'asc' | 'desc'} [sortDirection] Values: asc, desc
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {boolean} [skipGlobalFilters] 
         * @param {any} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async equipmentGetMany(sortBy?: string, sortDirection?: 'asc' | 'desc', page?: number, pageSize?: number, skipGlobalFilters?: boolean, body?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagedResponseDtoOfEquipmentListDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.equipmentGetMany(sortBy, sortDirection, page, pageSize, skipGlobalFilters, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Equipment_IndexAll  (Auth policies: management-access-policy)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async equipmentIndexAll(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.equipmentIndexAll(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Equipment_Read  (Auth policies: management-access-policy)
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async equipmentRead(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EquipmentFormDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.equipmentRead(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Equipment_SoftDelete 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async equipmentSoftDelete(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.equipmentSoftDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Equipment_Update  (Auth policies: management-access-policy)
         * @param {number} id 
         * @param {EquipmentFormDTO} [equipmentFormDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async equipmentUpdate(id: number, equipmentFormDTO?: EquipmentFormDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EquipmentFormDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.equipmentUpdate(id, equipmentFormDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Equipment_UpdatePartial 
         * @param {number} id 
         * @param {Array<Operation>} [operation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async equipmentUpdatePartial(id: number, operation?: Array<Operation>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EquipmentFormDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.equipmentUpdatePartial(id, operation, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * EquipmentApi - factory interface
 * @export
 */
export const EquipmentApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = EquipmentApiFp(configuration)
    return {
        /**
         * 
         * @summary Equipment_CloneEquipment  (Auth policies: management-access-policy)
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        equipmentCloneEquipment(id: number, options?: any): AxiosPromise<ResponseMessage> {
            return localVarFp.equipmentCloneEquipment(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Equipment_Create  (Auth policies: management-access-policy)
         * @param {EquipmentFormDTO} [equipmentFormDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        equipmentCreate(equipmentFormDTO?: EquipmentFormDTO, options?: any): AxiosPromise<EquipmentFormDTO> {
            return localVarFp.equipmentCreate(equipmentFormDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Equipment_Delete  (Auth policies: management-access-policy)
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        equipmentDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.equipmentDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Equipment_DeleteMany  (Auth policies: management-access-policy)
         * @param {Array<number>} [ids] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        equipmentDeleteMany(ids?: Array<number>, options?: any): AxiosPromise<Array<number>> {
            return localVarFp.equipmentDeleteMany(ids, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Equipment_GetMany 
         * @param {string} [sortBy] 
         * @param {'asc' | 'desc'} [sortDirection] Values: asc, desc
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {boolean} [skipGlobalFilters] 
         * @param {any} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        equipmentGetMany(sortBy?: string, sortDirection?: 'asc' | 'desc', page?: number, pageSize?: number, skipGlobalFilters?: boolean, body?: any, options?: any): AxiosPromise<PagedResponseDtoOfEquipmentListDTO> {
            return localVarFp.equipmentGetMany(sortBy, sortDirection, page, pageSize, skipGlobalFilters, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Equipment_IndexAll  (Auth policies: management-access-policy)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        equipmentIndexAll(options?: any): AxiosPromise<void> {
            return localVarFp.equipmentIndexAll(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Equipment_Read  (Auth policies: management-access-policy)
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        equipmentRead(id: number, options?: any): AxiosPromise<EquipmentFormDTO> {
            return localVarFp.equipmentRead(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Equipment_SoftDelete 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        equipmentSoftDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.equipmentSoftDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Equipment_Update  (Auth policies: management-access-policy)
         * @param {number} id 
         * @param {EquipmentFormDTO} [equipmentFormDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        equipmentUpdate(id: number, equipmentFormDTO?: EquipmentFormDTO, options?: any): AxiosPromise<EquipmentFormDTO> {
            return localVarFp.equipmentUpdate(id, equipmentFormDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Equipment_UpdatePartial 
         * @param {number} id 
         * @param {Array<Operation>} [operation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        equipmentUpdatePartial(id: number, operation?: Array<Operation>, options?: any): AxiosPromise<EquipmentFormDTO> {
            return localVarFp.equipmentUpdatePartial(id, operation, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * EquipmentApi - object-oriented interface
 * @export
 * @class EquipmentApi
 * @extends {BaseAPI}
 */
export class EquipmentApi extends BaseAPI {
    /**
     * 
     * @summary Equipment_CloneEquipment  (Auth policies: management-access-policy)
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EquipmentApi
     */
    public equipmentCloneEquipment(id: number, options?: AxiosRequestConfig) {
        return EquipmentApiFp(this.configuration).equipmentCloneEquipment(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Equipment_Create  (Auth policies: management-access-policy)
     * @param {EquipmentFormDTO} [equipmentFormDTO] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EquipmentApi
     */
    public equipmentCreate(equipmentFormDTO?: EquipmentFormDTO, options?: AxiosRequestConfig) {
        return EquipmentApiFp(this.configuration).equipmentCreate(equipmentFormDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Equipment_Delete  (Auth policies: management-access-policy)
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EquipmentApi
     */
    public equipmentDelete(id: number, options?: AxiosRequestConfig) {
        return EquipmentApiFp(this.configuration).equipmentDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Equipment_DeleteMany  (Auth policies: management-access-policy)
     * @param {Array<number>} [ids] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EquipmentApi
     */
    public equipmentDeleteMany(ids?: Array<number>, options?: AxiosRequestConfig) {
        return EquipmentApiFp(this.configuration).equipmentDeleteMany(ids, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Equipment_GetMany 
     * @param {string} [sortBy] 
     * @param {'asc' | 'desc'} [sortDirection] Values: asc, desc
     * @param {number} [page] 
     * @param {number} [pageSize] 
     * @param {boolean} [skipGlobalFilters] 
     * @param {any} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EquipmentApi
     */
    public equipmentGetMany(sortBy?: string, sortDirection?: 'asc' | 'desc', page?: number, pageSize?: number, skipGlobalFilters?: boolean, body?: any, options?: AxiosRequestConfig) {
        return EquipmentApiFp(this.configuration).equipmentGetMany(sortBy, sortDirection, page, pageSize, skipGlobalFilters, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Equipment_IndexAll  (Auth policies: management-access-policy)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EquipmentApi
     */
    public equipmentIndexAll(options?: AxiosRequestConfig) {
        return EquipmentApiFp(this.configuration).equipmentIndexAll(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Equipment_Read  (Auth policies: management-access-policy)
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EquipmentApi
     */
    public equipmentRead(id: number, options?: AxiosRequestConfig) {
        return EquipmentApiFp(this.configuration).equipmentRead(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Equipment_SoftDelete 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EquipmentApi
     */
    public equipmentSoftDelete(id: number, options?: AxiosRequestConfig) {
        return EquipmentApiFp(this.configuration).equipmentSoftDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Equipment_Update  (Auth policies: management-access-policy)
     * @param {number} id 
     * @param {EquipmentFormDTO} [equipmentFormDTO] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EquipmentApi
     */
    public equipmentUpdate(id: number, equipmentFormDTO?: EquipmentFormDTO, options?: AxiosRequestConfig) {
        return EquipmentApiFp(this.configuration).equipmentUpdate(id, equipmentFormDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Equipment_UpdatePartial 
     * @param {number} id 
     * @param {Array<Operation>} [operation] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EquipmentApi
     */
    public equipmentUpdatePartial(id: number, operation?: Array<Operation>, options?: AxiosRequestConfig) {
        return EquipmentApiFp(this.configuration).equipmentUpdatePartial(id, operation, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * EquipmentTypeApi - axios parameter creator
 * @export
 */
export const EquipmentTypeApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary EquipmentType_Create  (Auth policies: management-access-policy)
         * @param {EquipmentTypeFormDTO} [equipmentTypeFormDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        equipmentTypeCreate: async (equipmentTypeFormDTO?: EquipmentTypeFormDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/application/EquipmentType`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(equipmentTypeFormDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary EquipmentType_Delete  (Auth policies: management-access-policy)
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        equipmentTypeDelete: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('equipmentTypeDelete', 'id', id)
            const localVarPath = `/api/v1/application/EquipmentType/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary EquipmentType_DeleteMany  (Auth policies: management-access-policy)
         * @param {Array<number>} [ids] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        equipmentTypeDeleteMany: async (ids?: Array<number>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/application/EquipmentType`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (ids) {
                localVarQueryParameter['ids'] = ids;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary EquipmentType_GetJsonSchema  (Auth policies: management-access-policy)
         * @param {number} [equipmentTypeId] 
         * @param {'fullReadWrite' | 'fullReadOnly' | 'mainSearch' | 'advancedSearch'} [schemaType] Values: fullReadWrite, fullReadOnly, mainSearch, advancedSearch
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        equipmentTypeGetJsonSchema: async (equipmentTypeId?: number, schemaType?: 'fullReadWrite' | 'fullReadOnly' | 'mainSearch' | 'advancedSearch', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/application/EquipmentType/jsonschema`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (equipmentTypeId !== undefined) {
                localVarQueryParameter['equipmentTypeId'] = equipmentTypeId;
            }

            if (schemaType !== undefined) {
                localVarQueryParameter['schemaType'] = schemaType;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary EquipmentType_GetMany 
         * @param {string} [sortBy] 
         * @param {'asc' | 'desc'} [sortDirection] Values: asc, desc
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {boolean} [skipGlobalFilters] 
         * @param {any} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        equipmentTypeGetMany: async (sortBy?: string, sortDirection?: 'asc' | 'desc', page?: number, pageSize?: number, skipGlobalFilters?: boolean, body?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/application/EquipmentType/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (sortDirection !== undefined) {
                localVarQueryParameter['sortDirection'] = sortDirection;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (skipGlobalFilters !== undefined) {
                localVarQueryParameter['skipGlobalFilters'] = skipGlobalFilters;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary EquipmentType_Read  (Auth policies: management-access-policy)
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        equipmentTypeRead: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('equipmentTypeRead', 'id', id)
            const localVarPath = `/api/v1/application/EquipmentType/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary EquipmentType_SoftDelete 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        equipmentTypeSoftDelete: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('equipmentTypeSoftDelete', 'id', id)
            const localVarPath = `/api/v1/application/EquipmentType/{id}/soft`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary EquipmentType_Update  (Auth policies: management-access-policy)
         * @param {number} id 
         * @param {EquipmentTypeFormDTO} [equipmentTypeFormDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        equipmentTypeUpdate: async (id: number, equipmentTypeFormDTO?: EquipmentTypeFormDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('equipmentTypeUpdate', 'id', id)
            const localVarPath = `/api/v1/application/EquipmentType/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(equipmentTypeFormDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary EquipmentType_UpdatePartial 
         * @param {number} id 
         * @param {Array<Operation>} [operation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        equipmentTypeUpdatePartial: async (id: number, operation?: Array<Operation>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('equipmentTypeUpdatePartial', 'id', id)
            const localVarPath = `/api/v1/application/EquipmentType/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(operation, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EquipmentTypeApi - functional programming interface
 * @export
 */
export const EquipmentTypeApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = EquipmentTypeApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary EquipmentType_Create  (Auth policies: management-access-policy)
         * @param {EquipmentTypeFormDTO} [equipmentTypeFormDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async equipmentTypeCreate(equipmentTypeFormDTO?: EquipmentTypeFormDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EquipmentTypeFormDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.equipmentTypeCreate(equipmentTypeFormDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary EquipmentType_Delete  (Auth policies: management-access-policy)
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async equipmentTypeDelete(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.equipmentTypeDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary EquipmentType_DeleteMany  (Auth policies: management-access-policy)
         * @param {Array<number>} [ids] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async equipmentTypeDeleteMany(ids?: Array<number>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<number>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.equipmentTypeDeleteMany(ids, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary EquipmentType_GetJsonSchema  (Auth policies: management-access-policy)
         * @param {number} [equipmentTypeId] 
         * @param {'fullReadWrite' | 'fullReadOnly' | 'mainSearch' | 'advancedSearch'} [schemaType] Values: fullReadWrite, fullReadOnly, mainSearch, advancedSearch
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async equipmentTypeGetJsonSchema(equipmentTypeId?: number, schemaType?: 'fullReadWrite' | 'fullReadOnly' | 'mainSearch' | 'advancedSearch', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EquipmentsGetJsonSchemaResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.equipmentTypeGetJsonSchema(equipmentTypeId, schemaType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary EquipmentType_GetMany 
         * @param {string} [sortBy] 
         * @param {'asc' | 'desc'} [sortDirection] Values: asc, desc
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {boolean} [skipGlobalFilters] 
         * @param {any} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async equipmentTypeGetMany(sortBy?: string, sortDirection?: 'asc' | 'desc', page?: number, pageSize?: number, skipGlobalFilters?: boolean, body?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagedResponseDtoOfEquipmentTypeListDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.equipmentTypeGetMany(sortBy, sortDirection, page, pageSize, skipGlobalFilters, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary EquipmentType_Read  (Auth policies: management-access-policy)
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async equipmentTypeRead(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EquipmentTypeFormDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.equipmentTypeRead(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary EquipmentType_SoftDelete 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async equipmentTypeSoftDelete(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.equipmentTypeSoftDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary EquipmentType_Update  (Auth policies: management-access-policy)
         * @param {number} id 
         * @param {EquipmentTypeFormDTO} [equipmentTypeFormDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async equipmentTypeUpdate(id: number, equipmentTypeFormDTO?: EquipmentTypeFormDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EquipmentTypeFormDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.equipmentTypeUpdate(id, equipmentTypeFormDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary EquipmentType_UpdatePartial 
         * @param {number} id 
         * @param {Array<Operation>} [operation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async equipmentTypeUpdatePartial(id: number, operation?: Array<Operation>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EquipmentTypeFormDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.equipmentTypeUpdatePartial(id, operation, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * EquipmentTypeApi - factory interface
 * @export
 */
export const EquipmentTypeApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = EquipmentTypeApiFp(configuration)
    return {
        /**
         * 
         * @summary EquipmentType_Create  (Auth policies: management-access-policy)
         * @param {EquipmentTypeFormDTO} [equipmentTypeFormDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        equipmentTypeCreate(equipmentTypeFormDTO?: EquipmentTypeFormDTO, options?: any): AxiosPromise<EquipmentTypeFormDTO> {
            return localVarFp.equipmentTypeCreate(equipmentTypeFormDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary EquipmentType_Delete  (Auth policies: management-access-policy)
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        equipmentTypeDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.equipmentTypeDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary EquipmentType_DeleteMany  (Auth policies: management-access-policy)
         * @param {Array<number>} [ids] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        equipmentTypeDeleteMany(ids?: Array<number>, options?: any): AxiosPromise<Array<number>> {
            return localVarFp.equipmentTypeDeleteMany(ids, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary EquipmentType_GetJsonSchema  (Auth policies: management-access-policy)
         * @param {number} [equipmentTypeId] 
         * @param {'fullReadWrite' | 'fullReadOnly' | 'mainSearch' | 'advancedSearch'} [schemaType] Values: fullReadWrite, fullReadOnly, mainSearch, advancedSearch
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        equipmentTypeGetJsonSchema(equipmentTypeId?: number, schemaType?: 'fullReadWrite' | 'fullReadOnly' | 'mainSearch' | 'advancedSearch', options?: any): AxiosPromise<EquipmentsGetJsonSchemaResponse> {
            return localVarFp.equipmentTypeGetJsonSchema(equipmentTypeId, schemaType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary EquipmentType_GetMany 
         * @param {string} [sortBy] 
         * @param {'asc' | 'desc'} [sortDirection] Values: asc, desc
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {boolean} [skipGlobalFilters] 
         * @param {any} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        equipmentTypeGetMany(sortBy?: string, sortDirection?: 'asc' | 'desc', page?: number, pageSize?: number, skipGlobalFilters?: boolean, body?: any, options?: any): AxiosPromise<PagedResponseDtoOfEquipmentTypeListDTO> {
            return localVarFp.equipmentTypeGetMany(sortBy, sortDirection, page, pageSize, skipGlobalFilters, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary EquipmentType_Read  (Auth policies: management-access-policy)
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        equipmentTypeRead(id: number, options?: any): AxiosPromise<EquipmentTypeFormDTO> {
            return localVarFp.equipmentTypeRead(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary EquipmentType_SoftDelete 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        equipmentTypeSoftDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.equipmentTypeSoftDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary EquipmentType_Update  (Auth policies: management-access-policy)
         * @param {number} id 
         * @param {EquipmentTypeFormDTO} [equipmentTypeFormDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        equipmentTypeUpdate(id: number, equipmentTypeFormDTO?: EquipmentTypeFormDTO, options?: any): AxiosPromise<EquipmentTypeFormDTO> {
            return localVarFp.equipmentTypeUpdate(id, equipmentTypeFormDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary EquipmentType_UpdatePartial 
         * @param {number} id 
         * @param {Array<Operation>} [operation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        equipmentTypeUpdatePartial(id: number, operation?: Array<Operation>, options?: any): AxiosPromise<EquipmentTypeFormDTO> {
            return localVarFp.equipmentTypeUpdatePartial(id, operation, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * EquipmentTypeApi - object-oriented interface
 * @export
 * @class EquipmentTypeApi
 * @extends {BaseAPI}
 */
export class EquipmentTypeApi extends BaseAPI {
    /**
     * 
     * @summary EquipmentType_Create  (Auth policies: management-access-policy)
     * @param {EquipmentTypeFormDTO} [equipmentTypeFormDTO] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EquipmentTypeApi
     */
    public equipmentTypeCreate(equipmentTypeFormDTO?: EquipmentTypeFormDTO, options?: AxiosRequestConfig) {
        return EquipmentTypeApiFp(this.configuration).equipmentTypeCreate(equipmentTypeFormDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary EquipmentType_Delete  (Auth policies: management-access-policy)
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EquipmentTypeApi
     */
    public equipmentTypeDelete(id: number, options?: AxiosRequestConfig) {
        return EquipmentTypeApiFp(this.configuration).equipmentTypeDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary EquipmentType_DeleteMany  (Auth policies: management-access-policy)
     * @param {Array<number>} [ids] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EquipmentTypeApi
     */
    public equipmentTypeDeleteMany(ids?: Array<number>, options?: AxiosRequestConfig) {
        return EquipmentTypeApiFp(this.configuration).equipmentTypeDeleteMany(ids, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary EquipmentType_GetJsonSchema  (Auth policies: management-access-policy)
     * @param {number} [equipmentTypeId] 
     * @param {'fullReadWrite' | 'fullReadOnly' | 'mainSearch' | 'advancedSearch'} [schemaType] Values: fullReadWrite, fullReadOnly, mainSearch, advancedSearch
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EquipmentTypeApi
     */
    public equipmentTypeGetJsonSchema(equipmentTypeId?: number, schemaType?: 'fullReadWrite' | 'fullReadOnly' | 'mainSearch' | 'advancedSearch', options?: AxiosRequestConfig) {
        return EquipmentTypeApiFp(this.configuration).equipmentTypeGetJsonSchema(equipmentTypeId, schemaType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary EquipmentType_GetMany 
     * @param {string} [sortBy] 
     * @param {'asc' | 'desc'} [sortDirection] Values: asc, desc
     * @param {number} [page] 
     * @param {number} [pageSize] 
     * @param {boolean} [skipGlobalFilters] 
     * @param {any} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EquipmentTypeApi
     */
    public equipmentTypeGetMany(sortBy?: string, sortDirection?: 'asc' | 'desc', page?: number, pageSize?: number, skipGlobalFilters?: boolean, body?: any, options?: AxiosRequestConfig) {
        return EquipmentTypeApiFp(this.configuration).equipmentTypeGetMany(sortBy, sortDirection, page, pageSize, skipGlobalFilters, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary EquipmentType_Read  (Auth policies: management-access-policy)
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EquipmentTypeApi
     */
    public equipmentTypeRead(id: number, options?: AxiosRequestConfig) {
        return EquipmentTypeApiFp(this.configuration).equipmentTypeRead(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary EquipmentType_SoftDelete 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EquipmentTypeApi
     */
    public equipmentTypeSoftDelete(id: number, options?: AxiosRequestConfig) {
        return EquipmentTypeApiFp(this.configuration).equipmentTypeSoftDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary EquipmentType_Update  (Auth policies: management-access-policy)
     * @param {number} id 
     * @param {EquipmentTypeFormDTO} [equipmentTypeFormDTO] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EquipmentTypeApi
     */
    public equipmentTypeUpdate(id: number, equipmentTypeFormDTO?: EquipmentTypeFormDTO, options?: AxiosRequestConfig) {
        return EquipmentTypeApiFp(this.configuration).equipmentTypeUpdate(id, equipmentTypeFormDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary EquipmentType_UpdatePartial 
     * @param {number} id 
     * @param {Array<Operation>} [operation] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EquipmentTypeApi
     */
    public equipmentTypeUpdatePartial(id: number, operation?: Array<Operation>, options?: AxiosRequestConfig) {
        return EquipmentTypeApiFp(this.configuration).equipmentTypeUpdatePartial(id, operation, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * FieldApi - axios parameter creator
 * @export
 */
export const FieldApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Field_Create 
         * @param {FieldFormDTO} [fieldFormDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fieldCreate: async (fieldFormDTO?: FieldFormDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/application/Field`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(fieldFormDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Field_Delete 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fieldDelete: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('fieldDelete', 'id', id)
            const localVarPath = `/api/v1/application/Field/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Field_DeleteMany 
         * @param {Array<number>} [ids] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fieldDeleteMany: async (ids?: Array<number>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/application/Field`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (ids) {
                localVarQueryParameter['ids'] = ids;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Field_GetMany 
         * @param {string} [sortBy] 
         * @param {'asc' | 'desc'} [sortDirection] Values: asc, desc
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {boolean} [skipGlobalFilters] 
         * @param {any} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fieldGetMany: async (sortBy?: string, sortDirection?: 'asc' | 'desc', page?: number, pageSize?: number, skipGlobalFilters?: boolean, body?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/application/Field/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (sortDirection !== undefined) {
                localVarQueryParameter['sortDirection'] = sortDirection;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (skipGlobalFilters !== undefined) {
                localVarQueryParameter['skipGlobalFilters'] = skipGlobalFilters;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Field_Read 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fieldRead: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('fieldRead', 'id', id)
            const localVarPath = `/api/v1/application/Field/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Field_SoftDelete 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fieldSoftDelete: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('fieldSoftDelete', 'id', id)
            const localVarPath = `/api/v1/application/Field/{id}/soft`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Field_Update 
         * @param {number} id 
         * @param {FieldFormDTO} [fieldFormDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fieldUpdate: async (id: number, fieldFormDTO?: FieldFormDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('fieldUpdate', 'id', id)
            const localVarPath = `/api/v1/application/Field/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(fieldFormDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Field_UpdatePartial 
         * @param {number} id 
         * @param {Array<Operation>} [operation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fieldUpdatePartial: async (id: number, operation?: Array<Operation>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('fieldUpdatePartial', 'id', id)
            const localVarPath = `/api/v1/application/Field/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(operation, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FieldApi - functional programming interface
 * @export
 */
export const FieldApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FieldApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Field_Create 
         * @param {FieldFormDTO} [fieldFormDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fieldCreate(fieldFormDTO?: FieldFormDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FieldFormDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fieldCreate(fieldFormDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Field_Delete 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fieldDelete(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fieldDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Field_DeleteMany 
         * @param {Array<number>} [ids] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fieldDeleteMany(ids?: Array<number>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<number>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fieldDeleteMany(ids, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Field_GetMany 
         * @param {string} [sortBy] 
         * @param {'asc' | 'desc'} [sortDirection] Values: asc, desc
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {boolean} [skipGlobalFilters] 
         * @param {any} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fieldGetMany(sortBy?: string, sortDirection?: 'asc' | 'desc', page?: number, pageSize?: number, skipGlobalFilters?: boolean, body?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagedResponseDtoOfFieldListDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fieldGetMany(sortBy, sortDirection, page, pageSize, skipGlobalFilters, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Field_Read 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fieldRead(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FieldFormDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fieldRead(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Field_SoftDelete 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fieldSoftDelete(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fieldSoftDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Field_Update 
         * @param {number} id 
         * @param {FieldFormDTO} [fieldFormDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fieldUpdate(id: number, fieldFormDTO?: FieldFormDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FieldFormDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fieldUpdate(id, fieldFormDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Field_UpdatePartial 
         * @param {number} id 
         * @param {Array<Operation>} [operation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fieldUpdatePartial(id: number, operation?: Array<Operation>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FieldFormDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fieldUpdatePartial(id, operation, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FieldApi - factory interface
 * @export
 */
export const FieldApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FieldApiFp(configuration)
    return {
        /**
         * 
         * @summary Field_Create 
         * @param {FieldFormDTO} [fieldFormDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fieldCreate(fieldFormDTO?: FieldFormDTO, options?: any): AxiosPromise<FieldFormDTO> {
            return localVarFp.fieldCreate(fieldFormDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Field_Delete 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fieldDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.fieldDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Field_DeleteMany 
         * @param {Array<number>} [ids] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fieldDeleteMany(ids?: Array<number>, options?: any): AxiosPromise<Array<number>> {
            return localVarFp.fieldDeleteMany(ids, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Field_GetMany 
         * @param {string} [sortBy] 
         * @param {'asc' | 'desc'} [sortDirection] Values: asc, desc
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {boolean} [skipGlobalFilters] 
         * @param {any} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fieldGetMany(sortBy?: string, sortDirection?: 'asc' | 'desc', page?: number, pageSize?: number, skipGlobalFilters?: boolean, body?: any, options?: any): AxiosPromise<PagedResponseDtoOfFieldListDTO> {
            return localVarFp.fieldGetMany(sortBy, sortDirection, page, pageSize, skipGlobalFilters, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Field_Read 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fieldRead(id: number, options?: any): AxiosPromise<FieldFormDTO> {
            return localVarFp.fieldRead(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Field_SoftDelete 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fieldSoftDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.fieldSoftDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Field_Update 
         * @param {number} id 
         * @param {FieldFormDTO} [fieldFormDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fieldUpdate(id: number, fieldFormDTO?: FieldFormDTO, options?: any): AxiosPromise<FieldFormDTO> {
            return localVarFp.fieldUpdate(id, fieldFormDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Field_UpdatePartial 
         * @param {number} id 
         * @param {Array<Operation>} [operation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fieldUpdatePartial(id: number, operation?: Array<Operation>, options?: any): AxiosPromise<FieldFormDTO> {
            return localVarFp.fieldUpdatePartial(id, operation, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FieldApi - object-oriented interface
 * @export
 * @class FieldApi
 * @extends {BaseAPI}
 */
export class FieldApi extends BaseAPI {
    /**
     * 
     * @summary Field_Create 
     * @param {FieldFormDTO} [fieldFormDTO] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FieldApi
     */
    public fieldCreate(fieldFormDTO?: FieldFormDTO, options?: AxiosRequestConfig) {
        return FieldApiFp(this.configuration).fieldCreate(fieldFormDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Field_Delete 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FieldApi
     */
    public fieldDelete(id: number, options?: AxiosRequestConfig) {
        return FieldApiFp(this.configuration).fieldDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Field_DeleteMany 
     * @param {Array<number>} [ids] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FieldApi
     */
    public fieldDeleteMany(ids?: Array<number>, options?: AxiosRequestConfig) {
        return FieldApiFp(this.configuration).fieldDeleteMany(ids, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Field_GetMany 
     * @param {string} [sortBy] 
     * @param {'asc' | 'desc'} [sortDirection] Values: asc, desc
     * @param {number} [page] 
     * @param {number} [pageSize] 
     * @param {boolean} [skipGlobalFilters] 
     * @param {any} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FieldApi
     */
    public fieldGetMany(sortBy?: string, sortDirection?: 'asc' | 'desc', page?: number, pageSize?: number, skipGlobalFilters?: boolean, body?: any, options?: AxiosRequestConfig) {
        return FieldApiFp(this.configuration).fieldGetMany(sortBy, sortDirection, page, pageSize, skipGlobalFilters, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Field_Read 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FieldApi
     */
    public fieldRead(id: number, options?: AxiosRequestConfig) {
        return FieldApiFp(this.configuration).fieldRead(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Field_SoftDelete 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FieldApi
     */
    public fieldSoftDelete(id: number, options?: AxiosRequestConfig) {
        return FieldApiFp(this.configuration).fieldSoftDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Field_Update 
     * @param {number} id 
     * @param {FieldFormDTO} [fieldFormDTO] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FieldApi
     */
    public fieldUpdate(id: number, fieldFormDTO?: FieldFormDTO, options?: AxiosRequestConfig) {
        return FieldApiFp(this.configuration).fieldUpdate(id, fieldFormDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Field_UpdatePartial 
     * @param {number} id 
     * @param {Array<Operation>} [operation] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FieldApi
     */
    public fieldUpdatePartial(id: number, operation?: Array<Operation>, options?: AxiosRequestConfig) {
        return FieldApiFp(this.configuration).fieldUpdatePartial(id, operation, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * HelloApi - axios parameter creator
 * @export
 */
export const HelloApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * API smoke test
         * @summary Hello_Greet Hello world endpoint
         * @param {UtilsHelloWorldQuery} [utilsHelloWorldQuery] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        helloGreet: async (utilsHelloWorldQuery?: UtilsHelloWorldQuery, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/application/Hello/greeting`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(utilsHelloWorldQuery, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * HelloApi - functional programming interface
 * @export
 */
export const HelloApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = HelloApiAxiosParamCreator(configuration)
    return {
        /**
         * API smoke test
         * @summary Hello_Greet Hello world endpoint
         * @param {UtilsHelloWorldQuery} [utilsHelloWorldQuery] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async helloGreet(utilsHelloWorldQuery?: UtilsHelloWorldQuery, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UtilsHelloWorldResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.helloGreet(utilsHelloWorldQuery, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * HelloApi - factory interface
 * @export
 */
export const HelloApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = HelloApiFp(configuration)
    return {
        /**
         * API smoke test
         * @summary Hello_Greet Hello world endpoint
         * @param {UtilsHelloWorldQuery} [utilsHelloWorldQuery] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        helloGreet(utilsHelloWorldQuery?: UtilsHelloWorldQuery, options?: any): AxiosPromise<UtilsHelloWorldResponse> {
            return localVarFp.helloGreet(utilsHelloWorldQuery, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * HelloApi - object-oriented interface
 * @export
 * @class HelloApi
 * @extends {BaseAPI}
 */
export class HelloApi extends BaseAPI {
    /**
     * API smoke test
     * @summary Hello_Greet Hello world endpoint
     * @param {UtilsHelloWorldQuery} [utilsHelloWorldQuery] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HelloApi
     */
    public helloGreet(utilsHelloWorldQuery?: UtilsHelloWorldQuery, options?: AxiosRequestConfig) {
        return HelloApiFp(this.configuration).helloGreet(utilsHelloWorldQuery, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * OptionApi - axios parameter creator
 * @export
 */
export const OptionApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Option_Create 
         * @param {OptionFormDTO} [optionFormDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        optionCreate: async (optionFormDTO?: OptionFormDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/application/Option`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(optionFormDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Option_Delete 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        optionDelete: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('optionDelete', 'id', id)
            const localVarPath = `/api/v1/application/Option/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Option_DeleteMany 
         * @param {Array<number>} [ids] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        optionDeleteMany: async (ids?: Array<number>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/application/Option`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (ids) {
                localVarQueryParameter['ids'] = ids;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Option_GetMany 
         * @param {string} [sortBy] 
         * @param {'asc' | 'desc'} [sortDirection] Values: asc, desc
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {boolean} [skipGlobalFilters] 
         * @param {any} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        optionGetMany: async (sortBy?: string, sortDirection?: 'asc' | 'desc', page?: number, pageSize?: number, skipGlobalFilters?: boolean, body?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/application/Option/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (sortDirection !== undefined) {
                localVarQueryParameter['sortDirection'] = sortDirection;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (skipGlobalFilters !== undefined) {
                localVarQueryParameter['skipGlobalFilters'] = skipGlobalFilters;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Option_Read 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        optionRead: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('optionRead', 'id', id)
            const localVarPath = `/api/v1/application/Option/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Option_SoftDelete 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        optionSoftDelete: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('optionSoftDelete', 'id', id)
            const localVarPath = `/api/v1/application/Option/{id}/soft`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Option_Update 
         * @param {number} id 
         * @param {OptionFormDTO} [optionFormDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        optionUpdate: async (id: number, optionFormDTO?: OptionFormDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('optionUpdate', 'id', id)
            const localVarPath = `/api/v1/application/Option/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(optionFormDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Option_UpdatePartial 
         * @param {number} id 
         * @param {Array<Operation>} [operation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        optionUpdatePartial: async (id: number, operation?: Array<Operation>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('optionUpdatePartial', 'id', id)
            const localVarPath = `/api/v1/application/Option/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(operation, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OptionApi - functional programming interface
 * @export
 */
export const OptionApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OptionApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Option_Create 
         * @param {OptionFormDTO} [optionFormDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async optionCreate(optionFormDTO?: OptionFormDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OptionFormDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.optionCreate(optionFormDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Option_Delete 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async optionDelete(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.optionDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Option_DeleteMany 
         * @param {Array<number>} [ids] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async optionDeleteMany(ids?: Array<number>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<number>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.optionDeleteMany(ids, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Option_GetMany 
         * @param {string} [sortBy] 
         * @param {'asc' | 'desc'} [sortDirection] Values: asc, desc
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {boolean} [skipGlobalFilters] 
         * @param {any} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async optionGetMany(sortBy?: string, sortDirection?: 'asc' | 'desc', page?: number, pageSize?: number, skipGlobalFilters?: boolean, body?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagedResponseDtoOfOptionListDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.optionGetMany(sortBy, sortDirection, page, pageSize, skipGlobalFilters, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Option_Read 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async optionRead(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OptionFormDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.optionRead(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Option_SoftDelete 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async optionSoftDelete(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.optionSoftDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Option_Update 
         * @param {number} id 
         * @param {OptionFormDTO} [optionFormDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async optionUpdate(id: number, optionFormDTO?: OptionFormDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OptionFormDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.optionUpdate(id, optionFormDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Option_UpdatePartial 
         * @param {number} id 
         * @param {Array<Operation>} [operation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async optionUpdatePartial(id: number, operation?: Array<Operation>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OptionFormDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.optionUpdatePartial(id, operation, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OptionApi - factory interface
 * @export
 */
export const OptionApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OptionApiFp(configuration)
    return {
        /**
         * 
         * @summary Option_Create 
         * @param {OptionFormDTO} [optionFormDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        optionCreate(optionFormDTO?: OptionFormDTO, options?: any): AxiosPromise<OptionFormDTO> {
            return localVarFp.optionCreate(optionFormDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Option_Delete 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        optionDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.optionDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Option_DeleteMany 
         * @param {Array<number>} [ids] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        optionDeleteMany(ids?: Array<number>, options?: any): AxiosPromise<Array<number>> {
            return localVarFp.optionDeleteMany(ids, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Option_GetMany 
         * @param {string} [sortBy] 
         * @param {'asc' | 'desc'} [sortDirection] Values: asc, desc
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {boolean} [skipGlobalFilters] 
         * @param {any} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        optionGetMany(sortBy?: string, sortDirection?: 'asc' | 'desc', page?: number, pageSize?: number, skipGlobalFilters?: boolean, body?: any, options?: any): AxiosPromise<PagedResponseDtoOfOptionListDTO> {
            return localVarFp.optionGetMany(sortBy, sortDirection, page, pageSize, skipGlobalFilters, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Option_Read 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        optionRead(id: number, options?: any): AxiosPromise<OptionFormDTO> {
            return localVarFp.optionRead(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Option_SoftDelete 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        optionSoftDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.optionSoftDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Option_Update 
         * @param {number} id 
         * @param {OptionFormDTO} [optionFormDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        optionUpdate(id: number, optionFormDTO?: OptionFormDTO, options?: any): AxiosPromise<OptionFormDTO> {
            return localVarFp.optionUpdate(id, optionFormDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Option_UpdatePartial 
         * @param {number} id 
         * @param {Array<Operation>} [operation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        optionUpdatePartial(id: number, operation?: Array<Operation>, options?: any): AxiosPromise<OptionFormDTO> {
            return localVarFp.optionUpdatePartial(id, operation, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OptionApi - object-oriented interface
 * @export
 * @class OptionApi
 * @extends {BaseAPI}
 */
export class OptionApi extends BaseAPI {
    /**
     * 
     * @summary Option_Create 
     * @param {OptionFormDTO} [optionFormDTO] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OptionApi
     */
    public optionCreate(optionFormDTO?: OptionFormDTO, options?: AxiosRequestConfig) {
        return OptionApiFp(this.configuration).optionCreate(optionFormDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Option_Delete 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OptionApi
     */
    public optionDelete(id: number, options?: AxiosRequestConfig) {
        return OptionApiFp(this.configuration).optionDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Option_DeleteMany 
     * @param {Array<number>} [ids] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OptionApi
     */
    public optionDeleteMany(ids?: Array<number>, options?: AxiosRequestConfig) {
        return OptionApiFp(this.configuration).optionDeleteMany(ids, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Option_GetMany 
     * @param {string} [sortBy] 
     * @param {'asc' | 'desc'} [sortDirection] Values: asc, desc
     * @param {number} [page] 
     * @param {number} [pageSize] 
     * @param {boolean} [skipGlobalFilters] 
     * @param {any} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OptionApi
     */
    public optionGetMany(sortBy?: string, sortDirection?: 'asc' | 'desc', page?: number, pageSize?: number, skipGlobalFilters?: boolean, body?: any, options?: AxiosRequestConfig) {
        return OptionApiFp(this.configuration).optionGetMany(sortBy, sortDirection, page, pageSize, skipGlobalFilters, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Option_Read 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OptionApi
     */
    public optionRead(id: number, options?: AxiosRequestConfig) {
        return OptionApiFp(this.configuration).optionRead(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Option_SoftDelete 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OptionApi
     */
    public optionSoftDelete(id: number, options?: AxiosRequestConfig) {
        return OptionApiFp(this.configuration).optionSoftDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Option_Update 
     * @param {number} id 
     * @param {OptionFormDTO} [optionFormDTO] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OptionApi
     */
    public optionUpdate(id: number, optionFormDTO?: OptionFormDTO, options?: AxiosRequestConfig) {
        return OptionApiFp(this.configuration).optionUpdate(id, optionFormDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Option_UpdatePartial 
     * @param {number} id 
     * @param {Array<Operation>} [operation] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OptionApi
     */
    public optionUpdatePartial(id: number, operation?: Array<Operation>, options?: AxiosRequestConfig) {
        return OptionApiFp(this.configuration).optionUpdatePartial(id, operation, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * OptionSetApi - axios parameter creator
 * @export
 */
export const OptionSetApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary OptionSet_Create 
         * @param {OptionSetFormDTO} [optionSetFormDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        optionSetCreate: async (optionSetFormDTO?: OptionSetFormDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/application/OptionSet`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(optionSetFormDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary OptionSet_Delete 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        optionSetDelete: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('optionSetDelete', 'id', id)
            const localVarPath = `/api/v1/application/OptionSet/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary OptionSet_DeleteMany 
         * @param {Array<number>} [ids] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        optionSetDeleteMany: async (ids?: Array<number>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/application/OptionSet`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (ids) {
                localVarQueryParameter['ids'] = ids;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary OptionSet_GetMany 
         * @param {string} [sortBy] 
         * @param {'asc' | 'desc'} [sortDirection] Values: asc, desc
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {boolean} [skipGlobalFilters] 
         * @param {any} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        optionSetGetMany: async (sortBy?: string, sortDirection?: 'asc' | 'desc', page?: number, pageSize?: number, skipGlobalFilters?: boolean, body?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/application/OptionSet/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (sortDirection !== undefined) {
                localVarQueryParameter['sortDirection'] = sortDirection;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (skipGlobalFilters !== undefined) {
                localVarQueryParameter['skipGlobalFilters'] = skipGlobalFilters;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary OptionSet_Read 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        optionSetRead: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('optionSetRead', 'id', id)
            const localVarPath = `/api/v1/application/OptionSet/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary OptionSet_SoftDelete 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        optionSetSoftDelete: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('optionSetSoftDelete', 'id', id)
            const localVarPath = `/api/v1/application/OptionSet/{id}/soft`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary OptionSet_Update 
         * @param {number} id 
         * @param {OptionSetFormDTO} [optionSetFormDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        optionSetUpdate: async (id: number, optionSetFormDTO?: OptionSetFormDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('optionSetUpdate', 'id', id)
            const localVarPath = `/api/v1/application/OptionSet/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(optionSetFormDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary OptionSet_UpdatePartial 
         * @param {number} id 
         * @param {Array<Operation>} [operation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        optionSetUpdatePartial: async (id: number, operation?: Array<Operation>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('optionSetUpdatePartial', 'id', id)
            const localVarPath = `/api/v1/application/OptionSet/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(operation, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OptionSetApi - functional programming interface
 * @export
 */
export const OptionSetApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OptionSetApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary OptionSet_Create 
         * @param {OptionSetFormDTO} [optionSetFormDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async optionSetCreate(optionSetFormDTO?: OptionSetFormDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OptionSetFormDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.optionSetCreate(optionSetFormDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary OptionSet_Delete 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async optionSetDelete(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.optionSetDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary OptionSet_DeleteMany 
         * @param {Array<number>} [ids] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async optionSetDeleteMany(ids?: Array<number>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<number>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.optionSetDeleteMany(ids, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary OptionSet_GetMany 
         * @param {string} [sortBy] 
         * @param {'asc' | 'desc'} [sortDirection] Values: asc, desc
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {boolean} [skipGlobalFilters] 
         * @param {any} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async optionSetGetMany(sortBy?: string, sortDirection?: 'asc' | 'desc', page?: number, pageSize?: number, skipGlobalFilters?: boolean, body?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagedResponseDtoOfOptionSetListDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.optionSetGetMany(sortBy, sortDirection, page, pageSize, skipGlobalFilters, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary OptionSet_Read 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async optionSetRead(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OptionSetFormDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.optionSetRead(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary OptionSet_SoftDelete 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async optionSetSoftDelete(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.optionSetSoftDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary OptionSet_Update 
         * @param {number} id 
         * @param {OptionSetFormDTO} [optionSetFormDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async optionSetUpdate(id: number, optionSetFormDTO?: OptionSetFormDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OptionSetFormDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.optionSetUpdate(id, optionSetFormDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary OptionSet_UpdatePartial 
         * @param {number} id 
         * @param {Array<Operation>} [operation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async optionSetUpdatePartial(id: number, operation?: Array<Operation>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OptionSetFormDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.optionSetUpdatePartial(id, operation, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OptionSetApi - factory interface
 * @export
 */
export const OptionSetApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OptionSetApiFp(configuration)
    return {
        /**
         * 
         * @summary OptionSet_Create 
         * @param {OptionSetFormDTO} [optionSetFormDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        optionSetCreate(optionSetFormDTO?: OptionSetFormDTO, options?: any): AxiosPromise<OptionSetFormDTO> {
            return localVarFp.optionSetCreate(optionSetFormDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary OptionSet_Delete 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        optionSetDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.optionSetDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary OptionSet_DeleteMany 
         * @param {Array<number>} [ids] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        optionSetDeleteMany(ids?: Array<number>, options?: any): AxiosPromise<Array<number>> {
            return localVarFp.optionSetDeleteMany(ids, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary OptionSet_GetMany 
         * @param {string} [sortBy] 
         * @param {'asc' | 'desc'} [sortDirection] Values: asc, desc
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {boolean} [skipGlobalFilters] 
         * @param {any} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        optionSetGetMany(sortBy?: string, sortDirection?: 'asc' | 'desc', page?: number, pageSize?: number, skipGlobalFilters?: boolean, body?: any, options?: any): AxiosPromise<PagedResponseDtoOfOptionSetListDTO> {
            return localVarFp.optionSetGetMany(sortBy, sortDirection, page, pageSize, skipGlobalFilters, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary OptionSet_Read 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        optionSetRead(id: number, options?: any): AxiosPromise<OptionSetFormDTO> {
            return localVarFp.optionSetRead(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary OptionSet_SoftDelete 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        optionSetSoftDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.optionSetSoftDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary OptionSet_Update 
         * @param {number} id 
         * @param {OptionSetFormDTO} [optionSetFormDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        optionSetUpdate(id: number, optionSetFormDTO?: OptionSetFormDTO, options?: any): AxiosPromise<OptionSetFormDTO> {
            return localVarFp.optionSetUpdate(id, optionSetFormDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary OptionSet_UpdatePartial 
         * @param {number} id 
         * @param {Array<Operation>} [operation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        optionSetUpdatePartial(id: number, operation?: Array<Operation>, options?: any): AxiosPromise<OptionSetFormDTO> {
            return localVarFp.optionSetUpdatePartial(id, operation, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OptionSetApi - object-oriented interface
 * @export
 * @class OptionSetApi
 * @extends {BaseAPI}
 */
export class OptionSetApi extends BaseAPI {
    /**
     * 
     * @summary OptionSet_Create 
     * @param {OptionSetFormDTO} [optionSetFormDTO] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OptionSetApi
     */
    public optionSetCreate(optionSetFormDTO?: OptionSetFormDTO, options?: AxiosRequestConfig) {
        return OptionSetApiFp(this.configuration).optionSetCreate(optionSetFormDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary OptionSet_Delete 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OptionSetApi
     */
    public optionSetDelete(id: number, options?: AxiosRequestConfig) {
        return OptionSetApiFp(this.configuration).optionSetDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary OptionSet_DeleteMany 
     * @param {Array<number>} [ids] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OptionSetApi
     */
    public optionSetDeleteMany(ids?: Array<number>, options?: AxiosRequestConfig) {
        return OptionSetApiFp(this.configuration).optionSetDeleteMany(ids, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary OptionSet_GetMany 
     * @param {string} [sortBy] 
     * @param {'asc' | 'desc'} [sortDirection] Values: asc, desc
     * @param {number} [page] 
     * @param {number} [pageSize] 
     * @param {boolean} [skipGlobalFilters] 
     * @param {any} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OptionSetApi
     */
    public optionSetGetMany(sortBy?: string, sortDirection?: 'asc' | 'desc', page?: number, pageSize?: number, skipGlobalFilters?: boolean, body?: any, options?: AxiosRequestConfig) {
        return OptionSetApiFp(this.configuration).optionSetGetMany(sortBy, sortDirection, page, pageSize, skipGlobalFilters, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary OptionSet_Read 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OptionSetApi
     */
    public optionSetRead(id: number, options?: AxiosRequestConfig) {
        return OptionSetApiFp(this.configuration).optionSetRead(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary OptionSet_SoftDelete 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OptionSetApi
     */
    public optionSetSoftDelete(id: number, options?: AxiosRequestConfig) {
        return OptionSetApiFp(this.configuration).optionSetSoftDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary OptionSet_Update 
     * @param {number} id 
     * @param {OptionSetFormDTO} [optionSetFormDTO] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OptionSetApi
     */
    public optionSetUpdate(id: number, optionSetFormDTO?: OptionSetFormDTO, options?: AxiosRequestConfig) {
        return OptionSetApiFp(this.configuration).optionSetUpdate(id, optionSetFormDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary OptionSet_UpdatePartial 
     * @param {number} id 
     * @param {Array<Operation>} [operation] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OptionSetApi
     */
    public optionSetUpdatePartial(id: number, operation?: Array<Operation>, options?: AxiosRequestConfig) {
        return OptionSetApiFp(this.configuration).optionSetUpdatePartial(id, operation, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PolicyApi - axios parameter creator
 * @export
 */
export const PolicyApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Policy_Create 
         * @param {PolicyFormDTO} [policyFormDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        policyCreate: async (policyFormDTO?: PolicyFormDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/application/Policy`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(policyFormDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Policy_Delete 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        policyDelete: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('policyDelete', 'id', id)
            const localVarPath = `/api/v1/application/Policy/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Policy_DeleteMany 
         * @param {Array<number>} [ids] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        policyDeleteMany: async (ids?: Array<number>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/application/Policy`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (ids) {
                localVarQueryParameter['ids'] = ids;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Policy_GetMany 
         * @param {string} [sortBy] 
         * @param {'asc' | 'desc'} [sortDirection] Values: asc, desc
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {boolean} [skipGlobalFilters] 
         * @param {any} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        policyGetMany: async (sortBy?: string, sortDirection?: 'asc' | 'desc', page?: number, pageSize?: number, skipGlobalFilters?: boolean, body?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/application/Policy/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (sortDirection !== undefined) {
                localVarQueryParameter['sortDirection'] = sortDirection;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (skipGlobalFilters !== undefined) {
                localVarQueryParameter['skipGlobalFilters'] = skipGlobalFilters;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Policy_Read 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        policyRead: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('policyRead', 'id', id)
            const localVarPath = `/api/v1/application/Policy/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Policy_SoftDelete 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        policySoftDelete: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('policySoftDelete', 'id', id)
            const localVarPath = `/api/v1/application/Policy/{id}/soft`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Policy_Update 
         * @param {number} id 
         * @param {PolicyFormDTO} [policyFormDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        policyUpdate: async (id: number, policyFormDTO?: PolicyFormDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('policyUpdate', 'id', id)
            const localVarPath = `/api/v1/application/Policy/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(policyFormDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Policy_UpdatePartial 
         * @param {number} id 
         * @param {Array<Operation>} [operation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        policyUpdatePartial: async (id: number, operation?: Array<Operation>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('policyUpdatePartial', 'id', id)
            const localVarPath = `/api/v1/application/Policy/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(operation, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PolicyApi - functional programming interface
 * @export
 */
export const PolicyApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PolicyApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Policy_Create 
         * @param {PolicyFormDTO} [policyFormDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async policyCreate(policyFormDTO?: PolicyFormDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PolicyFormDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.policyCreate(policyFormDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Policy_Delete 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async policyDelete(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.policyDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Policy_DeleteMany 
         * @param {Array<number>} [ids] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async policyDeleteMany(ids?: Array<number>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<number>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.policyDeleteMany(ids, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Policy_GetMany 
         * @param {string} [sortBy] 
         * @param {'asc' | 'desc'} [sortDirection] Values: asc, desc
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {boolean} [skipGlobalFilters] 
         * @param {any} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async policyGetMany(sortBy?: string, sortDirection?: 'asc' | 'desc', page?: number, pageSize?: number, skipGlobalFilters?: boolean, body?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagedResponseDtoOfPolicyListDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.policyGetMany(sortBy, sortDirection, page, pageSize, skipGlobalFilters, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Policy_Read 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async policyRead(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PolicyFormDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.policyRead(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Policy_SoftDelete 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async policySoftDelete(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.policySoftDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Policy_Update 
         * @param {number} id 
         * @param {PolicyFormDTO} [policyFormDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async policyUpdate(id: number, policyFormDTO?: PolicyFormDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PolicyFormDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.policyUpdate(id, policyFormDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Policy_UpdatePartial 
         * @param {number} id 
         * @param {Array<Operation>} [operation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async policyUpdatePartial(id: number, operation?: Array<Operation>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PolicyFormDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.policyUpdatePartial(id, operation, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PolicyApi - factory interface
 * @export
 */
export const PolicyApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PolicyApiFp(configuration)
    return {
        /**
         * 
         * @summary Policy_Create 
         * @param {PolicyFormDTO} [policyFormDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        policyCreate(policyFormDTO?: PolicyFormDTO, options?: any): AxiosPromise<PolicyFormDTO> {
            return localVarFp.policyCreate(policyFormDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Policy_Delete 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        policyDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.policyDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Policy_DeleteMany 
         * @param {Array<number>} [ids] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        policyDeleteMany(ids?: Array<number>, options?: any): AxiosPromise<Array<number>> {
            return localVarFp.policyDeleteMany(ids, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Policy_GetMany 
         * @param {string} [sortBy] 
         * @param {'asc' | 'desc'} [sortDirection] Values: asc, desc
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {boolean} [skipGlobalFilters] 
         * @param {any} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        policyGetMany(sortBy?: string, sortDirection?: 'asc' | 'desc', page?: number, pageSize?: number, skipGlobalFilters?: boolean, body?: any, options?: any): AxiosPromise<PagedResponseDtoOfPolicyListDTO> {
            return localVarFp.policyGetMany(sortBy, sortDirection, page, pageSize, skipGlobalFilters, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Policy_Read 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        policyRead(id: number, options?: any): AxiosPromise<PolicyFormDTO> {
            return localVarFp.policyRead(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Policy_SoftDelete 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        policySoftDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.policySoftDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Policy_Update 
         * @param {number} id 
         * @param {PolicyFormDTO} [policyFormDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        policyUpdate(id: number, policyFormDTO?: PolicyFormDTO, options?: any): AxiosPromise<PolicyFormDTO> {
            return localVarFp.policyUpdate(id, policyFormDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Policy_UpdatePartial 
         * @param {number} id 
         * @param {Array<Operation>} [operation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        policyUpdatePartial(id: number, operation?: Array<Operation>, options?: any): AxiosPromise<PolicyFormDTO> {
            return localVarFp.policyUpdatePartial(id, operation, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PolicyApi - object-oriented interface
 * @export
 * @class PolicyApi
 * @extends {BaseAPI}
 */
export class PolicyApi extends BaseAPI {
    /**
     * 
     * @summary Policy_Create 
     * @param {PolicyFormDTO} [policyFormDTO] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PolicyApi
     */
    public policyCreate(policyFormDTO?: PolicyFormDTO, options?: AxiosRequestConfig) {
        return PolicyApiFp(this.configuration).policyCreate(policyFormDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Policy_Delete 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PolicyApi
     */
    public policyDelete(id: number, options?: AxiosRequestConfig) {
        return PolicyApiFp(this.configuration).policyDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Policy_DeleteMany 
     * @param {Array<number>} [ids] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PolicyApi
     */
    public policyDeleteMany(ids?: Array<number>, options?: AxiosRequestConfig) {
        return PolicyApiFp(this.configuration).policyDeleteMany(ids, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Policy_GetMany 
     * @param {string} [sortBy] 
     * @param {'asc' | 'desc'} [sortDirection] Values: asc, desc
     * @param {number} [page] 
     * @param {number} [pageSize] 
     * @param {boolean} [skipGlobalFilters] 
     * @param {any} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PolicyApi
     */
    public policyGetMany(sortBy?: string, sortDirection?: 'asc' | 'desc', page?: number, pageSize?: number, skipGlobalFilters?: boolean, body?: any, options?: AxiosRequestConfig) {
        return PolicyApiFp(this.configuration).policyGetMany(sortBy, sortDirection, page, pageSize, skipGlobalFilters, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Policy_Read 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PolicyApi
     */
    public policyRead(id: number, options?: AxiosRequestConfig) {
        return PolicyApiFp(this.configuration).policyRead(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Policy_SoftDelete 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PolicyApi
     */
    public policySoftDelete(id: number, options?: AxiosRequestConfig) {
        return PolicyApiFp(this.configuration).policySoftDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Policy_Update 
     * @param {number} id 
     * @param {PolicyFormDTO} [policyFormDTO] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PolicyApi
     */
    public policyUpdate(id: number, policyFormDTO?: PolicyFormDTO, options?: AxiosRequestConfig) {
        return PolicyApiFp(this.configuration).policyUpdate(id, policyFormDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Policy_UpdatePartial 
     * @param {number} id 
     * @param {Array<Operation>} [operation] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PolicyApi
     */
    public policyUpdatePartial(id: number, operation?: Array<Operation>, options?: AxiosRequestConfig) {
        return PolicyApiFp(this.configuration).policyUpdatePartial(id, operation, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * RoleApi - axios parameter creator
 * @export
 */
export const RoleApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Role_GetMany Retrieve a list of records of entity type
         * @param {string} [sortBy] Name of entity property to sort by
         * @param {'asc' | 'desc'} [sortDirection] Values: asc, desc
         * @param {number} [page] First page to retrieve (0 - based)
         * @param {number} [pageSize] Page size
         * @param {boolean} [skipGlobalFilters] If true ignores soft-deleted items
         * @param {any} [body] The criteria to follow
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        roleGetMany: async (sortBy?: string, sortDirection?: 'asc' | 'desc', page?: number, pageSize?: number, skipGlobalFilters?: boolean, body?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/application/Role/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (sortDirection !== undefined) {
                localVarQueryParameter['sortDirection'] = sortDirection;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (skipGlobalFilters !== undefined) {
                localVarQueryParameter['skipGlobalFilters'] = skipGlobalFilters;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RoleApi - functional programming interface
 * @export
 */
export const RoleApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RoleApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Role_GetMany Retrieve a list of records of entity type
         * @param {string} [sortBy] Name of entity property to sort by
         * @param {'asc' | 'desc'} [sortDirection] Values: asc, desc
         * @param {number} [page] First page to retrieve (0 - based)
         * @param {number} [pageSize] Page size
         * @param {boolean} [skipGlobalFilters] If true ignores soft-deleted items
         * @param {any} [body] The criteria to follow
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async roleGetMany(sortBy?: string, sortDirection?: 'asc' | 'desc', page?: number, pageSize?: number, skipGlobalFilters?: boolean, body?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagedResponseDtoOfRoleListDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.roleGetMany(sortBy, sortDirection, page, pageSize, skipGlobalFilters, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * RoleApi - factory interface
 * @export
 */
export const RoleApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RoleApiFp(configuration)
    return {
        /**
         * 
         * @summary Role_GetMany Retrieve a list of records of entity type
         * @param {string} [sortBy] Name of entity property to sort by
         * @param {'asc' | 'desc'} [sortDirection] Values: asc, desc
         * @param {number} [page] First page to retrieve (0 - based)
         * @param {number} [pageSize] Page size
         * @param {boolean} [skipGlobalFilters] If true ignores soft-deleted items
         * @param {any} [body] The criteria to follow
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        roleGetMany(sortBy?: string, sortDirection?: 'asc' | 'desc', page?: number, pageSize?: number, skipGlobalFilters?: boolean, body?: any, options?: any): AxiosPromise<PagedResponseDtoOfRoleListDTO> {
            return localVarFp.roleGetMany(sortBy, sortDirection, page, pageSize, skipGlobalFilters, body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * RoleApi - object-oriented interface
 * @export
 * @class RoleApi
 * @extends {BaseAPI}
 */
export class RoleApi extends BaseAPI {
    /**
     * 
     * @summary Role_GetMany Retrieve a list of records of entity type
     * @param {string} [sortBy] Name of entity property to sort by
     * @param {'asc' | 'desc'} [sortDirection] Values: asc, desc
     * @param {number} [page] First page to retrieve (0 - based)
     * @param {number} [pageSize] Page size
     * @param {boolean} [skipGlobalFilters] If true ignores soft-deleted items
     * @param {any} [body] The criteria to follow
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoleApi
     */
    public roleGetMany(sortBy?: string, sortDirection?: 'asc' | 'desc', page?: number, pageSize?: number, skipGlobalFilters?: boolean, body?: any, options?: AxiosRequestConfig) {
        return RoleApiFp(this.configuration).roleGetMany(sortBy, sortDirection, page, pageSize, skipGlobalFilters, body, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SettingApi - axios parameter creator
 * @export
 */
export const SettingApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Setting_Create 
         * @param {SettingFormDTO} [settingFormDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        settingCreate: async (settingFormDTO?: SettingFormDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/application/Setting`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(settingFormDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Setting_Delete 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        settingDelete: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('settingDelete', 'id', id)
            const localVarPath = `/api/v1/application/Setting/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Setting_DeleteMany 
         * @param {Array<number>} [ids] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        settingDeleteMany: async (ids?: Array<number>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/application/Setting`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (ids) {
                localVarQueryParameter['ids'] = ids;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Setting_GetMany 
         * @param {string} [sortBy] 
         * @param {'asc' | 'desc'} [sortDirection] Values: asc, desc
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {boolean} [skipGlobalFilters] 
         * @param {any} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        settingGetMany: async (sortBy?: string, sortDirection?: 'asc' | 'desc', page?: number, pageSize?: number, skipGlobalFilters?: boolean, body?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/application/Setting/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (sortDirection !== undefined) {
                localVarQueryParameter['sortDirection'] = sortDirection;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (skipGlobalFilters !== undefined) {
                localVarQueryParameter['skipGlobalFilters'] = skipGlobalFilters;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Setting_Read 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        settingRead: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('settingRead', 'id', id)
            const localVarPath = `/api/v1/application/Setting/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Setting_SoftDelete 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        settingSoftDelete: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('settingSoftDelete', 'id', id)
            const localVarPath = `/api/v1/application/Setting/{id}/soft`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Setting_Update 
         * @param {number} id 
         * @param {SettingFormDTO} [settingFormDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        settingUpdate: async (id: number, settingFormDTO?: SettingFormDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('settingUpdate', 'id', id)
            const localVarPath = `/api/v1/application/Setting/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(settingFormDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Setting_UpdatePartial 
         * @param {number} id 
         * @param {Array<Operation>} [operation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        settingUpdatePartial: async (id: number, operation?: Array<Operation>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('settingUpdatePartial', 'id', id)
            const localVarPath = `/api/v1/application/Setting/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(operation, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SettingApi - functional programming interface
 * @export
 */
export const SettingApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SettingApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Setting_Create 
         * @param {SettingFormDTO} [settingFormDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async settingCreate(settingFormDTO?: SettingFormDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SettingFormDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.settingCreate(settingFormDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Setting_Delete 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async settingDelete(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.settingDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Setting_DeleteMany 
         * @param {Array<number>} [ids] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async settingDeleteMany(ids?: Array<number>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<number>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.settingDeleteMany(ids, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Setting_GetMany 
         * @param {string} [sortBy] 
         * @param {'asc' | 'desc'} [sortDirection] Values: asc, desc
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {boolean} [skipGlobalFilters] 
         * @param {any} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async settingGetMany(sortBy?: string, sortDirection?: 'asc' | 'desc', page?: number, pageSize?: number, skipGlobalFilters?: boolean, body?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagedResponseDtoOfSettingListDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.settingGetMany(sortBy, sortDirection, page, pageSize, skipGlobalFilters, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Setting_Read 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async settingRead(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SettingFormDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.settingRead(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Setting_SoftDelete 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async settingSoftDelete(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.settingSoftDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Setting_Update 
         * @param {number} id 
         * @param {SettingFormDTO} [settingFormDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async settingUpdate(id: number, settingFormDTO?: SettingFormDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SettingFormDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.settingUpdate(id, settingFormDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Setting_UpdatePartial 
         * @param {number} id 
         * @param {Array<Operation>} [operation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async settingUpdatePartial(id: number, operation?: Array<Operation>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SettingFormDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.settingUpdatePartial(id, operation, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SettingApi - factory interface
 * @export
 */
export const SettingApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SettingApiFp(configuration)
    return {
        /**
         * 
         * @summary Setting_Create 
         * @param {SettingFormDTO} [settingFormDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        settingCreate(settingFormDTO?: SettingFormDTO, options?: any): AxiosPromise<SettingFormDTO> {
            return localVarFp.settingCreate(settingFormDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Setting_Delete 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        settingDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.settingDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Setting_DeleteMany 
         * @param {Array<number>} [ids] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        settingDeleteMany(ids?: Array<number>, options?: any): AxiosPromise<Array<number>> {
            return localVarFp.settingDeleteMany(ids, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Setting_GetMany 
         * @param {string} [sortBy] 
         * @param {'asc' | 'desc'} [sortDirection] Values: asc, desc
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {boolean} [skipGlobalFilters] 
         * @param {any} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        settingGetMany(sortBy?: string, sortDirection?: 'asc' | 'desc', page?: number, pageSize?: number, skipGlobalFilters?: boolean, body?: any, options?: any): AxiosPromise<PagedResponseDtoOfSettingListDTO> {
            return localVarFp.settingGetMany(sortBy, sortDirection, page, pageSize, skipGlobalFilters, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Setting_Read 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        settingRead(id: number, options?: any): AxiosPromise<SettingFormDTO> {
            return localVarFp.settingRead(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Setting_SoftDelete 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        settingSoftDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.settingSoftDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Setting_Update 
         * @param {number} id 
         * @param {SettingFormDTO} [settingFormDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        settingUpdate(id: number, settingFormDTO?: SettingFormDTO, options?: any): AxiosPromise<SettingFormDTO> {
            return localVarFp.settingUpdate(id, settingFormDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Setting_UpdatePartial 
         * @param {number} id 
         * @param {Array<Operation>} [operation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        settingUpdatePartial(id: number, operation?: Array<Operation>, options?: any): AxiosPromise<SettingFormDTO> {
            return localVarFp.settingUpdatePartial(id, operation, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SettingApi - object-oriented interface
 * @export
 * @class SettingApi
 * @extends {BaseAPI}
 */
export class SettingApi extends BaseAPI {
    /**
     * 
     * @summary Setting_Create 
     * @param {SettingFormDTO} [settingFormDTO] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettingApi
     */
    public settingCreate(settingFormDTO?: SettingFormDTO, options?: AxiosRequestConfig) {
        return SettingApiFp(this.configuration).settingCreate(settingFormDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Setting_Delete 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettingApi
     */
    public settingDelete(id: number, options?: AxiosRequestConfig) {
        return SettingApiFp(this.configuration).settingDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Setting_DeleteMany 
     * @param {Array<number>} [ids] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettingApi
     */
    public settingDeleteMany(ids?: Array<number>, options?: AxiosRequestConfig) {
        return SettingApiFp(this.configuration).settingDeleteMany(ids, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Setting_GetMany 
     * @param {string} [sortBy] 
     * @param {'asc' | 'desc'} [sortDirection] Values: asc, desc
     * @param {number} [page] 
     * @param {number} [pageSize] 
     * @param {boolean} [skipGlobalFilters] 
     * @param {any} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettingApi
     */
    public settingGetMany(sortBy?: string, sortDirection?: 'asc' | 'desc', page?: number, pageSize?: number, skipGlobalFilters?: boolean, body?: any, options?: AxiosRequestConfig) {
        return SettingApiFp(this.configuration).settingGetMany(sortBy, sortDirection, page, pageSize, skipGlobalFilters, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Setting_Read 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettingApi
     */
    public settingRead(id: number, options?: AxiosRequestConfig) {
        return SettingApiFp(this.configuration).settingRead(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Setting_SoftDelete 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettingApi
     */
    public settingSoftDelete(id: number, options?: AxiosRequestConfig) {
        return SettingApiFp(this.configuration).settingSoftDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Setting_Update 
     * @param {number} id 
     * @param {SettingFormDTO} [settingFormDTO] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettingApi
     */
    public settingUpdate(id: number, settingFormDTO?: SettingFormDTO, options?: AxiosRequestConfig) {
        return SettingApiFp(this.configuration).settingUpdate(id, settingFormDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Setting_UpdatePartial 
     * @param {number} id 
     * @param {Array<Operation>} [operation] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettingApi
     */
    public settingUpdatePartial(id: number, operation?: Array<Operation>, options?: AxiosRequestConfig) {
        return SettingApiFp(this.configuration).settingUpdatePartial(id, operation, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * TechnicalBranchApi - axios parameter creator
 * @export
 */
export const TechnicalBranchApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary TechnicalBranch_Create 
         * @param {TechnicalBranchFormDTO} [technicalBranchFormDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        technicalBranchCreate: async (technicalBranchFormDTO?: TechnicalBranchFormDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/application/TechnicalBranch`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(technicalBranchFormDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary TechnicalBranch_Delete 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        technicalBranchDelete: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('technicalBranchDelete', 'id', id)
            const localVarPath = `/api/v1/application/TechnicalBranch/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary TechnicalBranch_DeleteMany 
         * @param {Array<number>} [ids] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        technicalBranchDeleteMany: async (ids?: Array<number>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/application/TechnicalBranch`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (ids) {
                localVarQueryParameter['ids'] = ids;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary TechnicalBranch_GetMany 
         * @param {string} [sortBy] 
         * @param {'asc' | 'desc'} [sortDirection] Values: asc, desc
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {boolean} [skipGlobalFilters] 
         * @param {any} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        technicalBranchGetMany: async (sortBy?: string, sortDirection?: 'asc' | 'desc', page?: number, pageSize?: number, skipGlobalFilters?: boolean, body?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/application/TechnicalBranch/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (sortDirection !== undefined) {
                localVarQueryParameter['sortDirection'] = sortDirection;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (skipGlobalFilters !== undefined) {
                localVarQueryParameter['skipGlobalFilters'] = skipGlobalFilters;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary TechnicalBranch_Read 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        technicalBranchRead: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('technicalBranchRead', 'id', id)
            const localVarPath = `/api/v1/application/TechnicalBranch/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary TechnicalBranch_SoftDelete 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        technicalBranchSoftDelete: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('technicalBranchSoftDelete', 'id', id)
            const localVarPath = `/api/v1/application/TechnicalBranch/{id}/soft`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary TechnicalBranch_Update 
         * @param {number} id 
         * @param {TechnicalBranchFormDTO} [technicalBranchFormDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        technicalBranchUpdate: async (id: number, technicalBranchFormDTO?: TechnicalBranchFormDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('technicalBranchUpdate', 'id', id)
            const localVarPath = `/api/v1/application/TechnicalBranch/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(technicalBranchFormDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary TechnicalBranch_UpdatePartial 
         * @param {number} id 
         * @param {Array<Operation>} [operation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        technicalBranchUpdatePartial: async (id: number, operation?: Array<Operation>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('technicalBranchUpdatePartial', 'id', id)
            const localVarPath = `/api/v1/application/TechnicalBranch/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(operation, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TechnicalBranchApi - functional programming interface
 * @export
 */
export const TechnicalBranchApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TechnicalBranchApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary TechnicalBranch_Create 
         * @param {TechnicalBranchFormDTO} [technicalBranchFormDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async technicalBranchCreate(technicalBranchFormDTO?: TechnicalBranchFormDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TechnicalBranchFormDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.technicalBranchCreate(technicalBranchFormDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary TechnicalBranch_Delete 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async technicalBranchDelete(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.technicalBranchDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary TechnicalBranch_DeleteMany 
         * @param {Array<number>} [ids] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async technicalBranchDeleteMany(ids?: Array<number>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<number>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.technicalBranchDeleteMany(ids, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary TechnicalBranch_GetMany 
         * @param {string} [sortBy] 
         * @param {'asc' | 'desc'} [sortDirection] Values: asc, desc
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {boolean} [skipGlobalFilters] 
         * @param {any} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async technicalBranchGetMany(sortBy?: string, sortDirection?: 'asc' | 'desc', page?: number, pageSize?: number, skipGlobalFilters?: boolean, body?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagedResponseDtoOfTechnicalBranchListDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.technicalBranchGetMany(sortBy, sortDirection, page, pageSize, skipGlobalFilters, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary TechnicalBranch_Read 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async technicalBranchRead(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TechnicalBranchFormDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.technicalBranchRead(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary TechnicalBranch_SoftDelete 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async technicalBranchSoftDelete(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.technicalBranchSoftDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary TechnicalBranch_Update 
         * @param {number} id 
         * @param {TechnicalBranchFormDTO} [technicalBranchFormDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async technicalBranchUpdate(id: number, technicalBranchFormDTO?: TechnicalBranchFormDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TechnicalBranchFormDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.technicalBranchUpdate(id, technicalBranchFormDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary TechnicalBranch_UpdatePartial 
         * @param {number} id 
         * @param {Array<Operation>} [operation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async technicalBranchUpdatePartial(id: number, operation?: Array<Operation>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TechnicalBranchFormDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.technicalBranchUpdatePartial(id, operation, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TechnicalBranchApi - factory interface
 * @export
 */
export const TechnicalBranchApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TechnicalBranchApiFp(configuration)
    return {
        /**
         * 
         * @summary TechnicalBranch_Create 
         * @param {TechnicalBranchFormDTO} [technicalBranchFormDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        technicalBranchCreate(technicalBranchFormDTO?: TechnicalBranchFormDTO, options?: any): AxiosPromise<TechnicalBranchFormDTO> {
            return localVarFp.technicalBranchCreate(technicalBranchFormDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary TechnicalBranch_Delete 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        technicalBranchDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.technicalBranchDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary TechnicalBranch_DeleteMany 
         * @param {Array<number>} [ids] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        technicalBranchDeleteMany(ids?: Array<number>, options?: any): AxiosPromise<Array<number>> {
            return localVarFp.technicalBranchDeleteMany(ids, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary TechnicalBranch_GetMany 
         * @param {string} [sortBy] 
         * @param {'asc' | 'desc'} [sortDirection] Values: asc, desc
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {boolean} [skipGlobalFilters] 
         * @param {any} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        technicalBranchGetMany(sortBy?: string, sortDirection?: 'asc' | 'desc', page?: number, pageSize?: number, skipGlobalFilters?: boolean, body?: any, options?: any): AxiosPromise<PagedResponseDtoOfTechnicalBranchListDTO> {
            return localVarFp.technicalBranchGetMany(sortBy, sortDirection, page, pageSize, skipGlobalFilters, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary TechnicalBranch_Read 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        technicalBranchRead(id: number, options?: any): AxiosPromise<TechnicalBranchFormDTO> {
            return localVarFp.technicalBranchRead(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary TechnicalBranch_SoftDelete 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        technicalBranchSoftDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.technicalBranchSoftDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary TechnicalBranch_Update 
         * @param {number} id 
         * @param {TechnicalBranchFormDTO} [technicalBranchFormDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        technicalBranchUpdate(id: number, technicalBranchFormDTO?: TechnicalBranchFormDTO, options?: any): AxiosPromise<TechnicalBranchFormDTO> {
            return localVarFp.technicalBranchUpdate(id, technicalBranchFormDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary TechnicalBranch_UpdatePartial 
         * @param {number} id 
         * @param {Array<Operation>} [operation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        technicalBranchUpdatePartial(id: number, operation?: Array<Operation>, options?: any): AxiosPromise<TechnicalBranchFormDTO> {
            return localVarFp.technicalBranchUpdatePartial(id, operation, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TechnicalBranchApi - object-oriented interface
 * @export
 * @class TechnicalBranchApi
 * @extends {BaseAPI}
 */
export class TechnicalBranchApi extends BaseAPI {
    /**
     * 
     * @summary TechnicalBranch_Create 
     * @param {TechnicalBranchFormDTO} [technicalBranchFormDTO] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TechnicalBranchApi
     */
    public technicalBranchCreate(technicalBranchFormDTO?: TechnicalBranchFormDTO, options?: AxiosRequestConfig) {
        return TechnicalBranchApiFp(this.configuration).technicalBranchCreate(technicalBranchFormDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary TechnicalBranch_Delete 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TechnicalBranchApi
     */
    public technicalBranchDelete(id: number, options?: AxiosRequestConfig) {
        return TechnicalBranchApiFp(this.configuration).technicalBranchDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary TechnicalBranch_DeleteMany 
     * @param {Array<number>} [ids] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TechnicalBranchApi
     */
    public technicalBranchDeleteMany(ids?: Array<number>, options?: AxiosRequestConfig) {
        return TechnicalBranchApiFp(this.configuration).technicalBranchDeleteMany(ids, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary TechnicalBranch_GetMany 
     * @param {string} [sortBy] 
     * @param {'asc' | 'desc'} [sortDirection] Values: asc, desc
     * @param {number} [page] 
     * @param {number} [pageSize] 
     * @param {boolean} [skipGlobalFilters] 
     * @param {any} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TechnicalBranchApi
     */
    public technicalBranchGetMany(sortBy?: string, sortDirection?: 'asc' | 'desc', page?: number, pageSize?: number, skipGlobalFilters?: boolean, body?: any, options?: AxiosRequestConfig) {
        return TechnicalBranchApiFp(this.configuration).technicalBranchGetMany(sortBy, sortDirection, page, pageSize, skipGlobalFilters, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary TechnicalBranch_Read 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TechnicalBranchApi
     */
    public technicalBranchRead(id: number, options?: AxiosRequestConfig) {
        return TechnicalBranchApiFp(this.configuration).technicalBranchRead(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary TechnicalBranch_SoftDelete 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TechnicalBranchApi
     */
    public technicalBranchSoftDelete(id: number, options?: AxiosRequestConfig) {
        return TechnicalBranchApiFp(this.configuration).technicalBranchSoftDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary TechnicalBranch_Update 
     * @param {number} id 
     * @param {TechnicalBranchFormDTO} [technicalBranchFormDTO] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TechnicalBranchApi
     */
    public technicalBranchUpdate(id: number, technicalBranchFormDTO?: TechnicalBranchFormDTO, options?: AxiosRequestConfig) {
        return TechnicalBranchApiFp(this.configuration).technicalBranchUpdate(id, technicalBranchFormDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary TechnicalBranch_UpdatePartial 
     * @param {number} id 
     * @param {Array<Operation>} [operation] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TechnicalBranchApi
     */
    public technicalBranchUpdatePartial(id: number, operation?: Array<Operation>, options?: AxiosRequestConfig) {
        return TechnicalBranchApiFp(this.configuration).technicalBranchUpdatePartial(id, operation, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * TranslationApi - axios parameter creator
 * @export
 */
export const TranslationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Translation_Create 
         * @param {any} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        translationCreate: async (body?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/application/Translation`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Translation_Delete 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        translationDelete: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('translationDelete', 'id', id)
            const localVarPath = `/api/v1/application/Translation/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Translation_DeleteMany 
         * @param {Array<number>} [ids] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        translationDeleteMany: async (ids?: Array<number>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/application/Translation`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (ids) {
                localVarQueryParameter['ids'] = ids;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Translation_GetMany 
         * @param {string} [sortBy] 
         * @param {'asc' | 'desc'} [sortDirection] Values: asc, desc
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {boolean} [skipGlobalFilters] 
         * @param {any} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        translationGetMany: async (sortBy?: string, sortDirection?: 'asc' | 'desc', page?: number, pageSize?: number, skipGlobalFilters?: boolean, body?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/application/Translation/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (sortDirection !== undefined) {
                localVarQueryParameter['sortDirection'] = sortDirection;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (skipGlobalFilters !== undefined) {
                localVarQueryParameter['skipGlobalFilters'] = skipGlobalFilters;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Translation_Read 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        translationRead: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('translationRead', 'id', id)
            const localVarPath = `/api/v1/application/Translation/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Translation_SoftDelete 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        translationSoftDelete: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('translationSoftDelete', 'id', id)
            const localVarPath = `/api/v1/application/Translation/{id}/soft`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Translation_Update 
         * @param {number} id 
         * @param {any} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        translationUpdate: async (id: number, body?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('translationUpdate', 'id', id)
            const localVarPath = `/api/v1/application/Translation/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Translation_UpdatePartial 
         * @param {number} id 
         * @param {Array<Operation>} [operation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        translationUpdatePartial: async (id: number, operation?: Array<Operation>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('translationUpdatePartial', 'id', id)
            const localVarPath = `/api/v1/application/Translation/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(operation, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TranslationApi - functional programming interface
 * @export
 */
export const TranslationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TranslationApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Translation_Create 
         * @param {any} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async translationCreate(body?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.translationCreate(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Translation_Delete 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async translationDelete(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.translationDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Translation_DeleteMany 
         * @param {Array<number>} [ids] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async translationDeleteMany(ids?: Array<number>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<number>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.translationDeleteMany(ids, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Translation_GetMany 
         * @param {string} [sortBy] 
         * @param {'asc' | 'desc'} [sortDirection] Values: asc, desc
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {boolean} [skipGlobalFilters] 
         * @param {any} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async translationGetMany(sortBy?: string, sortDirection?: 'asc' | 'desc', page?: number, pageSize?: number, skipGlobalFilters?: boolean, body?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagedResponseDtoOfJObject>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.translationGetMany(sortBy, sortDirection, page, pageSize, skipGlobalFilters, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Translation_Read 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async translationRead(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.translationRead(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Translation_SoftDelete 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async translationSoftDelete(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.translationSoftDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Translation_Update 
         * @param {number} id 
         * @param {any} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async translationUpdate(id: number, body?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.translationUpdate(id, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Translation_UpdatePartial 
         * @param {number} id 
         * @param {Array<Operation>} [operation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async translationUpdatePartial(id: number, operation?: Array<Operation>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.translationUpdatePartial(id, operation, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TranslationApi - factory interface
 * @export
 */
export const TranslationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TranslationApiFp(configuration)
    return {
        /**
         * 
         * @summary Translation_Create 
         * @param {any} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        translationCreate(body?: any, options?: any): AxiosPromise<any> {
            return localVarFp.translationCreate(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Translation_Delete 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        translationDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.translationDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Translation_DeleteMany 
         * @param {Array<number>} [ids] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        translationDeleteMany(ids?: Array<number>, options?: any): AxiosPromise<Array<number>> {
            return localVarFp.translationDeleteMany(ids, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Translation_GetMany 
         * @param {string} [sortBy] 
         * @param {'asc' | 'desc'} [sortDirection] Values: asc, desc
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {boolean} [skipGlobalFilters] 
         * @param {any} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        translationGetMany(sortBy?: string, sortDirection?: 'asc' | 'desc', page?: number, pageSize?: number, skipGlobalFilters?: boolean, body?: any, options?: any): AxiosPromise<PagedResponseDtoOfJObject> {
            return localVarFp.translationGetMany(sortBy, sortDirection, page, pageSize, skipGlobalFilters, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Translation_Read 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        translationRead(id: number, options?: any): AxiosPromise<any> {
            return localVarFp.translationRead(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Translation_SoftDelete 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        translationSoftDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.translationSoftDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Translation_Update 
         * @param {number} id 
         * @param {any} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        translationUpdate(id: number, body?: any, options?: any): AxiosPromise<any> {
            return localVarFp.translationUpdate(id, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Translation_UpdatePartial 
         * @param {number} id 
         * @param {Array<Operation>} [operation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        translationUpdatePartial(id: number, operation?: Array<Operation>, options?: any): AxiosPromise<any> {
            return localVarFp.translationUpdatePartial(id, operation, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TranslationApi - object-oriented interface
 * @export
 * @class TranslationApi
 * @extends {BaseAPI}
 */
export class TranslationApi extends BaseAPI {
    /**
     * 
     * @summary Translation_Create 
     * @param {any} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TranslationApi
     */
    public translationCreate(body?: any, options?: AxiosRequestConfig) {
        return TranslationApiFp(this.configuration).translationCreate(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Translation_Delete 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TranslationApi
     */
    public translationDelete(id: number, options?: AxiosRequestConfig) {
        return TranslationApiFp(this.configuration).translationDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Translation_DeleteMany 
     * @param {Array<number>} [ids] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TranslationApi
     */
    public translationDeleteMany(ids?: Array<number>, options?: AxiosRequestConfig) {
        return TranslationApiFp(this.configuration).translationDeleteMany(ids, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Translation_GetMany 
     * @param {string} [sortBy] 
     * @param {'asc' | 'desc'} [sortDirection] Values: asc, desc
     * @param {number} [page] 
     * @param {number} [pageSize] 
     * @param {boolean} [skipGlobalFilters] 
     * @param {any} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TranslationApi
     */
    public translationGetMany(sortBy?: string, sortDirection?: 'asc' | 'desc', page?: number, pageSize?: number, skipGlobalFilters?: boolean, body?: any, options?: AxiosRequestConfig) {
        return TranslationApiFp(this.configuration).translationGetMany(sortBy, sortDirection, page, pageSize, skipGlobalFilters, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Translation_Read 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TranslationApi
     */
    public translationRead(id: number, options?: AxiosRequestConfig) {
        return TranslationApiFp(this.configuration).translationRead(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Translation_SoftDelete 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TranslationApi
     */
    public translationSoftDelete(id: number, options?: AxiosRequestConfig) {
        return TranslationApiFp(this.configuration).translationSoftDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Translation_Update 
     * @param {number} id 
     * @param {any} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TranslationApi
     */
    public translationUpdate(id: number, body?: any, options?: AxiosRequestConfig) {
        return TranslationApiFp(this.configuration).translationUpdate(id, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Translation_UpdatePartial 
     * @param {number} id 
     * @param {Array<Operation>} [operation] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TranslationApi
     */
    public translationUpdatePartial(id: number, operation?: Array<Operation>, options?: AxiosRequestConfig) {
        return TranslationApiFp(this.configuration).translationUpdatePartial(id, operation, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UIApi - axios parameter creator
 * @export
 */
export const UIApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Screens:  - <a href=\"https://www.figma.com/file/Oo0zANd3WP9pwGxdAunrSf/Adductio---Website?node-id=249%3A12286\">Brands</a>
         * @summary UI_BrandSearch Searches brands
         * @param {Array<number>} [areas] 
         * @param {Array<number>} [branches] 
         * @param {string} [text] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [acceptLanguage] Request Language; Values: pt, en, fr, es
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uIBrandSearch: async (areas?: Array<number>, branches?: Array<number>, text?: string, page?: number, pageSize?: number, acceptLanguage?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/application/UI/brand/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (areas) {
                localVarQueryParameter['areas'] = areas;
            }

            if (branches) {
                localVarQueryParameter['branches'] = branches;
            }

            if (text !== undefined) {
                localVarQueryParameter['text'] = text;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Screens:  - <a href=\"https://www.figma.com/file/Oo0zANd3WP9pwGxdAunrSf/Adductio---Website?node-id=149%3A9221\">Equipment Type</a>
         * @summary UI_EquipmentSearch Searches for equipment models of a given type
         * @param {string} [acceptLanguage] Request Language; Values: pt, en, fr, es
         * @param {EquipmentsSearchEquipmentsQuery} [equipmentsSearchEquipmentsQuery] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uIEquipmentSearch: async (acceptLanguage?: string, equipmentsSearchEquipmentsQuery?: EquipmentsSearchEquipmentsQuery, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/application/UI/equipment/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(equipmentsSearchEquipmentsQuery, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Screens:  - <a href=\"https://www.figma.com/file/Oo0zANd3WP9pwGxdAunrSf/Adductio---Website?node-id=634%3A15135\">Add Equipment</a>(populate eq.types select)  - <a href=\"https://www.figma.com/file/Oo0zANd3WP9pwGxdAunrSf/Adductio---Website?node-id=249%3A11734\">Area</a>
         * @summary UI_EquipmentTypeSearch Searches equipment types
         * @param {string} [acceptLanguage] Request Language; Values: pt, en, fr, es
         * @param {EquipmentTypesSearchEquipmentTypesWithSQLQuery} [equipmentTypesSearchEquipmentTypesWithSQLQuery] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uIEquipmentTypeSearch: async (acceptLanguage?: string, equipmentTypesSearchEquipmentTypesWithSQLQuery?: EquipmentTypesSearchEquipmentTypesWithSQLQuery, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/application/UI/equipment-type/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(equipmentTypesSearchEquipmentTypesWithSQLQuery, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary UI_GenerateSpecSheetPdf  (Auth)
         * @param {string} [acceptLanguage] Request Language; Values: pt, en, fr, es
         * @param {EquipmentsSpecSheetGeneratePdfCommand} [equipmentsSpecSheetGeneratePdfCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uIGenerateSpecSheetPdf: async (acceptLanguage?: string, equipmentsSpecSheetGeneratePdfCommand?: EquipmentsSpecSheetGeneratePdfCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/application/UI/equipment/pdf`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(equipmentsSpecSheetGeneratePdfCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Just in case we need a lazy loading approach for the area tree.
         * @summary UI_GetAreaBranch Retrieves a branch of the area tree (we probably don\'t need this and can always use the GetAreaTree endpoint).
         * @param {number} id The starting node ID
         * @param {string} [acceptLanguage] Request Language; Values: pt, en, fr, es
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uIGetAreaBranch: async (id: number, acceptLanguage?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('uIGetAreaBranch', 'id', id)
            const localVarPath = `/api/v1/application/UI/area/branch/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Screens:  - <a href=\"https://www.figma.com/file/Oo0zANd3WP9pwGxdAunrSf/Adductio---Website?node-id=148%3A7328\">Homepage</a>   - <a href=\"https://www.figma.com/file/Oo0zANd3WP9pwGxdAunrSf/Adductio---Website?node-id=148%3A8205\">Diretorio</a>   - <a href=\"https://www.figma.com/file/Oo0zANd3WP9pwGxdAunrSf/Adductio---Website?node-id=249%3A11734\">Area</a>
         * @summary UI_GetAreaTree Retrieves the areas in a nested json structure (to allow rendering as a tree)
         * @param {string} [acceptLanguage] Request Language; Values: pt, en, fr, es
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uIGetAreaTree: async (acceptLanguage?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/application/UI/area/tree`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary UI_GetBrand 
         * @param {number} id 
         * @param {string} [acceptLanguage] Request Language; Values: pt, en, fr, es
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uIGetBrand: async (id: number, acceptLanguage?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('uIGetBrand', 'id', id)
            const localVarPath = `/api/v1/application/UI/brand/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary UI_GetContentBranch Retrieves a branch of the content tree
         * @param {number} id The starting node ID
         * @param {string} [acceptLanguage] Request Language; Values: pt, en, fr, es
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uIGetContentBranch: async (id: number, acceptLanguage?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('uIGetContentBranch', 'id', id)
            const localVarPath = `/api/v1/application/UI/content/branch/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary UI_GetContentTree Retrieves the contents in a nested json structure
         * @param {string} [acceptLanguage] Request Language; Values: pt, en, fr, es
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uIGetContentTree: async (acceptLanguage?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/application/UI/content/tree`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary UI_GetCountries Retrieves the countries
         * @param {number} [regionId] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [acceptLanguage] Request Language; Values: pt, en, fr, es
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uIGetCountries: async (regionId?: number, page?: number, pageSize?: number, acceptLanguage?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/application/UI/location/countries`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (regionId !== undefined) {
                localVarQueryParameter['regionId'] = regionId;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Screens:  - <a href=\"https://www.figma.com/file/Oo0zANd3WP9pwGxdAunrSf/Adductio---Website?node-id=148%3A8941\">Equipment Detail</a>
         * @summary UI_GetEquipment Gets an equipment with the UI language in multilingual fields, ready to present in the UI.
         * @param {number} id 
         * @param {boolean} [singleLang] 
         * @param {string} [acceptLanguage] Request Language; Values: pt, en, fr, es
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uIGetEquipment: async (id: number, singleLang?: boolean, acceptLanguage?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('uIGetEquipment', 'id', id)
            const localVarPath = `/api/v1/application/UI/equipment/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (singleLang !== undefined) {
                localVarQueryParameter['singleLang'] = singleLang;
            }

            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * It\'s used to feed React Json Schema Form.   Screens:  - <a href=\"https://www.figma.com/file/Oo0zANd3WP9pwGxdAunrSf/Adductio---Website?node-id=149%3A9221\">Equipment Type</a> (schemaType: mainSearch/advancedSearch)  - <a href=\"https://www.figma.com/file/Oo0zANd3WP9pwGxdAunrSf/Adductio---Website?node-id=148%3A8941\">Equipment Detail</a> (schemaType: fullReadOnly)  - <a href=\"https://www.figma.com/file/Oo0zANd3WP9pwGxdAunrSf/Adductio---Website?node-id=634%3A15060\">Add Equipment</a> (schemaType: fullReadWrite)
         * @summary UI_GetEquipmentTypeSchema Retrieves the JSON Schema that describes an equipment type.
         * @param {number} [equipmentTypeId] 
         * @param {'fullReadWrite' | 'fullReadOnly' | 'mainSearch' | 'advancedSearch'} [schemaType] Values: fullReadWrite, fullReadOnly, mainSearch, advancedSearch
         * @param {string} [acceptLanguage] Request Language; Values: pt, en, fr, es
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uIGetEquipmentTypeSchema: async (equipmentTypeId?: number, schemaType?: 'fullReadWrite' | 'fullReadOnly' | 'mainSearch' | 'advancedSearch', acceptLanguage?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/application/UI/equipment-type/schema`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (equipmentTypeId !== undefined) {
                localVarQueryParameter['equipmentTypeId'] = equipmentTypeId;
            }

            if (schemaType !== undefined) {
                localVarQueryParameter['schemaType'] = schemaType;
            }

            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary UI_GetRegions Retrieves the regions
         * @param {number} [id] 
         * @param {string} [acceptLanguage] Request Language; Values: pt, en, fr, es
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uIGetRegions: async (id?: number, acceptLanguage?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/application/UI/location/regions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Screens:  - <a href=\"https://www.figma.com/file/Oo0zANd3WP9pwGxdAunrSf/Adductio---Website?node-id=148%3A7328\">Homepage</a>  - <a href=\"https://www.figma.com/file/Oo0zANd3WP9pwGxdAunrSf/Adductio---Website?node-id=249%3A11734\">Area</a>
         * @summary UI_GetTechnicalBranches Retrieves the technical branches (especialidades).
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [acceptLanguage] Request Language; Values: pt, en, fr, es
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uIGetTechnicalBranches: async (page?: number, pageSize?: number, acceptLanguage?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/application/UI/technical-branch`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary UI_GetTranslations Retrieves the translations
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [acceptLanguage] Request Language; Values: pt, en, fr, es
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uIGetTranslations: async (page?: number, pageSize?: number, acceptLanguage?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/application/UI/translations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Screens:  - <a href=\"https://www.figma.com/file/Oo0zANd3WP9pwGxdAunrSf/Adductio---Website?node-id=148%3A7328\">Home</a>
         * @summary UI_MainSearch Main search for the application - searches equipments, brands and equipment types, all at once.
         * @param {string} [text] 
         * @param {'all' | 'equipments' | 'brands' | 'equipmentTypes'} [searchType] Values: all, equipments, brands, equipmentTypes
         * @param {string} [acceptLanguage] Request Language; Values: pt, en, fr, es
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uIMainSearch: async (text?: string, searchType?: 'all' | 'equipments' | 'brands' | 'equipmentTypes', acceptLanguage?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/application/UI/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (text !== undefined) {
                localVarQueryParameter['text'] = text;
            }

            if (searchType !== undefined) {
                localVarQueryParameter['searchType'] = searchType;
            }

            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary UI_RequestQuote  (Auth)
         * @param {string} [acceptLanguage] Request Language; Values: pt, en, fr, es
         * @param {EquipmentsRequestQuoteCommand} [equipmentsRequestQuoteCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uIRequestQuote: async (acceptLanguage?: string, equipmentsRequestQuoteCommand?: EquipmentsRequestQuoteCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/application/UI/equipment/quote`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(equipmentsRequestQuoteCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary UI_TestPDF  (Auth)
         * @param {string} [acceptLanguage] Request Language; Values: pt, en, fr, es
         * @param {EquipmentsGeneratePdfTestCommand} [equipmentsGeneratePdfTestCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uITestPDF: async (acceptLanguage?: string, equipmentsGeneratePdfTestCommand?: EquipmentsGeneratePdfTestCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/application/UI/test-pdf`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(equipmentsGeneratePdfTestCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UIApi - functional programming interface
 * @export
 */
export const UIApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UIApiAxiosParamCreator(configuration)
    return {
        /**
         * Screens:  - <a href=\"https://www.figma.com/file/Oo0zANd3WP9pwGxdAunrSf/Adductio---Website?node-id=249%3A12286\">Brands</a>
         * @summary UI_BrandSearch Searches brands
         * @param {Array<number>} [areas] 
         * @param {Array<number>} [branches] 
         * @param {string} [text] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [acceptLanguage] Request Language; Values: pt, en, fr, es
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uIBrandSearch(areas?: Array<number>, branches?: Array<number>, text?: string, page?: number, pageSize?: number, acceptLanguage?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagedResponseDtoOfBrandSearchDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uIBrandSearch(areas, branches, text, page, pageSize, acceptLanguage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Screens:  - <a href=\"https://www.figma.com/file/Oo0zANd3WP9pwGxdAunrSf/Adductio---Website?node-id=149%3A9221\">Equipment Type</a>
         * @summary UI_EquipmentSearch Searches for equipment models of a given type
         * @param {string} [acceptLanguage] Request Language; Values: pt, en, fr, es
         * @param {EquipmentsSearchEquipmentsQuery} [equipmentsSearchEquipmentsQuery] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uIEquipmentSearch(acceptLanguage?: string, equipmentsSearchEquipmentsQuery?: EquipmentsSearchEquipmentsQuery, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EquipmentsSearchEquipmentsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uIEquipmentSearch(acceptLanguage, equipmentsSearchEquipmentsQuery, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Screens:  - <a href=\"https://www.figma.com/file/Oo0zANd3WP9pwGxdAunrSf/Adductio---Website?node-id=634%3A15135\">Add Equipment</a>(populate eq.types select)  - <a href=\"https://www.figma.com/file/Oo0zANd3WP9pwGxdAunrSf/Adductio---Website?node-id=249%3A11734\">Area</a>
         * @summary UI_EquipmentTypeSearch Searches equipment types
         * @param {string} [acceptLanguage] Request Language; Values: pt, en, fr, es
         * @param {EquipmentTypesSearchEquipmentTypesWithSQLQuery} [equipmentTypesSearchEquipmentTypesWithSQLQuery] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uIEquipmentTypeSearch(acceptLanguage?: string, equipmentTypesSearchEquipmentTypesWithSQLQuery?: EquipmentTypesSearchEquipmentTypesWithSQLQuery, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagedResponseDtoOfEquipmentTypeSearchDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uIEquipmentTypeSearch(acceptLanguage, equipmentTypesSearchEquipmentTypesWithSQLQuery, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary UI_GenerateSpecSheetPdf  (Auth)
         * @param {string} [acceptLanguage] Request Language; Values: pt, en, fr, es
         * @param {EquipmentsSpecSheetGeneratePdfCommand} [equipmentsSpecSheetGeneratePdfCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uIGenerateSpecSheetPdf(acceptLanguage?: string, equipmentsSpecSheetGeneratePdfCommand?: EquipmentsSpecSheetGeneratePdfCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uIGenerateSpecSheetPdf(acceptLanguage, equipmentsSpecSheetGeneratePdfCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Just in case we need a lazy loading approach for the area tree.
         * @summary UI_GetAreaBranch Retrieves a branch of the area tree (we probably don\'t need this and can always use the GetAreaTree endpoint).
         * @param {number} id The starting node ID
         * @param {string} [acceptLanguage] Request Language; Values: pt, en, fr, es
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uIGetAreaBranch(id: number, acceptLanguage?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AreaTreeUiDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uIGetAreaBranch(id, acceptLanguage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Screens:  - <a href=\"https://www.figma.com/file/Oo0zANd3WP9pwGxdAunrSf/Adductio---Website?node-id=148%3A7328\">Homepage</a>   - <a href=\"https://www.figma.com/file/Oo0zANd3WP9pwGxdAunrSf/Adductio---Website?node-id=148%3A8205\">Diretorio</a>   - <a href=\"https://www.figma.com/file/Oo0zANd3WP9pwGxdAunrSf/Adductio---Website?node-id=249%3A11734\">Area</a>
         * @summary UI_GetAreaTree Retrieves the areas in a nested json structure (to allow rendering as a tree)
         * @param {string} [acceptLanguage] Request Language; Values: pt, en, fr, es
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uIGetAreaTree(acceptLanguage?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AreaTreeUiDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uIGetAreaTree(acceptLanguage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary UI_GetBrand 
         * @param {number} id 
         * @param {string} [acceptLanguage] Request Language; Values: pt, en, fr, es
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uIGetBrand(id: number, acceptLanguage?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BrandUiFullDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uIGetBrand(id, acceptLanguage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary UI_GetContentBranch Retrieves a branch of the content tree
         * @param {number} id The starting node ID
         * @param {string} [acceptLanguage] Request Language; Values: pt, en, fr, es
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uIGetContentBranch(id: number, acceptLanguage?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ContentTreeUiDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uIGetContentBranch(id, acceptLanguage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary UI_GetContentTree Retrieves the contents in a nested json structure
         * @param {string} [acceptLanguage] Request Language; Values: pt, en, fr, es
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uIGetContentTree(acceptLanguage?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ContentTreeUiDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uIGetContentTree(acceptLanguage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary UI_GetCountries Retrieves the countries
         * @param {number} [regionId] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [acceptLanguage] Request Language; Values: pt, en, fr, es
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uIGetCountries(regionId?: number, page?: number, pageSize?: number, acceptLanguage?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagedResponseDtoOfCountryUiDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uIGetCountries(regionId, page, pageSize, acceptLanguage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Screens:  - <a href=\"https://www.figma.com/file/Oo0zANd3WP9pwGxdAunrSf/Adductio---Website?node-id=148%3A8941\">Equipment Detail</a>
         * @summary UI_GetEquipment Gets an equipment with the UI language in multilingual fields, ready to present in the UI.
         * @param {number} id 
         * @param {boolean} [singleLang] 
         * @param {string} [acceptLanguage] Request Language; Values: pt, en, fr, es
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uIGetEquipment(id: number, singleLang?: boolean, acceptLanguage?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EquipmentUiDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uIGetEquipment(id, singleLang, acceptLanguage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * It\'s used to feed React Json Schema Form.   Screens:  - <a href=\"https://www.figma.com/file/Oo0zANd3WP9pwGxdAunrSf/Adductio---Website?node-id=149%3A9221\">Equipment Type</a> (schemaType: mainSearch/advancedSearch)  - <a href=\"https://www.figma.com/file/Oo0zANd3WP9pwGxdAunrSf/Adductio---Website?node-id=148%3A8941\">Equipment Detail</a> (schemaType: fullReadOnly)  - <a href=\"https://www.figma.com/file/Oo0zANd3WP9pwGxdAunrSf/Adductio---Website?node-id=634%3A15060\">Add Equipment</a> (schemaType: fullReadWrite)
         * @summary UI_GetEquipmentTypeSchema Retrieves the JSON Schema that describes an equipment type.
         * @param {number} [equipmentTypeId] 
         * @param {'fullReadWrite' | 'fullReadOnly' | 'mainSearch' | 'advancedSearch'} [schemaType] Values: fullReadWrite, fullReadOnly, mainSearch, advancedSearch
         * @param {string} [acceptLanguage] Request Language; Values: pt, en, fr, es
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uIGetEquipmentTypeSchema(equipmentTypeId?: number, schemaType?: 'fullReadWrite' | 'fullReadOnly' | 'mainSearch' | 'advancedSearch', acceptLanguage?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EquipmentsGetJsonSchemaResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uIGetEquipmentTypeSchema(equipmentTypeId, schemaType, acceptLanguage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary UI_GetRegions Retrieves the regions
         * @param {number} [id] 
         * @param {string} [acceptLanguage] Request Language; Values: pt, en, fr, es
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uIGetRegions(id?: number, acceptLanguage?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RegionTreeUiDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uIGetRegions(id, acceptLanguage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Screens:  - <a href=\"https://www.figma.com/file/Oo0zANd3WP9pwGxdAunrSf/Adductio---Website?node-id=148%3A7328\">Homepage</a>  - <a href=\"https://www.figma.com/file/Oo0zANd3WP9pwGxdAunrSf/Adductio---Website?node-id=249%3A11734\">Area</a>
         * @summary UI_GetTechnicalBranches Retrieves the technical branches (especialidades).
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [acceptLanguage] Request Language; Values: pt, en, fr, es
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uIGetTechnicalBranches(page?: number, pageSize?: number, acceptLanguage?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagedResponseDtoOfTechnicalBranchUiDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uIGetTechnicalBranches(page, pageSize, acceptLanguage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary UI_GetTranslations Retrieves the translations
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [acceptLanguage] Request Language; Values: pt, en, fr, es
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uIGetTranslations(page?: number, pageSize?: number, acceptLanguage?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagedResponseDtoOfKeyValuePairOfStringString>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uIGetTranslations(page, pageSize, acceptLanguage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Screens:  - <a href=\"https://www.figma.com/file/Oo0zANd3WP9pwGxdAunrSf/Adductio---Website?node-id=148%3A7328\">Home</a>
         * @summary UI_MainSearch Main search for the application - searches equipments, brands and equipment types, all at once.
         * @param {string} [text] 
         * @param {'all' | 'equipments' | 'brands' | 'equipmentTypes'} [searchType] Values: all, equipments, brands, equipmentTypes
         * @param {string} [acceptLanguage] Request Language; Values: pt, en, fr, es
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uIMainSearch(text?: string, searchType?: 'all' | 'equipments' | 'brands' | 'equipmentTypes', acceptLanguage?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EquipmentsMainSearchResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uIMainSearch(text, searchType, acceptLanguage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary UI_RequestQuote  (Auth)
         * @param {string} [acceptLanguage] Request Language; Values: pt, en, fr, es
         * @param {EquipmentsRequestQuoteCommand} [equipmentsRequestQuoteCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uIRequestQuote(acceptLanguage?: string, equipmentsRequestQuoteCommand?: EquipmentsRequestQuoteCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseMessage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uIRequestQuote(acceptLanguage, equipmentsRequestQuoteCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary UI_TestPDF  (Auth)
         * @param {string} [acceptLanguage] Request Language; Values: pt, en, fr, es
         * @param {EquipmentsGeneratePdfTestCommand} [equipmentsGeneratePdfTestCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uITestPDF(acceptLanguage?: string, equipmentsGeneratePdfTestCommand?: EquipmentsGeneratePdfTestCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uITestPDF(acceptLanguage, equipmentsGeneratePdfTestCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UIApi - factory interface
 * @export
 */
export const UIApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UIApiFp(configuration)
    return {
        /**
         * Screens:  - <a href=\"https://www.figma.com/file/Oo0zANd3WP9pwGxdAunrSf/Adductio---Website?node-id=249%3A12286\">Brands</a>
         * @summary UI_BrandSearch Searches brands
         * @param {Array<number>} [areas] 
         * @param {Array<number>} [branches] 
         * @param {string} [text] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [acceptLanguage] Request Language; Values: pt, en, fr, es
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uIBrandSearch(areas?: Array<number>, branches?: Array<number>, text?: string, page?: number, pageSize?: number, acceptLanguage?: string, options?: any): AxiosPromise<PagedResponseDtoOfBrandSearchDTO> {
            return localVarFp.uIBrandSearch(areas, branches, text, page, pageSize, acceptLanguage, options).then((request) => request(axios, basePath));
        },
        /**
         * Screens:  - <a href=\"https://www.figma.com/file/Oo0zANd3WP9pwGxdAunrSf/Adductio---Website?node-id=149%3A9221\">Equipment Type</a>
         * @summary UI_EquipmentSearch Searches for equipment models of a given type
         * @param {string} [acceptLanguage] Request Language; Values: pt, en, fr, es
         * @param {EquipmentsSearchEquipmentsQuery} [equipmentsSearchEquipmentsQuery] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uIEquipmentSearch(acceptLanguage?: string, equipmentsSearchEquipmentsQuery?: EquipmentsSearchEquipmentsQuery, options?: any): AxiosPromise<EquipmentsSearchEquipmentsResponse> {
            return localVarFp.uIEquipmentSearch(acceptLanguage, equipmentsSearchEquipmentsQuery, options).then((request) => request(axios, basePath));
        },
        /**
         * Screens:  - <a href=\"https://www.figma.com/file/Oo0zANd3WP9pwGxdAunrSf/Adductio---Website?node-id=634%3A15135\">Add Equipment</a>(populate eq.types select)  - <a href=\"https://www.figma.com/file/Oo0zANd3WP9pwGxdAunrSf/Adductio---Website?node-id=249%3A11734\">Area</a>
         * @summary UI_EquipmentTypeSearch Searches equipment types
         * @param {string} [acceptLanguage] Request Language; Values: pt, en, fr, es
         * @param {EquipmentTypesSearchEquipmentTypesWithSQLQuery} [equipmentTypesSearchEquipmentTypesWithSQLQuery] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uIEquipmentTypeSearch(acceptLanguage?: string, equipmentTypesSearchEquipmentTypesWithSQLQuery?: EquipmentTypesSearchEquipmentTypesWithSQLQuery, options?: any): AxiosPromise<PagedResponseDtoOfEquipmentTypeSearchDTO> {
            return localVarFp.uIEquipmentTypeSearch(acceptLanguage, equipmentTypesSearchEquipmentTypesWithSQLQuery, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary UI_GenerateSpecSheetPdf  (Auth)
         * @param {string} [acceptLanguage] Request Language; Values: pt, en, fr, es
         * @param {EquipmentsSpecSheetGeneratePdfCommand} [equipmentsSpecSheetGeneratePdfCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uIGenerateSpecSheetPdf(acceptLanguage?: string, equipmentsSpecSheetGeneratePdfCommand?: EquipmentsSpecSheetGeneratePdfCommand, options?: any): AxiosPromise<void> {
            return localVarFp.uIGenerateSpecSheetPdf(acceptLanguage, equipmentsSpecSheetGeneratePdfCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * Just in case we need a lazy loading approach for the area tree.
         * @summary UI_GetAreaBranch Retrieves a branch of the area tree (we probably don\'t need this and can always use the GetAreaTree endpoint).
         * @param {number} id The starting node ID
         * @param {string} [acceptLanguage] Request Language; Values: pt, en, fr, es
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uIGetAreaBranch(id: number, acceptLanguage?: string, options?: any): AxiosPromise<Array<AreaTreeUiDTO>> {
            return localVarFp.uIGetAreaBranch(id, acceptLanguage, options).then((request) => request(axios, basePath));
        },
        /**
         * Screens:  - <a href=\"https://www.figma.com/file/Oo0zANd3WP9pwGxdAunrSf/Adductio---Website?node-id=148%3A7328\">Homepage</a>   - <a href=\"https://www.figma.com/file/Oo0zANd3WP9pwGxdAunrSf/Adductio---Website?node-id=148%3A8205\">Diretorio</a>   - <a href=\"https://www.figma.com/file/Oo0zANd3WP9pwGxdAunrSf/Adductio---Website?node-id=249%3A11734\">Area</a>
         * @summary UI_GetAreaTree Retrieves the areas in a nested json structure (to allow rendering as a tree)
         * @param {string} [acceptLanguage] Request Language; Values: pt, en, fr, es
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uIGetAreaTree(acceptLanguage?: string, options?: any): AxiosPromise<Array<AreaTreeUiDTO>> {
            return localVarFp.uIGetAreaTree(acceptLanguage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary UI_GetBrand 
         * @param {number} id 
         * @param {string} [acceptLanguage] Request Language; Values: pt, en, fr, es
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uIGetBrand(id: number, acceptLanguage?: string, options?: any): AxiosPromise<BrandUiFullDTO> {
            return localVarFp.uIGetBrand(id, acceptLanguage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary UI_GetContentBranch Retrieves a branch of the content tree
         * @param {number} id The starting node ID
         * @param {string} [acceptLanguage] Request Language; Values: pt, en, fr, es
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uIGetContentBranch(id: number, acceptLanguage?: string, options?: any): AxiosPromise<Array<ContentTreeUiDTO>> {
            return localVarFp.uIGetContentBranch(id, acceptLanguage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary UI_GetContentTree Retrieves the contents in a nested json structure
         * @param {string} [acceptLanguage] Request Language; Values: pt, en, fr, es
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uIGetContentTree(acceptLanguage?: string, options?: any): AxiosPromise<Array<ContentTreeUiDTO>> {
            return localVarFp.uIGetContentTree(acceptLanguage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary UI_GetCountries Retrieves the countries
         * @param {number} [regionId] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [acceptLanguage] Request Language; Values: pt, en, fr, es
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uIGetCountries(regionId?: number, page?: number, pageSize?: number, acceptLanguage?: string, options?: any): AxiosPromise<PagedResponseDtoOfCountryUiDTO> {
            return localVarFp.uIGetCountries(regionId, page, pageSize, acceptLanguage, options).then((request) => request(axios, basePath));
        },
        /**
         * Screens:  - <a href=\"https://www.figma.com/file/Oo0zANd3WP9pwGxdAunrSf/Adductio---Website?node-id=148%3A8941\">Equipment Detail</a>
         * @summary UI_GetEquipment Gets an equipment with the UI language in multilingual fields, ready to present in the UI.
         * @param {number} id 
         * @param {boolean} [singleLang] 
         * @param {string} [acceptLanguage] Request Language; Values: pt, en, fr, es
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uIGetEquipment(id: number, singleLang?: boolean, acceptLanguage?: string, options?: any): AxiosPromise<EquipmentUiDTO> {
            return localVarFp.uIGetEquipment(id, singleLang, acceptLanguage, options).then((request) => request(axios, basePath));
        },
        /**
         * It\'s used to feed React Json Schema Form.   Screens:  - <a href=\"https://www.figma.com/file/Oo0zANd3WP9pwGxdAunrSf/Adductio---Website?node-id=149%3A9221\">Equipment Type</a> (schemaType: mainSearch/advancedSearch)  - <a href=\"https://www.figma.com/file/Oo0zANd3WP9pwGxdAunrSf/Adductio---Website?node-id=148%3A8941\">Equipment Detail</a> (schemaType: fullReadOnly)  - <a href=\"https://www.figma.com/file/Oo0zANd3WP9pwGxdAunrSf/Adductio---Website?node-id=634%3A15060\">Add Equipment</a> (schemaType: fullReadWrite)
         * @summary UI_GetEquipmentTypeSchema Retrieves the JSON Schema that describes an equipment type.
         * @param {number} [equipmentTypeId] 
         * @param {'fullReadWrite' | 'fullReadOnly' | 'mainSearch' | 'advancedSearch'} [schemaType] Values: fullReadWrite, fullReadOnly, mainSearch, advancedSearch
         * @param {string} [acceptLanguage] Request Language; Values: pt, en, fr, es
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uIGetEquipmentTypeSchema(equipmentTypeId?: number, schemaType?: 'fullReadWrite' | 'fullReadOnly' | 'mainSearch' | 'advancedSearch', acceptLanguage?: string, options?: any): AxiosPromise<EquipmentsGetJsonSchemaResponse> {
            return localVarFp.uIGetEquipmentTypeSchema(equipmentTypeId, schemaType, acceptLanguage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary UI_GetRegions Retrieves the regions
         * @param {number} [id] 
         * @param {string} [acceptLanguage] Request Language; Values: pt, en, fr, es
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uIGetRegions(id?: number, acceptLanguage?: string, options?: any): AxiosPromise<Array<RegionTreeUiDTO>> {
            return localVarFp.uIGetRegions(id, acceptLanguage, options).then((request) => request(axios, basePath));
        },
        /**
         * Screens:  - <a href=\"https://www.figma.com/file/Oo0zANd3WP9pwGxdAunrSf/Adductio---Website?node-id=148%3A7328\">Homepage</a>  - <a href=\"https://www.figma.com/file/Oo0zANd3WP9pwGxdAunrSf/Adductio---Website?node-id=249%3A11734\">Area</a>
         * @summary UI_GetTechnicalBranches Retrieves the technical branches (especialidades).
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [acceptLanguage] Request Language; Values: pt, en, fr, es
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uIGetTechnicalBranches(page?: number, pageSize?: number, acceptLanguage?: string, options?: any): AxiosPromise<PagedResponseDtoOfTechnicalBranchUiDTO> {
            return localVarFp.uIGetTechnicalBranches(page, pageSize, acceptLanguage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary UI_GetTranslations Retrieves the translations
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [acceptLanguage] Request Language; Values: pt, en, fr, es
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uIGetTranslations(page?: number, pageSize?: number, acceptLanguage?: string, options?: any): AxiosPromise<PagedResponseDtoOfKeyValuePairOfStringString> {
            return localVarFp.uIGetTranslations(page, pageSize, acceptLanguage, options).then((request) => request(axios, basePath));
        },
        /**
         * Screens:  - <a href=\"https://www.figma.com/file/Oo0zANd3WP9pwGxdAunrSf/Adductio---Website?node-id=148%3A7328\">Home</a>
         * @summary UI_MainSearch Main search for the application - searches equipments, brands and equipment types, all at once.
         * @param {string} [text] 
         * @param {'all' | 'equipments' | 'brands' | 'equipmentTypes'} [searchType] Values: all, equipments, brands, equipmentTypes
         * @param {string} [acceptLanguage] Request Language; Values: pt, en, fr, es
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uIMainSearch(text?: string, searchType?: 'all' | 'equipments' | 'brands' | 'equipmentTypes', acceptLanguage?: string, options?: any): AxiosPromise<EquipmentsMainSearchResponse> {
            return localVarFp.uIMainSearch(text, searchType, acceptLanguage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary UI_RequestQuote  (Auth)
         * @param {string} [acceptLanguage] Request Language; Values: pt, en, fr, es
         * @param {EquipmentsRequestQuoteCommand} [equipmentsRequestQuoteCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uIRequestQuote(acceptLanguage?: string, equipmentsRequestQuoteCommand?: EquipmentsRequestQuoteCommand, options?: any): AxiosPromise<ResponseMessage> {
            return localVarFp.uIRequestQuote(acceptLanguage, equipmentsRequestQuoteCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary UI_TestPDF  (Auth)
         * @param {string} [acceptLanguage] Request Language; Values: pt, en, fr, es
         * @param {EquipmentsGeneratePdfTestCommand} [equipmentsGeneratePdfTestCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uITestPDF(acceptLanguage?: string, equipmentsGeneratePdfTestCommand?: EquipmentsGeneratePdfTestCommand, options?: any): AxiosPromise<void> {
            return localVarFp.uITestPDF(acceptLanguage, equipmentsGeneratePdfTestCommand, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UIApi - object-oriented interface
 * @export
 * @class UIApi
 * @extends {BaseAPI}
 */
export class UIApi extends BaseAPI {
    /**
     * Screens:  - <a href=\"https://www.figma.com/file/Oo0zANd3WP9pwGxdAunrSf/Adductio---Website?node-id=249%3A12286\">Brands</a>
     * @summary UI_BrandSearch Searches brands
     * @param {Array<number>} [areas] 
     * @param {Array<number>} [branches] 
     * @param {string} [text] 
     * @param {number} [page] 
     * @param {number} [pageSize] 
     * @param {string} [acceptLanguage] Request Language; Values: pt, en, fr, es
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UIApi
     */
    public uIBrandSearch(areas?: Array<number>, branches?: Array<number>, text?: string, page?: number, pageSize?: number, acceptLanguage?: string, options?: AxiosRequestConfig) {
        return UIApiFp(this.configuration).uIBrandSearch(areas, branches, text, page, pageSize, acceptLanguage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Screens:  - <a href=\"https://www.figma.com/file/Oo0zANd3WP9pwGxdAunrSf/Adductio---Website?node-id=149%3A9221\">Equipment Type</a>
     * @summary UI_EquipmentSearch Searches for equipment models of a given type
     * @param {string} [acceptLanguage] Request Language; Values: pt, en, fr, es
     * @param {EquipmentsSearchEquipmentsQuery} [equipmentsSearchEquipmentsQuery] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UIApi
     */
    public uIEquipmentSearch(acceptLanguage?: string, equipmentsSearchEquipmentsQuery?: EquipmentsSearchEquipmentsQuery, options?: AxiosRequestConfig) {
        return UIApiFp(this.configuration).uIEquipmentSearch(acceptLanguage, equipmentsSearchEquipmentsQuery, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Screens:  - <a href=\"https://www.figma.com/file/Oo0zANd3WP9pwGxdAunrSf/Adductio---Website?node-id=634%3A15135\">Add Equipment</a>(populate eq.types select)  - <a href=\"https://www.figma.com/file/Oo0zANd3WP9pwGxdAunrSf/Adductio---Website?node-id=249%3A11734\">Area</a>
     * @summary UI_EquipmentTypeSearch Searches equipment types
     * @param {string} [acceptLanguage] Request Language; Values: pt, en, fr, es
     * @param {EquipmentTypesSearchEquipmentTypesWithSQLQuery} [equipmentTypesSearchEquipmentTypesWithSQLQuery] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UIApi
     */
    public uIEquipmentTypeSearch(acceptLanguage?: string, equipmentTypesSearchEquipmentTypesWithSQLQuery?: EquipmentTypesSearchEquipmentTypesWithSQLQuery, options?: AxiosRequestConfig) {
        return UIApiFp(this.configuration).uIEquipmentTypeSearch(acceptLanguage, equipmentTypesSearchEquipmentTypesWithSQLQuery, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary UI_GenerateSpecSheetPdf  (Auth)
     * @param {string} [acceptLanguage] Request Language; Values: pt, en, fr, es
     * @param {EquipmentsSpecSheetGeneratePdfCommand} [equipmentsSpecSheetGeneratePdfCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UIApi
     */
    public uIGenerateSpecSheetPdf(acceptLanguage?: string, equipmentsSpecSheetGeneratePdfCommand?: EquipmentsSpecSheetGeneratePdfCommand, options?: AxiosRequestConfig) {
        return UIApiFp(this.configuration).uIGenerateSpecSheetPdf(acceptLanguage, equipmentsSpecSheetGeneratePdfCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Just in case we need a lazy loading approach for the area tree.
     * @summary UI_GetAreaBranch Retrieves a branch of the area tree (we probably don\'t need this and can always use the GetAreaTree endpoint).
     * @param {number} id The starting node ID
     * @param {string} [acceptLanguage] Request Language; Values: pt, en, fr, es
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UIApi
     */
    public uIGetAreaBranch(id: number, acceptLanguage?: string, options?: AxiosRequestConfig) {
        return UIApiFp(this.configuration).uIGetAreaBranch(id, acceptLanguage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Screens:  - <a href=\"https://www.figma.com/file/Oo0zANd3WP9pwGxdAunrSf/Adductio---Website?node-id=148%3A7328\">Homepage</a>   - <a href=\"https://www.figma.com/file/Oo0zANd3WP9pwGxdAunrSf/Adductio---Website?node-id=148%3A8205\">Diretorio</a>   - <a href=\"https://www.figma.com/file/Oo0zANd3WP9pwGxdAunrSf/Adductio---Website?node-id=249%3A11734\">Area</a>
     * @summary UI_GetAreaTree Retrieves the areas in a nested json structure (to allow rendering as a tree)
     * @param {string} [acceptLanguage] Request Language; Values: pt, en, fr, es
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UIApi
     */
    public uIGetAreaTree(acceptLanguage?: string, options?: AxiosRequestConfig) {
        return UIApiFp(this.configuration).uIGetAreaTree(acceptLanguage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary UI_GetBrand 
     * @param {number} id 
     * @param {string} [acceptLanguage] Request Language; Values: pt, en, fr, es
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UIApi
     */
    public uIGetBrand(id: number, acceptLanguage?: string, options?: AxiosRequestConfig) {
        return UIApiFp(this.configuration).uIGetBrand(id, acceptLanguage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary UI_GetContentBranch Retrieves a branch of the content tree
     * @param {number} id The starting node ID
     * @param {string} [acceptLanguage] Request Language; Values: pt, en, fr, es
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UIApi
     */
    public uIGetContentBranch(id: number, acceptLanguage?: string, options?: AxiosRequestConfig) {
        return UIApiFp(this.configuration).uIGetContentBranch(id, acceptLanguage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary UI_GetContentTree Retrieves the contents in a nested json structure
     * @param {string} [acceptLanguage] Request Language; Values: pt, en, fr, es
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UIApi
     */
    public uIGetContentTree(acceptLanguage?: string, options?: AxiosRequestConfig) {
        return UIApiFp(this.configuration).uIGetContentTree(acceptLanguage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary UI_GetCountries Retrieves the countries
     * @param {number} [regionId] 
     * @param {number} [page] 
     * @param {number} [pageSize] 
     * @param {string} [acceptLanguage] Request Language; Values: pt, en, fr, es
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UIApi
     */
    public uIGetCountries(regionId?: number, page?: number, pageSize?: number, acceptLanguage?: string, options?: AxiosRequestConfig) {
        return UIApiFp(this.configuration).uIGetCountries(regionId, page, pageSize, acceptLanguage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Screens:  - <a href=\"https://www.figma.com/file/Oo0zANd3WP9pwGxdAunrSf/Adductio---Website?node-id=148%3A8941\">Equipment Detail</a>
     * @summary UI_GetEquipment Gets an equipment with the UI language in multilingual fields, ready to present in the UI.
     * @param {number} id 
     * @param {boolean} [singleLang] 
     * @param {string} [acceptLanguage] Request Language; Values: pt, en, fr, es
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UIApi
     */
    public uIGetEquipment(id: number, singleLang?: boolean, acceptLanguage?: string, options?: AxiosRequestConfig) {
        return UIApiFp(this.configuration).uIGetEquipment(id, singleLang, acceptLanguage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * It\'s used to feed React Json Schema Form.   Screens:  - <a href=\"https://www.figma.com/file/Oo0zANd3WP9pwGxdAunrSf/Adductio---Website?node-id=149%3A9221\">Equipment Type</a> (schemaType: mainSearch/advancedSearch)  - <a href=\"https://www.figma.com/file/Oo0zANd3WP9pwGxdAunrSf/Adductio---Website?node-id=148%3A8941\">Equipment Detail</a> (schemaType: fullReadOnly)  - <a href=\"https://www.figma.com/file/Oo0zANd3WP9pwGxdAunrSf/Adductio---Website?node-id=634%3A15060\">Add Equipment</a> (schemaType: fullReadWrite)
     * @summary UI_GetEquipmentTypeSchema Retrieves the JSON Schema that describes an equipment type.
     * @param {number} [equipmentTypeId] 
     * @param {'fullReadWrite' | 'fullReadOnly' | 'mainSearch' | 'advancedSearch'} [schemaType] Values: fullReadWrite, fullReadOnly, mainSearch, advancedSearch
     * @param {string} [acceptLanguage] Request Language; Values: pt, en, fr, es
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UIApi
     */
    public uIGetEquipmentTypeSchema(equipmentTypeId?: number, schemaType?: 'fullReadWrite' | 'fullReadOnly' | 'mainSearch' | 'advancedSearch', acceptLanguage?: string, options?: AxiosRequestConfig) {
        return UIApiFp(this.configuration).uIGetEquipmentTypeSchema(equipmentTypeId, schemaType, acceptLanguage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary UI_GetRegions Retrieves the regions
     * @param {number} [id] 
     * @param {string} [acceptLanguage] Request Language; Values: pt, en, fr, es
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UIApi
     */
    public uIGetRegions(id?: number, acceptLanguage?: string, options?: AxiosRequestConfig) {
        return UIApiFp(this.configuration).uIGetRegions(id, acceptLanguage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Screens:  - <a href=\"https://www.figma.com/file/Oo0zANd3WP9pwGxdAunrSf/Adductio---Website?node-id=148%3A7328\">Homepage</a>  - <a href=\"https://www.figma.com/file/Oo0zANd3WP9pwGxdAunrSf/Adductio---Website?node-id=249%3A11734\">Area</a>
     * @summary UI_GetTechnicalBranches Retrieves the technical branches (especialidades).
     * @param {number} [page] 
     * @param {number} [pageSize] 
     * @param {string} [acceptLanguage] Request Language; Values: pt, en, fr, es
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UIApi
     */
    public uIGetTechnicalBranches(page?: number, pageSize?: number, acceptLanguage?: string, options?: AxiosRequestConfig) {
        return UIApiFp(this.configuration).uIGetTechnicalBranches(page, pageSize, acceptLanguage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary UI_GetTranslations Retrieves the translations
     * @param {number} [page] 
     * @param {number} [pageSize] 
     * @param {string} [acceptLanguage] Request Language; Values: pt, en, fr, es
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UIApi
     */
    public uIGetTranslations(page?: number, pageSize?: number, acceptLanguage?: string, options?: AxiosRequestConfig) {
        return UIApiFp(this.configuration).uIGetTranslations(page, pageSize, acceptLanguage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Screens:  - <a href=\"https://www.figma.com/file/Oo0zANd3WP9pwGxdAunrSf/Adductio---Website?node-id=148%3A7328\">Home</a>
     * @summary UI_MainSearch Main search for the application - searches equipments, brands and equipment types, all at once.
     * @param {string} [text] 
     * @param {'all' | 'equipments' | 'brands' | 'equipmentTypes'} [searchType] Values: all, equipments, brands, equipmentTypes
     * @param {string} [acceptLanguage] Request Language; Values: pt, en, fr, es
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UIApi
     */
    public uIMainSearch(text?: string, searchType?: 'all' | 'equipments' | 'brands' | 'equipmentTypes', acceptLanguage?: string, options?: AxiosRequestConfig) {
        return UIApiFp(this.configuration).uIMainSearch(text, searchType, acceptLanguage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary UI_RequestQuote  (Auth)
     * @param {string} [acceptLanguage] Request Language; Values: pt, en, fr, es
     * @param {EquipmentsRequestQuoteCommand} [equipmentsRequestQuoteCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UIApi
     */
    public uIRequestQuote(acceptLanguage?: string, equipmentsRequestQuoteCommand?: EquipmentsRequestQuoteCommand, options?: AxiosRequestConfig) {
        return UIApiFp(this.configuration).uIRequestQuote(acceptLanguage, equipmentsRequestQuoteCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary UI_TestPDF  (Auth)
     * @param {string} [acceptLanguage] Request Language; Values: pt, en, fr, es
     * @param {EquipmentsGeneratePdfTestCommand} [equipmentsGeneratePdfTestCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UIApi
     */
    public uITestPDF(acceptLanguage?: string, equipmentsGeneratePdfTestCommand?: EquipmentsGeneratePdfTestCommand, options?: AxiosRequestConfig) {
        return UIApiFp(this.configuration).uITestPDF(acceptLanguage, equipmentsGeneratePdfTestCommand, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UIBrandUserApi - axios parameter creator
 * @export
 */
export const UIBrandUserApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary UIBrandUser_CloneEquipment  (Auth roles: BrandUser)
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uIBrandUserCloneEquipment: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('uIBrandUserCloneEquipment', 'id', id)
            const localVarPath = `/api/v1/application/UIBrandUser/equipment/{id}/clone`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Screens:  - <a href=\"https://www.figma.com/file/Oo0zANd3WP9pwGxdAunrSf/Adductio---Website?node-id=634%3A15135\">Add Equipment</a> (populate eq.types select)
         * @summary UIBrandUser_CreateEquipment Allows a brand user (identified by an access token) to add an equipment model of a brand he sells/distributes. (Auth policies: brand-body-ownership)
         * @param {EquipmentFormDTO} [equipmentFormDTO] The equipment structure. JsonData field is the json generated by React Json Schema Form.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uIBrandUserCreateEquipment: async (equipmentFormDTO?: EquipmentFormDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/application/UIBrandUser/equipment`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(equipmentFormDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary UIBrandUser_Read Allows a brand user (identified by an access token) to read a brand he sells/distributes. (Auth policies: brand-query-ownership)
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uIBrandUserRead: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('uIBrandUserRead', 'id', id)
            const localVarPath = `/api/v1/application/UIBrandUser/brand/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary UIBrandUser_UpdateEquipment  (Auth policies: brand-body-ownership)
         * @param {string} id 
         * @param {EquipmentFormDTO} [equipmentFormDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uIBrandUserUpdateEquipment: async (id: string, equipmentFormDTO?: EquipmentFormDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('uIBrandUserUpdateEquipment', 'id', id)
            const localVarPath = `/api/v1/application/UIBrandUser/equipment/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(equipmentFormDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary UIBrandUser_UpdateUserBrand Allows a user (identified by an access token) to upsert a brand that he claims to sell/distribute.  There are some metadata and control fields in the json that should be returned to the server as received.  The json example contains a new image (sent in base64) and a previously existing logo (which is sent back as received). (Auth policies: brand-body-ownership)
         * @param {BrandsUpsertUserBrandCommand} [brandsUpsertUserBrandCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uIBrandUserUpdateUserBrand: async (brandsUpsertUserBrandCommand?: BrandsUpsertUserBrandCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/application/UIBrandUser/brand`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(brandsUpsertUserBrandCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UIBrandUserApi - functional programming interface
 * @export
 */
export const UIBrandUserApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UIBrandUserApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary UIBrandUser_CloneEquipment  (Auth roles: BrandUser)
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uIBrandUserCloneEquipment(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseMessage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uIBrandUserCloneEquipment(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Screens:  - <a href=\"https://www.figma.com/file/Oo0zANd3WP9pwGxdAunrSf/Adductio---Website?node-id=634%3A15135\">Add Equipment</a> (populate eq.types select)
         * @summary UIBrandUser_CreateEquipment Allows a brand user (identified by an access token) to add an equipment model of a brand he sells/distributes. (Auth policies: brand-body-ownership)
         * @param {EquipmentFormDTO} [equipmentFormDTO] The equipment structure. JsonData field is the json generated by React Json Schema Form.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uIBrandUserCreateEquipment(equipmentFormDTO?: EquipmentFormDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseMessage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uIBrandUserCreateEquipment(equipmentFormDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary UIBrandUser_Read Allows a brand user (identified by an access token) to read a brand he sells/distributes. (Auth policies: brand-query-ownership)
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uIBrandUserRead(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BrandFormDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uIBrandUserRead(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary UIBrandUser_UpdateEquipment  (Auth policies: brand-body-ownership)
         * @param {string} id 
         * @param {EquipmentFormDTO} [equipmentFormDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uIBrandUserUpdateEquipment(id: string, equipmentFormDTO?: EquipmentFormDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseMessage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uIBrandUserUpdateEquipment(id, equipmentFormDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary UIBrandUser_UpdateUserBrand Allows a user (identified by an access token) to upsert a brand that he claims to sell/distribute.  There are some metadata and control fields in the json that should be returned to the server as received.  The json example contains a new image (sent in base64) and a previously existing logo (which is sent back as received). (Auth policies: brand-body-ownership)
         * @param {BrandsUpsertUserBrandCommand} [brandsUpsertUserBrandCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uIBrandUserUpdateUserBrand(brandsUpsertUserBrandCommand?: BrandsUpsertUserBrandCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseMessage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uIBrandUserUpdateUserBrand(brandsUpsertUserBrandCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UIBrandUserApi - factory interface
 * @export
 */
export const UIBrandUserApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UIBrandUserApiFp(configuration)
    return {
        /**
         * 
         * @summary UIBrandUser_CloneEquipment  (Auth roles: BrandUser)
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uIBrandUserCloneEquipment(id: number, options?: any): AxiosPromise<ResponseMessage> {
            return localVarFp.uIBrandUserCloneEquipment(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Screens:  - <a href=\"https://www.figma.com/file/Oo0zANd3WP9pwGxdAunrSf/Adductio---Website?node-id=634%3A15135\">Add Equipment</a> (populate eq.types select)
         * @summary UIBrandUser_CreateEquipment Allows a brand user (identified by an access token) to add an equipment model of a brand he sells/distributes. (Auth policies: brand-body-ownership)
         * @param {EquipmentFormDTO} [equipmentFormDTO] The equipment structure. JsonData field is the json generated by React Json Schema Form.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uIBrandUserCreateEquipment(equipmentFormDTO?: EquipmentFormDTO, options?: any): AxiosPromise<ResponseMessage> {
            return localVarFp.uIBrandUserCreateEquipment(equipmentFormDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary UIBrandUser_Read Allows a brand user (identified by an access token) to read a brand he sells/distributes. (Auth policies: brand-query-ownership)
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uIBrandUserRead(id: number, options?: any): AxiosPromise<BrandFormDTO> {
            return localVarFp.uIBrandUserRead(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary UIBrandUser_UpdateEquipment  (Auth policies: brand-body-ownership)
         * @param {string} id 
         * @param {EquipmentFormDTO} [equipmentFormDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uIBrandUserUpdateEquipment(id: string, equipmentFormDTO?: EquipmentFormDTO, options?: any): AxiosPromise<ResponseMessage> {
            return localVarFp.uIBrandUserUpdateEquipment(id, equipmentFormDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary UIBrandUser_UpdateUserBrand Allows a user (identified by an access token) to upsert a brand that he claims to sell/distribute.  There are some metadata and control fields in the json that should be returned to the server as received.  The json example contains a new image (sent in base64) and a previously existing logo (which is sent back as received). (Auth policies: brand-body-ownership)
         * @param {BrandsUpsertUserBrandCommand} [brandsUpsertUserBrandCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uIBrandUserUpdateUserBrand(brandsUpsertUserBrandCommand?: BrandsUpsertUserBrandCommand, options?: any): AxiosPromise<ResponseMessage> {
            return localVarFp.uIBrandUserUpdateUserBrand(brandsUpsertUserBrandCommand, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UIBrandUserApi - object-oriented interface
 * @export
 * @class UIBrandUserApi
 * @extends {BaseAPI}
 */
export class UIBrandUserApi extends BaseAPI {
    /**
     * 
     * @summary UIBrandUser_CloneEquipment  (Auth roles: BrandUser)
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UIBrandUserApi
     */
    public uIBrandUserCloneEquipment(id: number, options?: AxiosRequestConfig) {
        return UIBrandUserApiFp(this.configuration).uIBrandUserCloneEquipment(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Screens:  - <a href=\"https://www.figma.com/file/Oo0zANd3WP9pwGxdAunrSf/Adductio---Website?node-id=634%3A15135\">Add Equipment</a> (populate eq.types select)
     * @summary UIBrandUser_CreateEquipment Allows a brand user (identified by an access token) to add an equipment model of a brand he sells/distributes. (Auth policies: brand-body-ownership)
     * @param {EquipmentFormDTO} [equipmentFormDTO] The equipment structure. JsonData field is the json generated by React Json Schema Form.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UIBrandUserApi
     */
    public uIBrandUserCreateEquipment(equipmentFormDTO?: EquipmentFormDTO, options?: AxiosRequestConfig) {
        return UIBrandUserApiFp(this.configuration).uIBrandUserCreateEquipment(equipmentFormDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary UIBrandUser_Read Allows a brand user (identified by an access token) to read a brand he sells/distributes. (Auth policies: brand-query-ownership)
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UIBrandUserApi
     */
    public uIBrandUserRead(id: number, options?: AxiosRequestConfig) {
        return UIBrandUserApiFp(this.configuration).uIBrandUserRead(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary UIBrandUser_UpdateEquipment  (Auth policies: brand-body-ownership)
     * @param {string} id 
     * @param {EquipmentFormDTO} [equipmentFormDTO] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UIBrandUserApi
     */
    public uIBrandUserUpdateEquipment(id: string, equipmentFormDTO?: EquipmentFormDTO, options?: AxiosRequestConfig) {
        return UIBrandUserApiFp(this.configuration).uIBrandUserUpdateEquipment(id, equipmentFormDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary UIBrandUser_UpdateUserBrand Allows a user (identified by an access token) to upsert a brand that he claims to sell/distribute.  There are some metadata and control fields in the json that should be returned to the server as received.  The json example contains a new image (sent in base64) and a previously existing logo (which is sent back as received). (Auth policies: brand-body-ownership)
     * @param {BrandsUpsertUserBrandCommand} [brandsUpsertUserBrandCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UIBrandUserApi
     */
    public uIBrandUserUpdateUserBrand(brandsUpsertUserBrandCommand?: BrandsUpsertUserBrandCommand, options?: AxiosRequestConfig) {
        return UIBrandUserApiFp(this.configuration).uIBrandUserUpdateUserBrand(brandsUpsertUserBrandCommand, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UIRegistrationApi - axios parameter creator
 * @export
 */
export const UIRegistrationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Screens:  - <a href=\"https://www.figma.com/file/Oo0zANd3WP9pwGxdAunrSf/Adductio---Website?node-id=1131%3A17416\">User registration</a>
         * @summary UIRegistration_CreateAppUser Handles an app user registration form submission
         * @param {UsersRegisterUserCommand} [usersRegisterUserCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uIRegistrationCreateAppUser: async (usersRegisterUserCommand?: UsersRegisterUserCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/application/UIRegistration/app-user`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(usersRegisterUserCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Screens:  - <a href=\"https://www.figma.com/file/Oo0zANd3WP9pwGxdAunrSf/Adductio---Website?node-id=1131%3A17416\">Brand user registration</a>
         * @summary UIRegistration_CreateBrandUser Handles a brand user registration form submission
         * @param {UsersRegisterBrandUserCommand} [usersRegisterBrandUserCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uIRegistrationCreateBrandUser: async (usersRegisterBrandUserCommand?: UsersRegisterBrandUserCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/application/UIRegistration/brand-user`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(usersRegisterBrandUserCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary UIRegistration_ForgotPassword 
         * @param {ForgotPasswordForgotPasswordSendMailCommand} [forgotPasswordForgotPasswordSendMailCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uIRegistrationForgotPassword: async (forgotPasswordForgotPasswordSendMailCommand?: ForgotPasswordForgotPasswordSendMailCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/application/UIRegistration/forgot-pass`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(forgotPasswordForgotPasswordSendMailCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary UIRegistration_ForgotPasswordSetNew 
         * @param {RecoverPasswordForgotPasswordSetNewCommand} [recoverPasswordForgotPasswordSetNewCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uIRegistrationForgotPasswordSetNew: async (recoverPasswordForgotPasswordSetNewCommand?: RecoverPasswordForgotPasswordSetNewCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/application/UIRegistration/forgot-pass-reset`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(recoverPasswordForgotPasswordSetNewCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Screens:  - <a href=\"https://www.figma.com/file/Oo0zANd3WP9pwGxdAunrSf/Adductio---Website?node-id=1131%3A17416\">User registration</a>
         * @summary UIRegistration_GetPolicies Returns active policies (for user consent flows)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uIRegistrationGetPolicies: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/application/UIRegistration/policies`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * - No screen -
         * @summary UIRegistration_ValidateEmailWithToken This endpoint URL is used as the \"confirm\" link in the email address confirmation email.
         * @param {string} [token] 
         * @param {string} [id] 
         * @param {string} [email] 
         * @param {string} [lang] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uIRegistrationValidateEmailWithToken: async (token?: string, id?: string, email?: string, lang?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/application/UIRegistration/emailconfirmation-tkn`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (token !== undefined) {
                localVarQueryParameter['token'] = token;
            }

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (email !== undefined) {
                localVarQueryParameter['email'] = email;
            }

            if (lang !== undefined) {
                localVarQueryParameter['lang'] = lang;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UIRegistrationApi - functional programming interface
 * @export
 */
export const UIRegistrationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UIRegistrationApiAxiosParamCreator(configuration)
    return {
        /**
         * Screens:  - <a href=\"https://www.figma.com/file/Oo0zANd3WP9pwGxdAunrSf/Adductio---Website?node-id=1131%3A17416\">User registration</a>
         * @summary UIRegistration_CreateAppUser Handles an app user registration form submission
         * @param {UsersRegisterUserCommand} [usersRegisterUserCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uIRegistrationCreateAppUser(usersRegisterUserCommand?: UsersRegisterUserCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseMessage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uIRegistrationCreateAppUser(usersRegisterUserCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Screens:  - <a href=\"https://www.figma.com/file/Oo0zANd3WP9pwGxdAunrSf/Adductio---Website?node-id=1131%3A17416\">Brand user registration</a>
         * @summary UIRegistration_CreateBrandUser Handles a brand user registration form submission
         * @param {UsersRegisterBrandUserCommand} [usersRegisterBrandUserCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uIRegistrationCreateBrandUser(usersRegisterBrandUserCommand?: UsersRegisterBrandUserCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseMessage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uIRegistrationCreateBrandUser(usersRegisterBrandUserCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary UIRegistration_ForgotPassword 
         * @param {ForgotPasswordForgotPasswordSendMailCommand} [forgotPasswordForgotPasswordSendMailCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uIRegistrationForgotPassword(forgotPasswordForgotPasswordSendMailCommand?: ForgotPasswordForgotPasswordSendMailCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseMessage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uIRegistrationForgotPassword(forgotPasswordForgotPasswordSendMailCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary UIRegistration_ForgotPasswordSetNew 
         * @param {RecoverPasswordForgotPasswordSetNewCommand} [recoverPasswordForgotPasswordSetNewCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uIRegistrationForgotPasswordSetNew(recoverPasswordForgotPasswordSetNewCommand?: RecoverPasswordForgotPasswordSetNewCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseMessage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uIRegistrationForgotPasswordSetNew(recoverPasswordForgotPasswordSetNewCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Screens:  - <a href=\"https://www.figma.com/file/Oo0zANd3WP9pwGxdAunrSf/Adductio---Website?node-id=1131%3A17416\">User registration</a>
         * @summary UIRegistration_GetPolicies Returns active policies (for user consent flows)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uIRegistrationGetPolicies(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagedResponseDtoOfPolicyUiDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uIRegistrationGetPolicies(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * - No screen -
         * @summary UIRegistration_ValidateEmailWithToken This endpoint URL is used as the \"confirm\" link in the email address confirmation email.
         * @param {string} [token] 
         * @param {string} [id] 
         * @param {string} [email] 
         * @param {string} [lang] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uIRegistrationValidateEmailWithToken(token?: string, id?: string, email?: string, lang?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uIRegistrationValidateEmailWithToken(token, id, email, lang, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UIRegistrationApi - factory interface
 * @export
 */
export const UIRegistrationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UIRegistrationApiFp(configuration)
    return {
        /**
         * Screens:  - <a href=\"https://www.figma.com/file/Oo0zANd3WP9pwGxdAunrSf/Adductio---Website?node-id=1131%3A17416\">User registration</a>
         * @summary UIRegistration_CreateAppUser Handles an app user registration form submission
         * @param {UsersRegisterUserCommand} [usersRegisterUserCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uIRegistrationCreateAppUser(usersRegisterUserCommand?: UsersRegisterUserCommand, options?: any): AxiosPromise<ResponseMessage> {
            return localVarFp.uIRegistrationCreateAppUser(usersRegisterUserCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * Screens:  - <a href=\"https://www.figma.com/file/Oo0zANd3WP9pwGxdAunrSf/Adductio---Website?node-id=1131%3A17416\">Brand user registration</a>
         * @summary UIRegistration_CreateBrandUser Handles a brand user registration form submission
         * @param {UsersRegisterBrandUserCommand} [usersRegisterBrandUserCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uIRegistrationCreateBrandUser(usersRegisterBrandUserCommand?: UsersRegisterBrandUserCommand, options?: any): AxiosPromise<ResponseMessage> {
            return localVarFp.uIRegistrationCreateBrandUser(usersRegisterBrandUserCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary UIRegistration_ForgotPassword 
         * @param {ForgotPasswordForgotPasswordSendMailCommand} [forgotPasswordForgotPasswordSendMailCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uIRegistrationForgotPassword(forgotPasswordForgotPasswordSendMailCommand?: ForgotPasswordForgotPasswordSendMailCommand, options?: any): AxiosPromise<ResponseMessage> {
            return localVarFp.uIRegistrationForgotPassword(forgotPasswordForgotPasswordSendMailCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary UIRegistration_ForgotPasswordSetNew 
         * @param {RecoverPasswordForgotPasswordSetNewCommand} [recoverPasswordForgotPasswordSetNewCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uIRegistrationForgotPasswordSetNew(recoverPasswordForgotPasswordSetNewCommand?: RecoverPasswordForgotPasswordSetNewCommand, options?: any): AxiosPromise<ResponseMessage> {
            return localVarFp.uIRegistrationForgotPasswordSetNew(recoverPasswordForgotPasswordSetNewCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * Screens:  - <a href=\"https://www.figma.com/file/Oo0zANd3WP9pwGxdAunrSf/Adductio---Website?node-id=1131%3A17416\">User registration</a>
         * @summary UIRegistration_GetPolicies Returns active policies (for user consent flows)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uIRegistrationGetPolicies(options?: any): AxiosPromise<PagedResponseDtoOfPolicyUiDTO> {
            return localVarFp.uIRegistrationGetPolicies(options).then((request) => request(axios, basePath));
        },
        /**
         * - No screen -
         * @summary UIRegistration_ValidateEmailWithToken This endpoint URL is used as the \"confirm\" link in the email address confirmation email.
         * @param {string} [token] 
         * @param {string} [id] 
         * @param {string} [email] 
         * @param {string} [lang] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uIRegistrationValidateEmailWithToken(token?: string, id?: string, email?: string, lang?: string, options?: any): AxiosPromise<void> {
            return localVarFp.uIRegistrationValidateEmailWithToken(token, id, email, lang, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UIRegistrationApi - object-oriented interface
 * @export
 * @class UIRegistrationApi
 * @extends {BaseAPI}
 */
export class UIRegistrationApi extends BaseAPI {
    /**
     * Screens:  - <a href=\"https://www.figma.com/file/Oo0zANd3WP9pwGxdAunrSf/Adductio---Website?node-id=1131%3A17416\">User registration</a>
     * @summary UIRegistration_CreateAppUser Handles an app user registration form submission
     * @param {UsersRegisterUserCommand} [usersRegisterUserCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UIRegistrationApi
     */
    public uIRegistrationCreateAppUser(usersRegisterUserCommand?: UsersRegisterUserCommand, options?: AxiosRequestConfig) {
        return UIRegistrationApiFp(this.configuration).uIRegistrationCreateAppUser(usersRegisterUserCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Screens:  - <a href=\"https://www.figma.com/file/Oo0zANd3WP9pwGxdAunrSf/Adductio---Website?node-id=1131%3A17416\">Brand user registration</a>
     * @summary UIRegistration_CreateBrandUser Handles a brand user registration form submission
     * @param {UsersRegisterBrandUserCommand} [usersRegisterBrandUserCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UIRegistrationApi
     */
    public uIRegistrationCreateBrandUser(usersRegisterBrandUserCommand?: UsersRegisterBrandUserCommand, options?: AxiosRequestConfig) {
        return UIRegistrationApiFp(this.configuration).uIRegistrationCreateBrandUser(usersRegisterBrandUserCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary UIRegistration_ForgotPassword 
     * @param {ForgotPasswordForgotPasswordSendMailCommand} [forgotPasswordForgotPasswordSendMailCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UIRegistrationApi
     */
    public uIRegistrationForgotPassword(forgotPasswordForgotPasswordSendMailCommand?: ForgotPasswordForgotPasswordSendMailCommand, options?: AxiosRequestConfig) {
        return UIRegistrationApiFp(this.configuration).uIRegistrationForgotPassword(forgotPasswordForgotPasswordSendMailCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary UIRegistration_ForgotPasswordSetNew 
     * @param {RecoverPasswordForgotPasswordSetNewCommand} [recoverPasswordForgotPasswordSetNewCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UIRegistrationApi
     */
    public uIRegistrationForgotPasswordSetNew(recoverPasswordForgotPasswordSetNewCommand?: RecoverPasswordForgotPasswordSetNewCommand, options?: AxiosRequestConfig) {
        return UIRegistrationApiFp(this.configuration).uIRegistrationForgotPasswordSetNew(recoverPasswordForgotPasswordSetNewCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Screens:  - <a href=\"https://www.figma.com/file/Oo0zANd3WP9pwGxdAunrSf/Adductio---Website?node-id=1131%3A17416\">User registration</a>
     * @summary UIRegistration_GetPolicies Returns active policies (for user consent flows)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UIRegistrationApi
     */
    public uIRegistrationGetPolicies(options?: AxiosRequestConfig) {
        return UIRegistrationApiFp(this.configuration).uIRegistrationGetPolicies(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * - No screen -
     * @summary UIRegistration_ValidateEmailWithToken This endpoint URL is used as the \"confirm\" link in the email address confirmation email.
     * @param {string} [token] 
     * @param {string} [id] 
     * @param {string} [email] 
     * @param {string} [lang] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UIRegistrationApi
     */
    public uIRegistrationValidateEmailWithToken(token?: string, id?: string, email?: string, lang?: string, options?: AxiosRequestConfig) {
        return UIRegistrationApiFp(this.configuration).uIRegistrationValidateEmailWithToken(token, id, email, lang, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UIUserApi - axios parameter creator
 * @export
 */
export const UIUserApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary UIUser_ChangePassword  (Auth)
         * @param {UsersChangePasswordCommand} [usersChangePasswordCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uIUserChangePassword: async (usersChangePasswordCommand?: UsersChangePasswordCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/application/UIUser/user/password`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(usersChangePasswordCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary UIUser_Delete  (Auth)
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uIUserDelete: async (body?: object, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/application/UIUser/user`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary UIUser_Export  (Auth)
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uIUserExport: async (body?: object, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/application/UIUser/export`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary UIUser_Read  (Auth)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uIUserRead: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/application/UIUser/user`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary UIUser_Update  (Auth)
         * @param {UserUIDTO} [userUIDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uIUserUpdate: async (userUIDTO?: UserUIDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/application/UIUser/user`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userUIDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UIUserApi - functional programming interface
 * @export
 */
export const UIUserApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UIUserApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary UIUser_ChangePassword  (Auth)
         * @param {UsersChangePasswordCommand} [usersChangePasswordCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uIUserChangePassword(usersChangePasswordCommand?: UsersChangePasswordCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uIUserChangePassword(usersChangePasswordCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary UIUser_Delete  (Auth)
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uIUserDelete(body?: object, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseMessage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uIUserDelete(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary UIUser_Export  (Auth)
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uIUserExport(body?: object, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uIUserExport(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary UIUser_Read  (Auth)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uIUserRead(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserUIDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uIUserRead(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary UIUser_Update  (Auth)
         * @param {UserUIDTO} [userUIDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uIUserUpdate(userUIDTO?: UserUIDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserUIDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uIUserUpdate(userUIDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UIUserApi - factory interface
 * @export
 */
export const UIUserApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UIUserApiFp(configuration)
    return {
        /**
         * 
         * @summary UIUser_ChangePassword  (Auth)
         * @param {UsersChangePasswordCommand} [usersChangePasswordCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uIUserChangePassword(usersChangePasswordCommand?: UsersChangePasswordCommand, options?: any): AxiosPromise<void> {
            return localVarFp.uIUserChangePassword(usersChangePasswordCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary UIUser_Delete  (Auth)
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uIUserDelete(body?: object, options?: any): AxiosPromise<ResponseMessage> {
            return localVarFp.uIUserDelete(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary UIUser_Export  (Auth)
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uIUserExport(body?: object, options?: any): AxiosPromise<void> {
            return localVarFp.uIUserExport(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary UIUser_Read  (Auth)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uIUserRead(options?: any): AxiosPromise<UserUIDTO> {
            return localVarFp.uIUserRead(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary UIUser_Update  (Auth)
         * @param {UserUIDTO} [userUIDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uIUserUpdate(userUIDTO?: UserUIDTO, options?: any): AxiosPromise<UserUIDTO> {
            return localVarFp.uIUserUpdate(userUIDTO, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UIUserApi - object-oriented interface
 * @export
 * @class UIUserApi
 * @extends {BaseAPI}
 */
export class UIUserApi extends BaseAPI {
    /**
     * 
     * @summary UIUser_ChangePassword  (Auth)
     * @param {UsersChangePasswordCommand} [usersChangePasswordCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UIUserApi
     */
    public uIUserChangePassword(usersChangePasswordCommand?: UsersChangePasswordCommand, options?: AxiosRequestConfig) {
        return UIUserApiFp(this.configuration).uIUserChangePassword(usersChangePasswordCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary UIUser_Delete  (Auth)
     * @param {object} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UIUserApi
     */
    public uIUserDelete(body?: object, options?: AxiosRequestConfig) {
        return UIUserApiFp(this.configuration).uIUserDelete(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary UIUser_Export  (Auth)
     * @param {object} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UIUserApi
     */
    public uIUserExport(body?: object, options?: AxiosRequestConfig) {
        return UIUserApiFp(this.configuration).uIUserExport(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary UIUser_Read  (Auth)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UIUserApi
     */
    public uIUserRead(options?: AxiosRequestConfig) {
        return UIUserApiFp(this.configuration).uIUserRead(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary UIUser_Update  (Auth)
     * @param {UserUIDTO} [userUIDTO] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UIUserApi
     */
    public uIUserUpdate(userUIDTO?: UserUIDTO, options?: AxiosRequestConfig) {
        return UIUserApiFp(this.configuration).uIUserUpdate(userUIDTO, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UnitApi - axios parameter creator
 * @export
 */
export const UnitApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Unit_Create 
         * @param {UnitFormDTO} [unitFormDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unitCreate: async (unitFormDTO?: UnitFormDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/application/Unit`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(unitFormDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Unit_Delete 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unitDelete: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('unitDelete', 'id', id)
            const localVarPath = `/api/v1/application/Unit/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Unit_DeleteMany 
         * @param {Array<number>} [ids] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unitDeleteMany: async (ids?: Array<number>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/application/Unit`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (ids) {
                localVarQueryParameter['ids'] = ids;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Unit_GetMany 
         * @param {string} [sortBy] 
         * @param {'asc' | 'desc'} [sortDirection] Values: asc, desc
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {boolean} [skipGlobalFilters] 
         * @param {any} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unitGetMany: async (sortBy?: string, sortDirection?: 'asc' | 'desc', page?: number, pageSize?: number, skipGlobalFilters?: boolean, body?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/application/Unit/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (sortDirection !== undefined) {
                localVarQueryParameter['sortDirection'] = sortDirection;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (skipGlobalFilters !== undefined) {
                localVarQueryParameter['skipGlobalFilters'] = skipGlobalFilters;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Unit_Read 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unitRead: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('unitRead', 'id', id)
            const localVarPath = `/api/v1/application/Unit/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Unit_SoftDelete 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unitSoftDelete: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('unitSoftDelete', 'id', id)
            const localVarPath = `/api/v1/application/Unit/{id}/soft`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Unit_Update 
         * @param {number} id 
         * @param {UnitFormDTO} [unitFormDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unitUpdate: async (id: number, unitFormDTO?: UnitFormDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('unitUpdate', 'id', id)
            const localVarPath = `/api/v1/application/Unit/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(unitFormDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Unit_UpdatePartial 
         * @param {number} id 
         * @param {Array<Operation>} [operation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unitUpdatePartial: async (id: number, operation?: Array<Operation>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('unitUpdatePartial', 'id', id)
            const localVarPath = `/api/v1/application/Unit/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(operation, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UnitApi - functional programming interface
 * @export
 */
export const UnitApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UnitApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Unit_Create 
         * @param {UnitFormDTO} [unitFormDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async unitCreate(unitFormDTO?: UnitFormDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UnitFormDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.unitCreate(unitFormDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Unit_Delete 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async unitDelete(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.unitDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Unit_DeleteMany 
         * @param {Array<number>} [ids] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async unitDeleteMany(ids?: Array<number>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<number>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.unitDeleteMany(ids, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Unit_GetMany 
         * @param {string} [sortBy] 
         * @param {'asc' | 'desc'} [sortDirection] Values: asc, desc
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {boolean} [skipGlobalFilters] 
         * @param {any} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async unitGetMany(sortBy?: string, sortDirection?: 'asc' | 'desc', page?: number, pageSize?: number, skipGlobalFilters?: boolean, body?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagedResponseDtoOfUnitListDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.unitGetMany(sortBy, sortDirection, page, pageSize, skipGlobalFilters, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Unit_Read 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async unitRead(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UnitFormDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.unitRead(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Unit_SoftDelete 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async unitSoftDelete(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.unitSoftDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Unit_Update 
         * @param {number} id 
         * @param {UnitFormDTO} [unitFormDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async unitUpdate(id: number, unitFormDTO?: UnitFormDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UnitFormDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.unitUpdate(id, unitFormDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Unit_UpdatePartial 
         * @param {number} id 
         * @param {Array<Operation>} [operation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async unitUpdatePartial(id: number, operation?: Array<Operation>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UnitFormDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.unitUpdatePartial(id, operation, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UnitApi - factory interface
 * @export
 */
export const UnitApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UnitApiFp(configuration)
    return {
        /**
         * 
         * @summary Unit_Create 
         * @param {UnitFormDTO} [unitFormDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unitCreate(unitFormDTO?: UnitFormDTO, options?: any): AxiosPromise<UnitFormDTO> {
            return localVarFp.unitCreate(unitFormDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Unit_Delete 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unitDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.unitDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Unit_DeleteMany 
         * @param {Array<number>} [ids] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unitDeleteMany(ids?: Array<number>, options?: any): AxiosPromise<Array<number>> {
            return localVarFp.unitDeleteMany(ids, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Unit_GetMany 
         * @param {string} [sortBy] 
         * @param {'asc' | 'desc'} [sortDirection] Values: asc, desc
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {boolean} [skipGlobalFilters] 
         * @param {any} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unitGetMany(sortBy?: string, sortDirection?: 'asc' | 'desc', page?: number, pageSize?: number, skipGlobalFilters?: boolean, body?: any, options?: any): AxiosPromise<PagedResponseDtoOfUnitListDTO> {
            return localVarFp.unitGetMany(sortBy, sortDirection, page, pageSize, skipGlobalFilters, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Unit_Read 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unitRead(id: number, options?: any): AxiosPromise<UnitFormDTO> {
            return localVarFp.unitRead(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Unit_SoftDelete 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unitSoftDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.unitSoftDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Unit_Update 
         * @param {number} id 
         * @param {UnitFormDTO} [unitFormDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unitUpdate(id: number, unitFormDTO?: UnitFormDTO, options?: any): AxiosPromise<UnitFormDTO> {
            return localVarFp.unitUpdate(id, unitFormDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Unit_UpdatePartial 
         * @param {number} id 
         * @param {Array<Operation>} [operation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unitUpdatePartial(id: number, operation?: Array<Operation>, options?: any): AxiosPromise<UnitFormDTO> {
            return localVarFp.unitUpdatePartial(id, operation, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UnitApi - object-oriented interface
 * @export
 * @class UnitApi
 * @extends {BaseAPI}
 */
export class UnitApi extends BaseAPI {
    /**
     * 
     * @summary Unit_Create 
     * @param {UnitFormDTO} [unitFormDTO] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UnitApi
     */
    public unitCreate(unitFormDTO?: UnitFormDTO, options?: AxiosRequestConfig) {
        return UnitApiFp(this.configuration).unitCreate(unitFormDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Unit_Delete 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UnitApi
     */
    public unitDelete(id: number, options?: AxiosRequestConfig) {
        return UnitApiFp(this.configuration).unitDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Unit_DeleteMany 
     * @param {Array<number>} [ids] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UnitApi
     */
    public unitDeleteMany(ids?: Array<number>, options?: AxiosRequestConfig) {
        return UnitApiFp(this.configuration).unitDeleteMany(ids, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Unit_GetMany 
     * @param {string} [sortBy] 
     * @param {'asc' | 'desc'} [sortDirection] Values: asc, desc
     * @param {number} [page] 
     * @param {number} [pageSize] 
     * @param {boolean} [skipGlobalFilters] 
     * @param {any} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UnitApi
     */
    public unitGetMany(sortBy?: string, sortDirection?: 'asc' | 'desc', page?: number, pageSize?: number, skipGlobalFilters?: boolean, body?: any, options?: AxiosRequestConfig) {
        return UnitApiFp(this.configuration).unitGetMany(sortBy, sortDirection, page, pageSize, skipGlobalFilters, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Unit_Read 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UnitApi
     */
    public unitRead(id: number, options?: AxiosRequestConfig) {
        return UnitApiFp(this.configuration).unitRead(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Unit_SoftDelete 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UnitApi
     */
    public unitSoftDelete(id: number, options?: AxiosRequestConfig) {
        return UnitApiFp(this.configuration).unitSoftDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Unit_Update 
     * @param {number} id 
     * @param {UnitFormDTO} [unitFormDTO] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UnitApi
     */
    public unitUpdate(id: number, unitFormDTO?: UnitFormDTO, options?: AxiosRequestConfig) {
        return UnitApiFp(this.configuration).unitUpdate(id, unitFormDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Unit_UpdatePartial 
     * @param {number} id 
     * @param {Array<Operation>} [operation] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UnitApi
     */
    public unitUpdatePartial(id: number, operation?: Array<Operation>, options?: AxiosRequestConfig) {
        return UnitApiFp(this.configuration).unitUpdatePartial(id, operation, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UnitTypeApi - axios parameter creator
 * @export
 */
export const UnitTypeApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary UnitType_Create 
         * @param {UnitTypeFormDTO} [unitTypeFormDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unitTypeCreate: async (unitTypeFormDTO?: UnitTypeFormDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/application/UnitType`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(unitTypeFormDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary UnitType_Delete 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unitTypeDelete: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('unitTypeDelete', 'id', id)
            const localVarPath = `/api/v1/application/UnitType/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary UnitType_DeleteMany 
         * @param {Array<number>} [ids] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unitTypeDeleteMany: async (ids?: Array<number>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/application/UnitType`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (ids) {
                localVarQueryParameter['ids'] = ids;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary UnitType_GetMany 
         * @param {string} [sortBy] 
         * @param {'asc' | 'desc'} [sortDirection] Values: asc, desc
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {boolean} [skipGlobalFilters] 
         * @param {any} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unitTypeGetMany: async (sortBy?: string, sortDirection?: 'asc' | 'desc', page?: number, pageSize?: number, skipGlobalFilters?: boolean, body?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/application/UnitType/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (sortDirection !== undefined) {
                localVarQueryParameter['sortDirection'] = sortDirection;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (skipGlobalFilters !== undefined) {
                localVarQueryParameter['skipGlobalFilters'] = skipGlobalFilters;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary UnitType_Read 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unitTypeRead: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('unitTypeRead', 'id', id)
            const localVarPath = `/api/v1/application/UnitType/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary UnitType_SoftDelete 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unitTypeSoftDelete: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('unitTypeSoftDelete', 'id', id)
            const localVarPath = `/api/v1/application/UnitType/{id}/soft`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary UnitType_Update 
         * @param {number} id 
         * @param {UnitTypeFormDTO} [unitTypeFormDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unitTypeUpdate: async (id: number, unitTypeFormDTO?: UnitTypeFormDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('unitTypeUpdate', 'id', id)
            const localVarPath = `/api/v1/application/UnitType/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(unitTypeFormDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary UnitType_UpdatePartial 
         * @param {number} id 
         * @param {Array<Operation>} [operation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unitTypeUpdatePartial: async (id: number, operation?: Array<Operation>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('unitTypeUpdatePartial', 'id', id)
            const localVarPath = `/api/v1/application/UnitType/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(operation, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UnitTypeApi - functional programming interface
 * @export
 */
export const UnitTypeApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UnitTypeApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary UnitType_Create 
         * @param {UnitTypeFormDTO} [unitTypeFormDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async unitTypeCreate(unitTypeFormDTO?: UnitTypeFormDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UnitTypeFormDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.unitTypeCreate(unitTypeFormDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary UnitType_Delete 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async unitTypeDelete(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.unitTypeDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary UnitType_DeleteMany 
         * @param {Array<number>} [ids] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async unitTypeDeleteMany(ids?: Array<number>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<number>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.unitTypeDeleteMany(ids, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary UnitType_GetMany 
         * @param {string} [sortBy] 
         * @param {'asc' | 'desc'} [sortDirection] Values: asc, desc
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {boolean} [skipGlobalFilters] 
         * @param {any} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async unitTypeGetMany(sortBy?: string, sortDirection?: 'asc' | 'desc', page?: number, pageSize?: number, skipGlobalFilters?: boolean, body?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagedResponseDtoOfUnitTypeListDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.unitTypeGetMany(sortBy, sortDirection, page, pageSize, skipGlobalFilters, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary UnitType_Read 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async unitTypeRead(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UnitTypeFormDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.unitTypeRead(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary UnitType_SoftDelete 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async unitTypeSoftDelete(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.unitTypeSoftDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary UnitType_Update 
         * @param {number} id 
         * @param {UnitTypeFormDTO} [unitTypeFormDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async unitTypeUpdate(id: number, unitTypeFormDTO?: UnitTypeFormDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UnitTypeFormDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.unitTypeUpdate(id, unitTypeFormDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary UnitType_UpdatePartial 
         * @param {number} id 
         * @param {Array<Operation>} [operation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async unitTypeUpdatePartial(id: number, operation?: Array<Operation>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UnitTypeFormDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.unitTypeUpdatePartial(id, operation, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UnitTypeApi - factory interface
 * @export
 */
export const UnitTypeApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UnitTypeApiFp(configuration)
    return {
        /**
         * 
         * @summary UnitType_Create 
         * @param {UnitTypeFormDTO} [unitTypeFormDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unitTypeCreate(unitTypeFormDTO?: UnitTypeFormDTO, options?: any): AxiosPromise<UnitTypeFormDTO> {
            return localVarFp.unitTypeCreate(unitTypeFormDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary UnitType_Delete 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unitTypeDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.unitTypeDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary UnitType_DeleteMany 
         * @param {Array<number>} [ids] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unitTypeDeleteMany(ids?: Array<number>, options?: any): AxiosPromise<Array<number>> {
            return localVarFp.unitTypeDeleteMany(ids, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary UnitType_GetMany 
         * @param {string} [sortBy] 
         * @param {'asc' | 'desc'} [sortDirection] Values: asc, desc
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {boolean} [skipGlobalFilters] 
         * @param {any} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unitTypeGetMany(sortBy?: string, sortDirection?: 'asc' | 'desc', page?: number, pageSize?: number, skipGlobalFilters?: boolean, body?: any, options?: any): AxiosPromise<PagedResponseDtoOfUnitTypeListDTO> {
            return localVarFp.unitTypeGetMany(sortBy, sortDirection, page, pageSize, skipGlobalFilters, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary UnitType_Read 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unitTypeRead(id: number, options?: any): AxiosPromise<UnitTypeFormDTO> {
            return localVarFp.unitTypeRead(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary UnitType_SoftDelete 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unitTypeSoftDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.unitTypeSoftDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary UnitType_Update 
         * @param {number} id 
         * @param {UnitTypeFormDTO} [unitTypeFormDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unitTypeUpdate(id: number, unitTypeFormDTO?: UnitTypeFormDTO, options?: any): AxiosPromise<UnitTypeFormDTO> {
            return localVarFp.unitTypeUpdate(id, unitTypeFormDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary UnitType_UpdatePartial 
         * @param {number} id 
         * @param {Array<Operation>} [operation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unitTypeUpdatePartial(id: number, operation?: Array<Operation>, options?: any): AxiosPromise<UnitTypeFormDTO> {
            return localVarFp.unitTypeUpdatePartial(id, operation, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UnitTypeApi - object-oriented interface
 * @export
 * @class UnitTypeApi
 * @extends {BaseAPI}
 */
export class UnitTypeApi extends BaseAPI {
    /**
     * 
     * @summary UnitType_Create 
     * @param {UnitTypeFormDTO} [unitTypeFormDTO] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UnitTypeApi
     */
    public unitTypeCreate(unitTypeFormDTO?: UnitTypeFormDTO, options?: AxiosRequestConfig) {
        return UnitTypeApiFp(this.configuration).unitTypeCreate(unitTypeFormDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary UnitType_Delete 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UnitTypeApi
     */
    public unitTypeDelete(id: number, options?: AxiosRequestConfig) {
        return UnitTypeApiFp(this.configuration).unitTypeDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary UnitType_DeleteMany 
     * @param {Array<number>} [ids] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UnitTypeApi
     */
    public unitTypeDeleteMany(ids?: Array<number>, options?: AxiosRequestConfig) {
        return UnitTypeApiFp(this.configuration).unitTypeDeleteMany(ids, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary UnitType_GetMany 
     * @param {string} [sortBy] 
     * @param {'asc' | 'desc'} [sortDirection] Values: asc, desc
     * @param {number} [page] 
     * @param {number} [pageSize] 
     * @param {boolean} [skipGlobalFilters] 
     * @param {any} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UnitTypeApi
     */
    public unitTypeGetMany(sortBy?: string, sortDirection?: 'asc' | 'desc', page?: number, pageSize?: number, skipGlobalFilters?: boolean, body?: any, options?: AxiosRequestConfig) {
        return UnitTypeApiFp(this.configuration).unitTypeGetMany(sortBy, sortDirection, page, pageSize, skipGlobalFilters, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary UnitType_Read 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UnitTypeApi
     */
    public unitTypeRead(id: number, options?: AxiosRequestConfig) {
        return UnitTypeApiFp(this.configuration).unitTypeRead(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary UnitType_SoftDelete 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UnitTypeApi
     */
    public unitTypeSoftDelete(id: number, options?: AxiosRequestConfig) {
        return UnitTypeApiFp(this.configuration).unitTypeSoftDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary UnitType_Update 
     * @param {number} id 
     * @param {UnitTypeFormDTO} [unitTypeFormDTO] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UnitTypeApi
     */
    public unitTypeUpdate(id: number, unitTypeFormDTO?: UnitTypeFormDTO, options?: AxiosRequestConfig) {
        return UnitTypeApiFp(this.configuration).unitTypeUpdate(id, unitTypeFormDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary UnitType_UpdatePartial 
     * @param {number} id 
     * @param {Array<Operation>} [operation] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UnitTypeApi
     */
    public unitTypeUpdatePartial(id: number, operation?: Array<Operation>, options?: AxiosRequestConfig) {
        return UnitTypeApiFp(this.configuration).unitTypeUpdatePartial(id, operation, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UserApi - axios parameter creator
 * @export
 */
export const UserApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary User_Create 
         * @param {UserFormDTO} [userFormDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userCreate: async (userFormDTO?: UserFormDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/application/User`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userFormDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary User_Delete 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('userDelete', 'id', id)
            const localVarPath = `/api/v1/application/User/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary User_DeleteMany 
         * @param {Array<string>} [ids] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userDeleteMany: async (ids?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/application/User`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (ids) {
                localVarQueryParameter['ids'] = ids;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary User_GetMany Retrieve a list of records of entity type
         * @param {string} [sortBy] Name of entity property to sort by
         * @param {'asc' | 'desc'} [sortDirection] Values: asc, desc
         * @param {number} [page] First page to retrieve (0 - based)
         * @param {number} [pageSize] Page size
         * @param {boolean} [skipGlobalFilters] If true ignores soft-deleted items
         * @param {any} [body] The criteria to follow
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userGetMany: async (sortBy?: string, sortDirection?: 'asc' | 'desc', page?: number, pageSize?: number, skipGlobalFilters?: boolean, body?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/application/User/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (sortDirection !== undefined) {
                localVarQueryParameter['sortDirection'] = sortDirection;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (skipGlobalFilters !== undefined) {
                localVarQueryParameter['skipGlobalFilters'] = skipGlobalFilters;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary User_Read 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userRead: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('userRead', 'id', id)
            const localVarPath = `/api/v1/application/User/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary User_SoftDelete 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userSoftDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('userSoftDelete', 'id', id)
            const localVarPath = `/api/v1/application/User/{id}/soft`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary User_Update 
         * @param {string} id 
         * @param {UsersUpsertUserCommand} [usersUpsertUserCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userUpdate: async (id: string, usersUpsertUserCommand?: UsersUpsertUserCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('userUpdate', 'id', id)
            const localVarPath = `/api/v1/application/User/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(usersUpsertUserCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary User_UpdatePartial 
         * @param {string} id 
         * @param {Array<Operation>} [operation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userUpdatePartial: async (id: string, operation?: Array<Operation>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('userUpdatePartial', 'id', id)
            const localVarPath = `/api/v1/application/User/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(operation, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserApi - functional programming interface
 * @export
 */
export const UserApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary User_Create 
         * @param {UserFormDTO} [userFormDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userCreate(userFormDTO?: UserFormDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserFormDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userCreate(userFormDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary User_Delete 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary User_DeleteMany 
         * @param {Array<string>} [ids] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userDeleteMany(ids?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userDeleteMany(ids, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary User_GetMany Retrieve a list of records of entity type
         * @param {string} [sortBy] Name of entity property to sort by
         * @param {'asc' | 'desc'} [sortDirection] Values: asc, desc
         * @param {number} [page] First page to retrieve (0 - based)
         * @param {number} [pageSize] Page size
         * @param {boolean} [skipGlobalFilters] If true ignores soft-deleted items
         * @param {any} [body] The criteria to follow
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userGetMany(sortBy?: string, sortDirection?: 'asc' | 'desc', page?: number, pageSize?: number, skipGlobalFilters?: boolean, body?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagedResponseDtoOfUserListDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userGetMany(sortBy, sortDirection, page, pageSize, skipGlobalFilters, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary User_Read 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userRead(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserFormDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userRead(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary User_SoftDelete 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userSoftDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userSoftDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary User_Update 
         * @param {string} id 
         * @param {UsersUpsertUserCommand} [usersUpsertUserCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userUpdate(id: string, usersUpsertUserCommand?: UsersUpsertUserCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserFormDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userUpdate(id, usersUpsertUserCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary User_UpdatePartial 
         * @param {string} id 
         * @param {Array<Operation>} [operation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userUpdatePartial(id: string, operation?: Array<Operation>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserFormDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userUpdatePartial(id, operation, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UserApi - factory interface
 * @export
 */
export const UserApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserApiFp(configuration)
    return {
        /**
         * 
         * @summary User_Create 
         * @param {UserFormDTO} [userFormDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userCreate(userFormDTO?: UserFormDTO, options?: any): AxiosPromise<UserFormDTO> {
            return localVarFp.userCreate(userFormDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary User_Delete 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userDelete(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.userDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary User_DeleteMany 
         * @param {Array<string>} [ids] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userDeleteMany(ids?: Array<string>, options?: any): AxiosPromise<Array<string>> {
            return localVarFp.userDeleteMany(ids, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary User_GetMany Retrieve a list of records of entity type
         * @param {string} [sortBy] Name of entity property to sort by
         * @param {'asc' | 'desc'} [sortDirection] Values: asc, desc
         * @param {number} [page] First page to retrieve (0 - based)
         * @param {number} [pageSize] Page size
         * @param {boolean} [skipGlobalFilters] If true ignores soft-deleted items
         * @param {any} [body] The criteria to follow
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userGetMany(sortBy?: string, sortDirection?: 'asc' | 'desc', page?: number, pageSize?: number, skipGlobalFilters?: boolean, body?: any, options?: any): AxiosPromise<PagedResponseDtoOfUserListDTO> {
            return localVarFp.userGetMany(sortBy, sortDirection, page, pageSize, skipGlobalFilters, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary User_Read 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userRead(id: string, options?: any): AxiosPromise<UserFormDTO> {
            return localVarFp.userRead(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary User_SoftDelete 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userSoftDelete(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.userSoftDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary User_Update 
         * @param {string} id 
         * @param {UsersUpsertUserCommand} [usersUpsertUserCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userUpdate(id: string, usersUpsertUserCommand?: UsersUpsertUserCommand, options?: any): AxiosPromise<UserFormDTO> {
            return localVarFp.userUpdate(id, usersUpsertUserCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary User_UpdatePartial 
         * @param {string} id 
         * @param {Array<Operation>} [operation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userUpdatePartial(id: string, operation?: Array<Operation>, options?: any): AxiosPromise<UserFormDTO> {
            return localVarFp.userUpdatePartial(id, operation, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserApi - object-oriented interface
 * @export
 * @class UserApi
 * @extends {BaseAPI}
 */
export class UserApi extends BaseAPI {
    /**
     * 
     * @summary User_Create 
     * @param {UserFormDTO} [userFormDTO] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userCreate(userFormDTO?: UserFormDTO, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).userCreate(userFormDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary User_Delete 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userDelete(id: string, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).userDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary User_DeleteMany 
     * @param {Array<string>} [ids] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userDeleteMany(ids?: Array<string>, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).userDeleteMany(ids, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary User_GetMany Retrieve a list of records of entity type
     * @param {string} [sortBy] Name of entity property to sort by
     * @param {'asc' | 'desc'} [sortDirection] Values: asc, desc
     * @param {number} [page] First page to retrieve (0 - based)
     * @param {number} [pageSize] Page size
     * @param {boolean} [skipGlobalFilters] If true ignores soft-deleted items
     * @param {any} [body] The criteria to follow
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userGetMany(sortBy?: string, sortDirection?: 'asc' | 'desc', page?: number, pageSize?: number, skipGlobalFilters?: boolean, body?: any, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).userGetMany(sortBy, sortDirection, page, pageSize, skipGlobalFilters, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary User_Read 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userRead(id: string, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).userRead(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary User_SoftDelete 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userSoftDelete(id: string, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).userSoftDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary User_Update 
     * @param {string} id 
     * @param {UsersUpsertUserCommand} [usersUpsertUserCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userUpdate(id: string, usersUpsertUserCommand?: UsersUpsertUserCommand, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).userUpdate(id, usersUpsertUserCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary User_UpdatePartial 
     * @param {string} id 
     * @param {Array<Operation>} [operation] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userUpdatePartial(id: string, operation?: Array<Operation>, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).userUpdatePartial(id, operation, options).then((request) => request(this.axios, this.basePath));
    }
}


