
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * React-Admin Create/Update components for: User Resource
 */

import * as RA from 'react-admin';
import UserIcon from '@mui/icons-material/Bookmark';
import UserList from './User_List';
import InputHelper from '../common/inputHelper';
import { SDK, AppService } from 'src/ts-axios-sdk/SDK'

const BaseForm = (props: any) => {

    return (

        <RA.TabbedForm warnWhenUnsavedChanges {...props}>
            
            <RA.FormTab label="Common" >

                {props.type === "edit" && <RA.TextInput disabled source="id" /> }

                <RA.TextInput  source="name" />
                <RA.TextInput  source="email" />

                <RA.BooleanInput source="approved" />

                <RA.PasswordInput placeholder='Leave blank to keep existing' source="password" />
                <RA.PasswordInput source="passwordConfirm" />

                <RA.BooleanInput source="emailConfirmed" />
                <RA.BooleanInput source="lockoutEnabled" />

                <RA.NumberInput  source="accessFailedCount" />

                <RA.ReferenceArrayInput source="roles" reference="Role" 
                    perPage={10} sort={{ field: null, order: 'ASC' }}
                    parse={InputHelper.parseIdToObject} format={InputHelper.formatObjectToId} >
                    <RA.SelectArrayInput parse={InputHelper.parseIdToObject} format={InputHelper.formatObjectToId} 
                        validate={[RA.required('Required')]}
                        />
                </RA.ReferenceArrayInput>  

            </RA.FormTab>

            <RA.FormTab label="Base Profile" >
                <RA.TextInput  source="baseProfile.job" label="Job"  />
                <RA.TextInput  source="baseProfile.company" label="Company" />
                
                <RA.TextInput  source="baseProfile.streetAddress" label="Street"  />
                <RA.TextInput  source="baseProfile.city" label="City"  />
                <RA.TextInput  source="baseProfile.postalCode" label="Postal Code"  />
                <RA.ReferenceInput
                    sx={{ width: '250px' }}
                    source='baseProfile.countryId'
                    perPage={0} // retrieve all 
                    sort={{ field: 'name', order: 'ASC' }}
                    reference="country">
                    <RA.AutocompleteInput optionText="name" label="Country" />
                </RA.ReferenceInput>

                <RA.ReferenceInput source="baseProfile.branchId" reference="TechnicalBranch" >
                    <RA.SelectInput optionText="name" label="Branch"/>
                </RA.ReferenceInput>
                
            </RA.FormTab>

            <RA.FormTab label="Brand Editor Profile" >
                <RA.ReferenceArrayInput source="brandEditorProfile.brands" reference="Brand" 
                    perPage={0} 
                    //parse={v => InputHelper.parseIdToObject(v, 'brandId')} format={v => InputHelper.formatObjectToId(v, 'brandId')} 
                    >
                    <RA.SelectArrayInput 
                        optionText="name" 
                        parse={v => InputHelper.parseIdToObject(v, 'brandId')} format={v => InputHelper.formatObjectToId(v, 'brandId')} 
                        />
                </RA.ReferenceArrayInput>
            </RA.FormTab>
            
            <RA.FormTab label="Consents" >
                <RA.ArrayField source="consents">
                    
                    <RA.Datagrid>
                        <RA.TextField source="policyId" />
                        <RA.TextField source="consentState" />
                        <RA.DateField source="lastUpdated" />
                    </RA.Datagrid>

                </RA.ArrayField>
            </RA.FormTab>

        </RA.TabbedForm>

    )
};

export const UserEdit = (props: any) => (
    <RA.Edit {...props} >
        <BaseForm type="edit" />
    </RA.Edit>
);

export const UserCreate = (props: any) => (
    <RA.Create {...props}>
        <BaseForm type="create" />
    </RA.Create>
);

export default {
    name: 'User',
    options: {label: 'User' },
    list: UserList,
    edit: UserEdit,
    create: UserCreate,
    icon: UserIcon,
};

