import { useState } from 'react';
import { Box, Tab, TextField, Paper } from "@mui/material"
import { TabContext, TabList, TabPanel } from "@mui/lab";

interface State {
    readonly pt: string;
    readonly fr: string;
    readonly en: string;
    readonly es: string;
}

const I18NText = (props: any) => {

    const [tab, setTab] = useState("pt");
    const [label, setLabel] = useState(props?.schema?.title);
    const [pt, setPt] = useState(props?.formData?.pt);
    const [en, setEn] = useState(props?.formData?.en);
    const [fr, setFr] = useState(props?.formData?.fr);
    const [es, setEs] = useState(props?.formData?.es);

    const ml = props.multiline;

    const update = (stateFunc: any, stateKey: keyof State, newVal: any) => {
        stateFunc(newVal); // update state - immutable on each render (so update will only be visible on next render)
        const state = { pt, en, fr, es }; // retrieve current state
        state[stateKey] = newVal; // update the property that changed

        // update the main Form state with whatever was changed by this component
        props.onChange(state);
    };

    return (
        <Paper variant="outlined" >
            <Box>
                <TabContext value={tab}>
                    <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                        <TabList onChange={(e: any, newVal: any) => setTab(newVal)} >
                            <Tab label="PT" value="pt" />
                            <Tab label="EN" value="en" />
                            <Tab label="FR" value="fr" />
                            <Tab label="ES" value="es" />
                        </TabList>
                    </Box>
                    <TabPanel value="pt">
                        <TextField id="pt" label={label} variant="filled" multiline={ml} value={pt || ''} onChange={(e: any) => update(setPt, 'pt', e.target.value)} />
                    </TabPanel>
                    <TabPanel value="en">
                        <TextField id="en" label={label} variant="filled" multiline={ml} value={en || ''} onChange={(e: any) => update(setEn, 'en', e.target.value)} />
                    </TabPanel>
                    <TabPanel value="fr">
                        <TextField id="fr" label={label} variant="filled" multiline={ml} value={fr || ''} onChange={(e: any) => update(setFr, 'fr', e.target.value)} />
                    </TabPanel>
                    <TabPanel value="es">
                        <TextField id="es" label={label} variant="filled" multiline={ml} value={es || ''} onChange={(e: any) => update(setEs, 'es', e.target.value)} />
                    </TabPanel>
                </TabContext>
            </Box>
        </Paper>
    );
}

export const TextInputI18N = (props: any) => (
    <I18NText {...props} multiline={true} />
);

export default I18NText;