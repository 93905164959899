import * as RA from 'react-admin';
import AreaIcon from '@mui/icons-material/Bookmark';
import TreeList from './Tree_List';
import { CreateNode, EditNode, EditNodeToolbar } from '@react-admin/ra-tree';

// a Create view for a tree uses <CreateNode> instead of the standard <Create>
const AreaCreate = (props: any) => (
    <CreateNode {...props}>
        <RA.SimpleForm warnWhenUnsavedChanges>
            
            {/* TODO: generate this file or retrieve these fields from the generated files */}
            <RA.TextInput  source="name" />
            <RA.TranslatableInputs locales={['pt','en', 'fr', 'es']} defaultLocale="pt" >
                <RA.TextInput source="displayNameI18n" multiline/>
            </RA.TranslatableInputs>
            <RA.ImageInput source="images" accept={'.png,.jpg,.jpeg,.svg'} multiple>
                <RA.ImageField source="src" title="title" />
            </RA.ImageInput>

            <RA.NumberInput  source="position" />
            <RA.ReferenceInput label="parent" source="parentId" reference="parent">
                <RA.SelectInput optionText="name" />
            </RA.ReferenceInput>
            <RA.TextInput  source="images" />
            <RA.TextInput  source="name" />
            <RA.TranslatableInputs locales={['pt','en', 'fr', 'es']} defaultLocale="pt" >
                <RA.TextInput source="displayNameI18n" />
            </RA.TranslatableInputs>

        </RA.SimpleForm>
    </CreateNode>
);

// an Edit view for a tree uses <EditNode> instead of the standard <Edit>
const AreaEdit = (props: any) => (
    <EditNode {...props}>
        <RA.SimpleForm warnWhenUnsavedChanges toolbar={<EditNodeToolbar />}>
            
            {/* TODO: generate this file or retrieve these fields from the generated files */}
            <RA.NumberInput disabled source="id" />
            <RA.TextInput  source="name" />
            <RA.TranslatableInputs locales={['pt','en', 'fr', 'es']} defaultLocale="pt" >
                <RA.TextInput source="displayNameI18n" multiline />
            </RA.TranslatableInputs>
            <RA.ImageInput source="images" accept={'.png,.jpg,.jpeg,.svg'} multiple>
                <RA.ImageField source="src" title="title" />
            </RA.ImageInput>

        </RA.SimpleForm>
    </EditNode>
)

const AreaTree = () => (
    <TreeList 
        create={AreaCreate}
        edit={AreaEdit}/>
);

export default {
    name: 'Area',
    options: {label: 'Area' },
    list: AreaTree,
    icon: AreaIcon,
};

