import Form from "@rjsf/mui";
import { createTheme } from '@mui/material/styles';
import { ThemeProvider } from '@mui/material/styles';
import TextField from "@mui/material/TextField";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";

import React, { Component, useState, useEffect } from 'react';
import { TextInputI18N } from '../equipments/I18NText'
import validator from "@rjsf/validator-ajv6";
import EquipmentTypeSelect from '../equipments/EquipmentTypeSelect'
import BrandSelect from '../equipments/BrandSelect'
import CountrySelect from '../equipments/CountrySelect'

import { SDK, AppService } from 'src/ts-axios-sdk/SDK'

interface Props {
    eqId: number;
    callback: any;
}

interface State {
    schema: any;
    uiSchema: any;
    formData: any;
    fields: any;
}

const theme = createTheme({
    // remove shadows that would wrap around the catalogue files, leaving the upload input outside
    shadows: ['none','none','none','none','none','none','none','none','none','none','none','none','none','none','none','none','none','none','none','none','none','none','none','none','none'],
    components: {
          MuiTextField: {
            defaultProps: {
              variant: 'standard'
            },
        }
    }});

const JsonSchemaForm = (props: Props) => {

    const [state, setState] = useState<State>({ schema: {}, uiSchema: {}, formData: {}, fields: {} });

    const [eqTypeId, setEqTypeId] = useState<any>(null);
    const [brandId, setBrandId] = useState<any>(null);
    const [countryId, setCountryId] = useState<any>(null);
    const [name, setName] = useState<any>('');
    const [published, setPublished] = useState<any>(false);

    useEffect(() => {

        // effect function can't be async, so we create a local function
        const fetchEquipment = async (id: number | undefined) => {

            const api = AppService.EquipmentApiFactory(...SDK.Config('en'));
            await api.equipmentRead(Number(id))
                .then(async (response) => {
                    setEqTypeId(response.data.equipmentTypeId);
                    setBrandId(response.data.brandId);
                    setName(response.data.name);
                    setPublished(response.data.published);
                    setCountryId(response.data.madeInCountryId);
                });
        }

        // and call it afterwards
        if (!isNaN(props.eqId)) {
            fetchEquipment(props.eqId).catch(console.error);
        }

    }, []) // empty deps array: run just once

    useEffect(() => {

        // effect function can't be async, so we create a local function
        const fetchEqType = async () => {

            if (eqTypeId !== null) {

                const eqTypeApi = AppService.EquipmentTypeApiFactory(...SDK.Config('en'));
                const eqApi = AppService.EquipmentApiFactory(...SDK.Config('en'));

                // full read + write form
                const jsonSchema = await eqTypeApi.equipmentTypeGetJsonSchema(eqTypeId, 'fullReadWrite');
                const eq = isNaN(props.eqId) ? null : await eqApi.equipmentRead(props.eqId);

                setState({
                    schema: jsonSchema.data.jsonSchema,
                    formData: eq?.data.jsonData ?? {},
                    uiSchema: jsonSchema.data.uiSchema,
                    fields: {
                        'TextInputI18N': TextInputI18N,
                        'AttachmentField': AttachmentField
                    }
                })
            }
            else {
                // no equipment selected: reset state
                setState({ schema: {}, uiSchema: {}, formData: {}, fields: {} })
            }
        }

        // and call it afterwards
        fetchEqType().catch(console.error);

    }, [eqTypeId]) // deps array: effect depends on eqTypeId - when it changes, effect is triggered

    const onSubmit = async (data: any) => {

        if (!name) // simple validation
        {
            alert("Name is required");
        }
        else {
            const api = AppService.EquipmentApiFactory(...SDK.Config('en'));
            const equipmentDto = {
                id: props.eqId,
                equipmentTypeId: eqTypeId,
                brandId: brandId,
                madeInCountryId: countryId,
                name: name,
                published: published,
                jsonData: data.formData
            } as AppService.EquipmentFormDTO;

            await api.equipmentCreate(equipmentDto)
                .then(props.callback());
        }
    };

    const onError = (errors:any) => alert(errors);

    // replaces typical array text inputs with readonly <a>'s
    const AttachmentField = (props:any) => <a href={props.formData['Src']} target='_blank'>{props.formData['Title']}</a>

    return (
        <>
            <EquipmentTypeSelect externalCallback={(_e: any, v: any) => setEqTypeId(v?.id)} value={eqTypeId} />
            <TextField id="name" label="Name" variant="filled" required value={name || ''} onChange={(e: any) => setName(e.target.value)} />
            <BrandSelect externalCallback={(_e: any, v: any) => setBrandId(v?.id)} value={brandId} />
            <CountrySelect externalCallback={(_e: any, v: any) => setCountryId(v?.id)} value={countryId} />
            <FormControlLabel control={<Switch checked={published} onChange={(e: any) => setPublished(e.target.checked)} />} label="Published" />
            <ThemeProvider theme={theme}>
                <Form validator={validator} schema={state.schema} formData={state.formData}
                    uiSchema={state.uiSchema}
                    fields={state.fields}
                    onSubmit={onSubmit}
                    onChange={e => {
                        setState({ schema: state.schema, uiSchema: state.uiSchema, formData: e.formData, fields: state.fields });
                    }} 
                    // onError={onError} // TODO: try this
                    />
            </ThemeProvider>
        </>
    );
}

export default JsonSchemaForm;