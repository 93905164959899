// https://marmelab.com/react-admin/Authentication.html
import { AuthProvider } from 'ra-core';
import { SDK } from 'src/ts-axios-sdk/SDK'

export const formatUrlData = (data: any) => {
    let info = new URLSearchParams();
    for (let key in data) {
        info.append(key, data[key]);
    }
    return info;
};

const authProvider: AuthProvider = {

    // send username and password to the auth server and get back credentials
    login: async ({ username, password }) => await SDK.LoginAsync(username, password),
    // when the dataProvider returns an error, check if this is an authentication error
    checkError: async (error) => {
        const status = error.status;

        // forbidden
        if (status === 403) {
            SDK.Logout();
            return Promise.reject();
        }

        // unauthorized (we'll try to use the refresh-token)
        if (status === 401) {

            try {

                await SDK.GetAccessTokenAsync();

            } catch (e) {

                console.log(e);
                alert("Session expired. Please login again");

                return Promise.reject();
            }
        }

        // other error code (404, 500, etc): no need to log out
        return Promise.resolve();
    },
    // when the user navigates, make sure that their credentials are still valid
    checkAuth: async () => {
        var tkn = await SDK.GetAccessTokenAsync(); // it'll refresh the token if necessary
        if (tkn) 
            return Promise.resolve();
        else
            return Promise.reject();
    },
    // remove local credentials 
    logout: () => {
        SDK.Logout();
        return Promise.resolve();
    },
    // get the user's profile
    getIdentity: () => {
        // TODO: implement this - it will need a profile retrieval endpoint 
        try {
            var authJson = localStorage.getItem('auth');
            const { id, fullName, avatar } = JSON.parse(authJson || '');

            return Promise.resolve({ id, fullName, avatar });
        } catch (error) {
            return Promise.reject(error);
        }
    },
    // get the user permissions (optional)
    // https://marmelab.com/react-admin/AuthProviderWriting.html#getpermissions
    getPermissions: () => Promise.resolve(),
    // get the user roles (optional - only for Role-Based Access Control)
    // https://marmelab.com/react-admin/AuthProviderWriting.html#getroles
    getRoles: () => Promise.resolve(),
};

export default authProvider;