////////////////////

import Axios, { AxiosInstance, AxiosRequestConfig } from 'axios'
import { UIApi, UIBrandUserApi, UIRegistrationApi, UIUserApi } from './application';

// expose generated clients through these exports
// this way, wherever we use the SDK, it's enough to import this file, like:
// import { SDK, AppService } from 'src/ts-axios-sdk/SDK'
export * as AppService from './application/api';
//export * as IdService from './identity/api'

interface TokenResponse {
  access_token: string,
  expires_in: string,
  token_type: number,
  refresh_token: string,
  scope: string
};

// storage keys
enum Storage {
  AccessToken = "access-token",
  RefreshToken = "refresh-token",
  AccessTokenExpiry = "access-token-expiry",
}

// Identity Server 4 configuration
const is4Config = {
  authority: `${process.env.REACT_APP_API_AUTHORITY}`,
  client_id: `${process.env.REACT_APP_API_CLIENT_ID}`
};

export class SDK {

  public static UIApi = () => new UIApi(undefined, '', this.GetAxios());
  public static UIUserApi = () => new UIUserApi(undefined, '', this.GetAxios());
  public static UIBrandUserApi = () => new UIBrandUserApi(undefined, '', this.GetAxios());
  public static UIRegistrationApi = () => new UIRegistrationApi(undefined, '', this.GetAxios());

  // prevent direct construction calls with the `new` operator.
  private constructor() { }

  /**
   * Config - returns the default parameters for the API factories
   */
  public static Config(lang: string = ''): any {
    return [{}, '', this.GetAxios(lang)];
  }

  // storage access methods
  private static Get(key: Storage) { return typeof window !== "undefined" ? localStorage.getItem(key) : ''; }
  private static Set(key: Storage, val: any) { 
    if (typeof window !== "undefined")
      localStorage.setItem(key, val); 
  }

  /**
   * Cleanup all token data from storage
   */
  public static Logout() {
    localStorage.clear();
  }

  public static async LoginAsync(username: string, password: string) {

      const response = await fetch(
        `${is4Config.authority}/connect/token`,
        {
          method: 'POST',
          headers: new Headers({ 'Content-Type': 'application/x-www-form-urlencoded' }),
          body: new URLSearchParams({
            grant_type: 'password',
            client_id: is4Config.client_id,
            username,
            password,
          }),
        }
      );
      
      if (!response.ok) {
        // var json = await response.json();
        throw new Error('Authentication failed');
      }

      // Parse the response JSON and save the access token and refresh token to session storage
      let tkn: TokenResponse = await response.json();
      this.SaveToken(tkn);
  }

  private static SaveToken(tkn: TokenResponse) {
    // we'll consider the token expired 1min before it actually is (safeguard)
    const secondsUntilConsideredExpired = Number(tkn.expires_in) - 60; 
    const expiration_time = Date.now() + secondsUntilConsideredExpired * 1000;
    this.Set(Storage.AccessToken, tkn.access_token);
    this.Set(Storage.RefreshToken, tkn.refresh_token);
    this.Set(Storage.AccessTokenExpiry, expiration_time);
  }

  /**
   * Retrieves the token, and refreshes it if needed
   * @returns the access token
   */
  public static async GetAccessTokenAsync() {

    try {

      // Get the access token and refresh token from session storage
      const accessToken = this.Get(Storage.AccessToken);
      const refreshToken = this.Get(Storage.RefreshToken);

      // If there is no refresh token, return null
      if (!refreshToken || !accessToken) {
        return null;
      }

      // Check if the access token has expired
      const tokenExpirationDate = parseInt(this.Get(Storage.AccessTokenExpiry) || '0', 10);
      const isExpired = Date.now() > tokenExpirationDate;

      // If the access token is not expired, return it
      if (!isExpired) {
        return accessToken;
      }

      // Send the refresh token to the server to get a new access token
      const response = await fetch(
          `${is4Config.authority}/connect/token`,
          {
            method: 'POST',
            headers: new Headers({ 'Content-Type': 'application/x-www-form-urlencoded' }),
            body: new URLSearchParams({
              grant_type: 'refresh_token',
              client_id: is4Config.client_id,
              refresh_token: refreshToken
            }),
          }
        );

      if (!response.ok) {
        this.Logout();
        throw new Error('Failed to refresh access token');
      }

      // Parse the response JSON and save the access token and refresh token to session storage
      let tkn: TokenResponse = await response.json();
      this.SaveToken(tkn);

      return tkn.access_token;

    } catch (error) {
      console.log(error);
      return null;
    }

  }

  // returns an axios instance with
  // interceptors for authentication (include Bearer token, refresh when needed)
  private static GetAxios(lang: string = ''): AxiosInstance {

    const baseUrl = process.env.REACT_APP_API_BASE_URL;

    var axiosBaseConfig: AxiosRequestConfig = {
      baseURL: baseUrl,
    }

    // if a lang was set, override the Accept-Language header
    // otherwise, use it'll use whatever the client sent 
    if (lang !== '')
      axiosBaseConfig.headers = { 'Accept-Language': lang }

    const axiosInstance = Axios.create(axiosBaseConfig);

    // setup axios interceptors
    // refs: 
    //  https://www.zappts.com/blog/refresh-token-usando-axios-interceptors/
    //  https://kapeli.com/cheat_sheets/Axios.docset/Contents/Resources/Documents/index#//dash_ref/Category/Request%20Config/1

    // request interceptor
    axiosInstance.interceptors.request.use(async (config) => {

      try {

        // refresh the token if needed add it to the headers
        var token = await this.GetAccessTokenAsync();
        
        if (config.headers !== undefined && token)
          config.headers.Authorization = `Bearer ${token}`;

      } catch (e) {
        
        console.log(e);
        alert("Session expired. Please login again");

        return Promise.reject("Session expired. Please login again");
      }

      return config;

    }, (error) => {
      return Promise.reject(error);
    });

    // response interceptor (not used ATM)
    // axiosInstance.interceptors.response.use((response) => { // success - received a 2XX status
    //   return response;
    // }, (error) => { // error - received a status not like 2XX 
    //   console.error(error);
    // });

    return axiosInstance;

  }

}
