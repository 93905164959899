
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * React-Admin List component for: EquipmentType Resource
 */

import * as RA from 'react-admin';


const postFilters = [
    <RA.TextInput source="search" label="Search" alwaysOn />
];
const EquipmentTypeList = () => (
    <RA.List filters={postFilters}> 
        <RA.Datagrid rowClick="edit">
            <RA.TextField source="name" />

            <RA.NumberField source="id" />

        </RA.Datagrid>
    </RA.List>
);

export default EquipmentTypeList;

