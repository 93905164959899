
import * as RA from 'react-admin';
import Icon from '@mui/icons-material/Bookmark';

import { EditableDatagrid, RowForm } from "@react-admin/ra-editable-datagrid";

const postFilters = [
    <RA.TextInput source="search" label="Search" alwaysOn />,
    <RA.RadioButtonGroupInput alwaysOn source="type" choices={[
        { id: 0, name: 'Content' },
        { id: 1, name: 'Error' },
        { id: 2, name: 'Dictionary' }
    ]} />
];

const TranslationList = () => (
  <RA.List hasCreate empty={false} filters={postFilters} filterDefaultValues={{ 'type': 2 }} >
    <EditableDatagrid
      mutationMode={'pessimistic'}
      createForm={<TranslationForm />}
      editForm={<TranslationForm />}
    >
      <RA.TextField source="id" />
      <RA.TextField source="type" />
      <RA.TextField source="label" />
      <RA.TextField source="pt" />
      <RA.TextField source="en" />
      <RA.TextField source="fr" />
      <RA.TextField source="es" />
    </EditableDatagrid>
  </RA.List>
);

const TranslationForm = () => (
  <RowForm>
    <RA.TextField source="id" />
    <RA.TextInput source="type" disabled defaultValue="Dictionary" />
    <RA.TextInput source="label" />
    <RA.TextInput source="pt" />
    <RA.TextInput source="en" />
    <RA.TextInput source="fr" />
    <RA.TextInput source="es" />
  </RowForm>
);

// export default TranslationList;

export default {
    name: 'Translation',
    options: {label: 'Translation' },
    list: TranslationList,
    icon: Icon
};