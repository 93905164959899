import { required } from 'react-admin';

export default {
    parseIdToObject: (value : any, idField : string = 'id') => {
        const object = value && value.map((v:any) => ({ [idField]: v, stateFlag: 1 })); // unchanged       
        return object;
    },
    formatObjectToId: (value : any, idField : string = 'id') => {
        const idValue = value && value.map((v:any) => v[idField]);
        return idValue;
    },
    requiredIf: (condition : boolean, message = 'ra.validation.required') => {
        return condition 
            ? required(message) 
            : Object.assign(undefined, { isRequired: true })
    },
    locales: ['pt', 'en', 'fr', 'es']
}