
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * React-Admin Create/Update components for: Country Resource
 */

import * as RA from 'react-admin';
import CountryIcon from '@mui/icons-material/Bookmark';
import CountryList from './Country_List';
import InputHelper from '../common/inputHelper';


const CountryCreate = () => (
    <RA.Create>
        <RA.SimpleForm warnWhenUnsavedChanges>
            <RA.TextInput  source="alpha2" />

            <RA.TextInput  source="alpha3" />

            <RA.TextInput  source="phonePrefix" />

            <RA.TextInput  source="currency" />

            <RA.TextInput  source="latLong" />

            <RA.TextInput  source="capital" />

            <RA.TextInput  source="timezones" />

            <RA.TextInput  source="languages" />

            <RA.TextInput  source="region" />

            
            <RA.TextInput  source="name" />

            <RA.TranslatableInputs locales={['pt','en', 'fr', 'es']} defaultLocale="pt" >
    <RA.TextInput source="displayNameI18n" />
</RA.TranslatableInputs>

        </RA.SimpleForm>
    </RA.Create>
);

const CountryEdit = () => (
    <RA.Edit>
        <RA.SimpleForm warnWhenUnsavedChanges>
            <RA.TextInput  source="alpha2" />

            <RA.TextInput  source="alpha3" />

            <RA.TextInput  source="phonePrefix" />

            <RA.TextInput  source="currency" />

            <RA.TextInput  source="latLong" />

            <RA.TextInput  source="capital" />

            <RA.TextInput  source="timezones" />

            <RA.TextInput  source="languages" />

            <RA.TextInput  source="region" />

            <RA.NumberInput disabled source="id" />

            <RA.TextInput  source="name" />

            <RA.TranslatableInputs locales={['pt','en', 'fr', 'es']} defaultLocale="pt" >
    <RA.TextInput source="displayNameI18n" />
</RA.TranslatableInputs>

        </RA.SimpleForm>
    </RA.Edit>
);

export default {
    name: 'Country',
    options: {label: 'Country' },
    list: CountryList,
    edit: CountryEdit,
    create: CountryCreate,
    icon: CountryIcon,
};

