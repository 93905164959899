
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * React-Admin Create/Update components for: Role Resource
 */

import * as RA from 'react-admin';
import RoleIcon from '@mui/icons-material/Bookmark';
import RoleList from './Role_List';
import InputHelper from '../common/inputHelper';


const RoleCreate = () => (
    <RA.Create>
        <RA.SimpleForm warnWhenUnsavedChanges>
            <RA.TextInput  source="name" />

            
        </RA.SimpleForm>
    </RA.Create>
);

const RoleEdit = () => (
    <RA.Edit>
        <RA.SimpleForm warnWhenUnsavedChanges>
            <RA.TextInput  source="name" />

            <RA.TextInput disabled source="id" />

        </RA.SimpleForm>
    </RA.Edit>
);

export default {
    name: 'Role',
    options: {label: 'Role' },
    list: RoleList,
    edit: RoleEdit,
    create: RoleCreate,
    icon: RoleIcon,
};

