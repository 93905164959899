import * as RA from 'react-admin';
import { TextInput } from 'react-admin';
import { CreateNode, EditNode, EditNodeToolbar, TreeWithDetails, useTreeController } from '@react-admin/ra-tree';


const TreeList = (props: any) => {

    const treeController = useTreeController({
        resource: props.resource,
        hideRootNodes: false,
        lazy: false,
        titleField: props.titleField,
        mutationMode: 'optimistic'
    });

    return <TreeWithDetails
        titleField='name'
        create={props.TreeCreate}
        edit={props.TreeEdit}
        allowMultipleRoots
        draggable
        onDrop={treeController.handleDrop}
        autoExpandParent={false}
        defaultExpandParent={false}
        defaultExpandAll={false}
        {...props}
    />;
};

export default TreeList;