
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * React-Admin Create/Update components for: TechnicalBranch Resource
 */

import * as RA from 'react-admin';
import TechnicalBranchIcon from '@mui/icons-material/Bookmark';
import TechnicalBranchList from './TechnicalBranch_List';
import InputHelper from '../common/inputHelper';


const TechnicalBranchCreate = () => (
    <RA.Create>
        <RA.SimpleForm warnWhenUnsavedChanges>
            
            <RA.TextInput  source="name" />

            <RA.TranslatableInputs locales={['pt','en', 'fr', 'es']} defaultLocale="pt" >
                <RA.TextInput source="displayNameI18n" />
            </RA.TranslatableInputs>
            
            <RA.ImageInput source="images" accept={'.png,.jpg,.jpeg,.svg'} multiple>
                <RA.ImageField source="src" title="title" />
            </RA.ImageInput>

        </RA.SimpleForm>
    </RA.Create>
);

const TechnicalBranchEdit = () => (
    <RA.Edit>
        <RA.SimpleForm warnWhenUnsavedChanges>
            <RA.NumberInput disabled source="id" />

            <RA.TextInput  source="name" />

            <RA.TranslatableInputs locales={['pt','en', 'fr', 'es']} defaultLocale="pt" >
                <RA.TextInput source="displayNameI18n" />
            </RA.TranslatableInputs>

            <RA.ImageInput source="images" accept={'.png,.jpg,.jpeg,.svg'} multiple>
                <RA.ImageField source="src" title="title" />
            </RA.ImageInput>

        </RA.SimpleForm>
    </RA.Edit>
);

export default {
    name: 'TechnicalBranch',
    options: {label: 'Technical Branch' },
    list: TechnicalBranchList,
    edit: TechnicalBranchEdit,
    create: TechnicalBranchCreate,
    icon: TechnicalBranchIcon,
};

