import * as RA from 'react-admin';
import BrandIcon from '@mui/icons-material/Bookmark';
import BrandList from './Brand_List';
import InputHelper from '../common/inputHelper';
import Styles from '../common/styles';
import { Box, Grid, Typography } from '@mui/material'
import { required } from 'react-admin';
import { SDK, AppService } from 'src/ts-axios-sdk/SDK'

const BaseForm = (props: any) => (

    <RA.TabbedForm warnWhenUnsavedChanges {...props}>
        <RA.FormTab label="Brand" >

            {props.type === "edit" && <RA.NumberInput disabled source="id" />}

            <RA.TextInput source="name" />
            
            <RA.BooleanInput source="published" />

            <RA.TranslatableInputs locales={['pt','en', 'fr', 'es']} defaultLocale="pt" >
                <RA.TextInput source="displayNameI18n" multiline />
            </RA.TranslatableInputs>

            <RA.ReferenceArrayInput source="technicalBranches" reference="TechnicalBranch" 
                perPage={0} 
                parse={InputHelper.parseIdToObject} format={InputHelper.formatObjectToId} >
                <RA.SelectArrayInput optionText="name" parse={InputHelper.parseIdToObject} format={InputHelper.formatObjectToId} />
            </RA.ReferenceArrayInput>

            <RA.TextInput source="website" />

            <RA.TranslatableInputs locales={['pt','en', 'fr', 'es']} defaultLocale="pt" >
                <RA.TextInput source="description" multiline />
            </RA.TranslatableInputs>

            <RA.TranslatableInputs locales={['pt','en', 'fr', 'es']} defaultLocale="pt" >
                <RA.TextInput source="services" multiline />
            </RA.TranslatableInputs>

        </RA.FormTab>

        <RA.FormTab label="Images" >

            <RA.ImageInput source="logo" accept={'.png,.jpg,.jpeg,.svg'} >
                <RA.ImageField source="src" title="title" />
            </RA.ImageInput>

            <RA.ImageInput source="images" accept={'.png,.jpg,.jpeg,.svg'} multiple>
                <RA.ImageField source="src" title="title" />
            </RA.ImageInput>

        </RA.FormTab>

        <RA.FormTab label="Office">
            
            <RA.TextInput  source="mainOffice.streetAddress" multiline />

            <RA.TextInput  source="mainOffice.city" />

            <RA.TextInput  source="mainOffice.postalCode" />

            <RA.ReferenceInput
                label="Country" sx={{ width: '250px' }}
                source='mainOffice.countryId'
                perPage={0} // retrieve all 
                sort={{ field: 'name', order: 'ASC' }}
                reference="country">
                <RA.AutocompleteInput optionText="name" />
            </RA.ReferenceInput>

            <RA.TextInput  source="mainOffice.phoneNumber" />

            <RA.TextInput  source="mainOffice.email" />

        </RA.FormTab>

    </RA.TabbedForm>

);

const EditForm = (props: any) => (
    <RA.Edit {...props}>
        <BaseForm type="edit" />
    </RA.Edit>
);

const CreateForm = (props: any) => (
    <RA.Create {...props}>
        <BaseForm type="create" />
    </RA.Create>
);

export default {
    name: 'Brand',
    brands: {label: 'Brand' },
    list: BrandList,
    edit: EditForm,
    create: CreateForm,
    icon: BrandIcon,
};



