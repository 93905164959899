
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * React-Admin Create/Update components for: Equipment Resource
 */
import EquipmentIcon from '@mui/icons-material/Bookmark'
import EquipmentList from './Equipment_List'

import JsonSchemaForm from '../equipments/JsonSchemaForm'
import { useParams } from "react-router-dom"
import { useState, useEffect } from 'react'
import { Card, TextField } from "@mui/material"
import { SDK, AppService } from 'src/ts-axios-sdk/SDK'
import { useNotify, useRefresh, useRedirect } from 'react-admin';

const EquipmentForm = (props: any) => {
    const { id } = useParams();
    const redirect = useRedirect();
    const postSubmitCallback = () => {
        redirect('/Equipment');
    }

    return (
        <div>
            <Card sx={{ padding: 2 }}>
                <JsonSchemaForm callback={postSubmitCallback} eqId={Number(id)}  />
            </Card>
        </div>
    );
}

export default {
    name: 'Equipment',
    options: { label: 'Equipment' },
    list: EquipmentList,
    edit: EquipmentForm,
    create: EquipmentForm,
    icon: EquipmentIcon,
};

