import * as RA from 'react-admin';
import ContentIcon from '@mui/icons-material/Note';
import TreeList from './Tree_List';
import { CreateNode, EditNode, EditNodeToolbar } from '@react-admin/ra-tree';
import { SDK, AppService } from 'src/ts-axios-sdk/SDK'
import { useWatch, useFormContext } from "react-hook-form";
import InputHelper from '../common/inputHelper';
import {
	RichTextInput,
	RichTextInputToolbar,
	LevelSelect,
	FormatButtons,
	ListButtons,
	LinkButtons,
	QuoteButtons,
	ClearButtons,
    AlignmentButtons
} from 'ra-input-rich-text';

// TODO: Important - Content supports images but only inside JsonData and as arrays
// when mapping Content images from/to JsonData/Attachments all attachments are considered to belong within jsonData

const contentTypes = AppService.ContentFormDTOContentTypeEnum;
const ContentType = () => {
    const type = useWatch({ name: 'contentType' });

    return (
        <>

            {type == contentTypes.Page &&
                <>    
                    <RA.SelectInput source="jsonData.template" label='Template' choices={[
                        { id: 'home', name: 'Homepage' },
                        { id: 'whowheare', name: 'Who We Are' }
                    ]} />
                </>
            }

            {type == contentTypes.ImageTextModule &&
                <> 
                    <RA.ImageInput source="jsonData.image" accept={'.png,.jpg,.jpeg,.svg'} multiple >
                        <RA.ImageField source="src" title="title" />
                    </RA.ImageInput>
                    <RA.TranslatableInputs locales={InputHelper.locales} defaultLocale="pt" >
                        <RA.TextInput label="Title" source='jsonData.title' />
                    </RA.TranslatableInputs>
                    <RA.TranslatableInputs locales={InputHelper.locales} defaultLocale="pt" >
                        <RichTextInput label="Text" source='jsonData.text' 
                        toolbar={
                            <RichTextInputToolbar>
                                <LevelSelect />
                                <FormatButtons />
                                <AlignmentButtons />
                                <ListButtons />
                                <LinkButtons />
                                <QuoteButtons />
                                <ClearButtons />
                            </RichTextInputToolbar>
                        }
                        />
                    </RA.TranslatableInputs>
                </>
            }

            {type == contentTypes.TextModule &&
                <>    
                    <RA.TranslatableInputs locales={InputHelper.locales} defaultLocale="pt" >
                        <RA.TextInput label="Title" source='jsonData.title' />
                    </RA.TranslatableInputs>
                    <RA.TranslatableInputs locales={InputHelper.locales} defaultLocale="pt" >
                        <RichTextInput label="Text" source='jsonData.text'  />
                    </RA.TranslatableInputs>
                </>
            }

            {type == contentTypes.HeroModule &&
                <> 
                    <RA.ImageInput source="jsonData.image" accept={'.png,.jpg,.jpeg,.svg'} multiple >
                        <RA.ImageField source="src" title="title" />
                    </RA.ImageInput>
                    <RA.TranslatableInputs locales={InputHelper.locales} defaultLocale="pt" >
                        <RA.TextInput label="Title" source='jsonData.title' />
                    </RA.TranslatableInputs>
                    <RA.TranslatableInputs locales={InputHelper.locales} defaultLocale="pt" >
                        <RA.TextInput label="SubTitle" source='jsonData.subTitle' />
                    </RA.TranslatableInputs>
                </>
            }

        </>
    );
};

// a Create view for a tree uses <CreateNode> instead of the standard <Create>
const ContentCreate = (props: any) => {
    return (
        <CreateNode {...props}>
            <RA.SimpleForm warnWhenUnsavedChanges>

                <RA.TextInput  source="name" />
                <RA.BooleanInput source="published" />

                <RA.SelectInput label="Content Type" sx={{ width: '250px' }} validate={[RA.required('Required')]}
                    source="contentType"
                    choices={Object.entries(contentTypes).map((x) => ({ id: x[1], name: x[0] }))}
                />

                <ContentType />

            </RA.SimpleForm>
        </CreateNode>
    )
};

// an Edit view for a tree uses <EditNode> instead of the standard <Edit>
const ContentEdit = (props: any) => (
    <EditNode {...props}>
        <RA.SimpleForm warnWhenUnsavedChanges toolbar={<EditNodeToolbar />}>
            
        <RA.TextInput  source="name" />
        <RA.BooleanInput source="published" />

        <RA.SelectInput label="Content Type" sx={{ width: '250px' }} validate={[RA.required('Required')]}
            source="contentType"
            choices={Object.entries(contentTypes).map((x) => ({ id: x[1], name: x[0] }))}
        />

        <ContentType />

        </RA.SimpleForm>
    </EditNode>
)

const ContentTree = () => (
    <TreeList 
        create={ContentCreate}
        edit={ContentEdit}/>
);

export default {
    name: 'Content',
    options: {label: 'Content' },
    list: ContentTree,
    icon: ContentIcon,
};

