
import * as RA from 'react-admin';


const postFilters = [

    <RA.TextInput source="search" label="Search" alwaysOn />,

    <RA.ReferenceInput 
        alwaysOn
        label="Role"
        source="roleId" 
        reference="Role"
        perPage={10} // retrieve all to ensure the dropdown filter won't clear
        on
        sort={{ field: 'name', order: 'ASC' }} >
        <RA.AutocompleteInput source="id" optionText="name" optionValue='id' 
            sx={{ width: 175 }}
            //filterToQuery={(query: string) => ({ search: query })}
        />
    </RA.ReferenceInput>,

    // issue with multiple values: https://github.com/marmelab/react-admin/issues/8181
    <RA.ReferenceInput
      alwaysOn
      label="Brand"
      source="brandId" 
      reference="Brand"
      perPage={0} // retrieve all to ensure the dropdown filter won't clear
      on
      sort={{ field: 'name', order: 'ASC' }}
    >
      <RA.AutocompleteInput source="id" optionText="name" optionValue='id' 
        sx={{ width: 300 }}
        //filterToQuery={(query: string) => ({ search: query })}
      />
  </RA.ReferenceInput>,
  <RA.NullableBooleanInput alwaysOn label="Approved" source="approved" />,

];

const UserList = () => (
    <RA.List filters={postFilters}> 
        <RA.Datagrid rowClick="edit">
            <RA.TextField source="name" />

            <RA.TextField source="email" />

            <RA.TextField source="approved" />

            <RA.DateField showTime source="created" />

            <RA.TextField source="id" />

        </RA.Datagrid>
    </RA.List>
);

export default UserList;

