
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * React-Admin Create/Update components for: Policy Resource
 */

import * as RA from 'react-admin';
import PolicyIcon from '@mui/icons-material/Bookmark';
import PolicyList from './Policy_List';
import InputHelper from '../common/inputHelper';
import { SDK, AppService } from 'src/ts-axios-sdk/SDK'

const PolicyCreate = () => (
    <RA.Create>
        <RA.SimpleForm warnWhenUnsavedChanges>
            
            <RA.SelectInput label="Policy Type" sx={{ width: '250px' }} validate={[RA.required('Required')]}
                source="type"
                choices={Object.entries(AppService.PolicyFormDTOTypeEnum).map((x) => ({ id: x[1], name: x[0] }))}
            />
            <RA.BooleanInput label="Active" source="isActive" />
            <RA.BooleanInput label="Required" source="isRequired" />

            <RA.TextInput  source="version" />

            <RA.TranslatableInputs locales={['pt','en', 'fr', 'es']} defaultLocale="pt" >
    <RA.TextInput source="titleI18n" multiline />
</RA.TranslatableInputs>

            <RA.TranslatableInputs locales={['pt','en', 'fr', 'es']} defaultLocale="pt" >
    <RA.TextInput source="textI18n" multiline />
</RA.TranslatableInputs>

        </RA.SimpleForm>
    </RA.Create>
);

const PolicyEdit = () => (
    <RA.Edit>
        <RA.SimpleForm warnWhenUnsavedChanges>
            
            <RA.SelectInput label="Policy Type" sx={{ width: '250px' }} validate={[RA.required('Required')]}
                source="type"
                choices={Object.entries(AppService.PolicyFormDTOTypeEnum).map((x) => ({ id: x[1], name: x[0] }))}
            />
            <RA.BooleanInput label="Active" source="isActive" />
            <RA.BooleanInput label="Required" source="isRequired" />

            <RA.TextInput  source="version" />
            
            <RA.TranslatableInputs locales={['pt','en', 'fr', 'es']} defaultLocale="pt" >
    <RA.TextInput source="titleI18n" multiline />
</RA.TranslatableInputs>

            <RA.TranslatableInputs locales={['pt','en', 'fr', 'es']} defaultLocale="pt" >
    <RA.TextInput source="textI18n" multiline />
</RA.TranslatableInputs>

        </RA.SimpleForm>
    </RA.Edit>
);

export default {
    name: 'Policy',
    options: {label: 'Policy' },
    list: PolicyList,
    edit: PolicyEdit,
    create: PolicyCreate,
    icon: PolicyIcon,
};

