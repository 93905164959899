import * as React from 'react';
import { Admin, Resource, mergeTranslations, Layout, Menu } from 'react-admin';
import authProvider from './common/authProvider';
import dataProvider from './common/dataProviderTree';
import polyglotI18nProvider from 'ra-i18n-polyglot';
import englishMessages from 'ra-language-english';
import { raTreeLanguageEnglish } from '@react-admin/ra-tree';

import OptionSet from './resources/OptionSet_Forms';
import Equipment from './resources/Equipment_Forms';
import EquipmentType from './resources/EquipmentType_Forms';
import TechnicalBranch from './resources/TechnicalBranch_Forms';
import Area from './resources/Area_Tree';
import Content from './resources/Content_Tree';
import Brand from './resources/Brand_Forms';
import UnitType from './resources/UnitType_Forms';
import Translations from './resources/Translations';
import User from './resources/User_Forms';
import Role from './resources/Role_Forms';
import Policy from './resources/Policy_Forms';
import Setting from './resources/Setting_Forms';
import Country from './resources/Country_Forms';

import * as Icons from '@mui/icons-material';

const i18nProvider = polyglotI18nProvider(locale => {
  return mergeTranslations(englishMessages, raTreeLanguageEnglish);
}, 'en');

const MyMenu = () => (
  <Menu>
      {/* TODO */}
      {/* <Menu.DashboardItem /> */}
      <Menu.Item to="/EquipmentType" primaryText="Equipment Types" leftIcon={<Icons.ViewList />} />
      <Menu.Item to="/Equipment" primaryText="Equipments" leftIcon={<Icons.List />} />
      <Menu.Item to="/TechnicalBranch" primaryText="Technical Branches" leftIcon={<Icons.Bookmarks />} />
      <Menu.Item to="/Area" primaryText="Areas" leftIcon={<Icons.LocalOffer />} />
      <Menu.Item to="/Brand" primaryText="Brands" leftIcon={<Icons.Work />} />
      <Menu.Item to="/OptionSet" primaryText="Options" leftIcon={<Icons.RuleFolder />} />
      <Menu.Item to="/UnitType" primaryText="Units" leftIcon={<Icons.SquareFoot />} />
      <Menu.Item to="/Content" primaryText="Content" leftIcon={<Icons.Notes />} />
      <Menu.Item to="/Translation" primaryText="Translations" leftIcon={<Icons.Translate />} />
      <Menu.Item to="/User" primaryText="User" leftIcon={<Icons.Groups />} />
      <Menu.Item to="/Policy" primaryText="Policy" leftIcon={<Icons.Policy />} />
      <Menu.Item to="/Setting" primaryText="Setting" leftIcon={<Icons.Settings />} />
      <Menu.Item to="/Country" primaryText="Country" leftIcon={<Icons.Map />} />
      {/* <Menu.Item to="/Role" primaryText="Role" leftIcon={<Icons.Badge />} /> */}
  </Menu>
);

const MyLayout = (props:any) => <Layout {...props} menu={MyMenu} />;

function App() {
  return (
    <Admin dataProvider={dataProvider} authProvider={authProvider} i18nProvider={i18nProvider} layout={MyLayout} 
      requireAuth // https://marmelab.com/react-admin/Authentication.html#disabling-anonymous-access
      > 

        <Resource {...EquipmentType} />
        <Resource {...Equipment} />
        
        <Resource {...TechnicalBranch} />
        <Resource {...Area} />
        <Resource {...Brand} />

        <Resource {...OptionSet} />
        <Resource {...UnitType} />
        
        <Resource {...Content} />
        <Resource {...Translations} />

        <Resource {...User} />
        <Resource {...Role} />
        <Resource {...Policy} />
        <Resource {...Setting} />
        
        <Resource {...Country} />

      </Admin>
  );
}

export default App;
